export const LocationValleys = [
  {
    id: 1,
    name: "Valle Camonica – Iseo",
    villages: [
      "Ponte di Legno",
      "Temù",
      "Vione",
      "Vezza d’Oglio",
      "Incudine",
      "Monno",
      "Edolo",
      "Corteno Golgi",
      "Sonico",
      "Malonno",
      "Berzo Demo",
      "Cevo",
      "Saviore dell’Adamello",
      "Paico Loveno",
      "Sellero",
      "Cedegolo",
      "Capo di Ponte",
      "Paspardo",
      "Cimbergo",
      "Ono San Pietro",
      "Cerveno",
      "Ceto",
      "Losine",
      "Lozio",
      "Braone",
      "Niardo",
      "Breno",
      "Malegno",
      "Borno",
      "Ossimo",
      "Cividate Camuno",
      "Prestine",
      "Bienno",
      "Berzo Inferiore",
      "Esine",
      "Piancogno",
      "Angolo Terme",
      "Darfo Boario Terme",
      "Gianico",
      "Artogne",
      "Piancamuno",
      "Pisogne",
      "Zone",
      "Marone",
      "Sale Marasino",
      "Sulzano",
      "Iseo",
    ],
  },
  {
    id: 2,
    name: "Bassa Bresciana",
    villages: [
      "Poncarale",
      "Montirone",
      "Bagnolo Mella",
      "Ghedi",
      "Leno",
      "Offlaga",
      "Manerbio",
      "Bassano Bresciano",
      "Verolanuova",
      "Pontevico",
      "Alfianello",
      "Seniga",
      "Milzano",
      "Pralboino",
      "San Gervasio Bresciano",
      "Cigole",
      "Pavone Mella",
      "Gottolengo",
      "Gambara",
      "Fiesse",
      "Remedello",
      "Isorella",
      "Visano",
      "Acquafredda",
      "Carpenedolo",
      "Calvisano",
      "Roccafranca",
      "Comezzano Cizzago",
      "Trenzano",
      "Maclodio",
      "Lograto",
      "Rudiano",
      "Castelcovati",
      "Castrezzato",
      "Berlingo",
      "Capriano Del Colle",
      "Azzano Mella",
      "Mairano",
      "Longhena",
      "Brandico",
      "Corzano",
      "Pompiano",
      "Orzivecchi",
      "Orzinuovi",
      "Barbariga",
      "Dello",
      "Villa Chiara",
      "Borgo San Giacomo",
      "San Paolo",
      "Verolavecchia",
      "Quinzano d’Oglio",
    ],
  },
  {
    id: 3,
    name: "Garda - Valle Sabbia",
    villages: [
      "Montichiari",
      "Calcinato",
      "Pozzolengo",
      "Lonato",
      "Desenzano Del Garda",
      "Sirmione",
      "Padenghe Sul Garda",
      "Calvagese Della Riviera",
      "Moniga Del Garda",
      "Soiano",
      "Polpenazze",
      "Manerba Del Garda",
      "Puegnago Sul Garda",
      "Muscoline",
      "San Felice Del Benaco",
      "Salò",
      "Roè Volciano",
      "Gardone Riviera",
      "Gargnano",
      "Tignale",
      "Tremosine",
      "Limone Del Garda",
      "Villanuova Sul Clisi",
      "Prevalle",
      "Bedizzole",
      "Gavardo",
      "Vobarno",
      "Sabbio Chiese",
      "Vallio",
      "Odolo",
      "Agnosine",
      "Preseglie",
      "Bione",
      "Barghe",
      "Provaglio Val Sabbia",
      "Vestone",
      "Treviso Bresciano",
      "Casto",
      "Mura",
      "Pertica Alta",
      "Pertica Bassa",
      "Lavenone",
      "Idro",
      "Anfo",
      "Bagolino",
      "Capovalle",
      "Valvestino",
      "Magasa",
      "Nuvolera",
      "Nuvolento",
      "Serle",
      "Paitone",
    ],
  },
  {
    id: 4,
    name: "Brescia – Valle Trompia - Basso Iseo",
    villages: [
      "Brescia",
      "Provaglio d’Iseo",
      "Monticelli Brusati",
      "Paratico",
      "Cortefranca",
      "Adro",
      "Capriolo",
      "Erbusco",
      "Cazzago San Martino",
      "Passirano",
      "Rodengo Saiano",
      "Palazzolo Sull’Oglio",
      "Pontoglio",
      "Cologne",
      "Paderno Franciacorta",
      "Gussago",
      "Coccaglio",
      "Rovato",
      "Ospitaletto",
      "Travagliato",
      "Chiari",
      "Urago d’Oglio",
      "Castegnato",
      "Collebeato",
      "Cellatica",
      "Roncadelle",
      "Torbole Casaglia",
      "Castelmella",
      "Flero",
      "San Zeno Naviglio",
      "Borgosatollo",
      "Castenedolo",
      "Rezzato",
      "Mazzano",
      "Botticino",
      "Concesio",
      "Villa Carcina",
      "Bovezzo",
      "Nave",
      "Caino",
      "Lumezzane",
      "Sarezzo",
      "Polaveno",
      "Brione",
      "Gardone Val Trompia",
      "Marcheno",
      "Lodrino",
      "Tavernole Sul Mella",
      "Pezzaze",
      "Marmentino",
      "Irma",
      "Bovegno",
      "Collio",
    ],
  },
];
