import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React from "react";

export default function SingleReview({ review }) {
  const array = [1, 2, 3, 4, 5];
  return (
    <div className="flex flex-col p-4 bg-gray-200 border rounded-lg shadow-lg">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <img
            src={review.user.image}
            alt="user"
            className="w-12 h-12 rounded-full"
          />
          <div className="flex flex-col ml-4">
            <div className="font-semibold">{review.user.name}</div>
            <div className="text-xs text-gray-500">
              {dayjs(review.createdAt).format("MMMM YYYY")}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center gap-x-1">
            <div className="flex items-center gap-x-1">
              {array.map((item) => (
                <FontAwesomeIcon
                  key={item}
                  icon={faStar}
                  className={`${
                    item <= review.rating ? "text-primary" : "text-gray-300"
                  }`}
                />
              ))}
            </div>
            <div className="font-semibold">{review.rating}/5</div>
          </div>
        </div>
      </div>
      <div className="mt-4 line-clamp-3">{review.text}</div>
    </div>
  );
}
