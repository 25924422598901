import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TagsInput from "react-tagsinput";

export default function ModalNewFaq({
  faqSections,
  setFaqSections,
  editFaq = null,
  setEditFaq,
  setOpenModalFaq,
}) {
  const [error, setError] = useState([
    { id: "question", error: null },
    { id: "answer", error: null },
    { id: "faq_section_id", error: null },
  ]);
  const animatedComponents = makeAnimated();
  const [selectedSection, setSelectedSection] = useState(null);
  const [tags, setTags] = useState(editFaq ? editFaq.tags : []);
  const [isWritingRequirements, setIsWritingRequirements] = useState(false);

  const addFaq = async () => {
    const question = document.getElementById("question").value;
    const answer = document.getElementById("answer").value;

    const currentErrors = [
      { id: "question", error: null },
      { id: "answer", error: null },
      { id: "faq_section_id", error: null },
    ];

    if (question === "") {
      currentErrors[0].error = true;
    }

    if (answer === "") {
      currentErrors[1].error = true;
    }

    if (!selectedSection) {
      currentErrors[2].error = true;
    }

    setError(currentErrors);

    if (
      currentErrors[0].error ||
      currentErrors[1].error ||
      currentErrors[2].error
    ) {
      return;
    }

    const token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/faqCreate`,
        {
          question: question,
          answer: answer,
          tags: tags,
          faq_section_id: selectedSection.value,
          position: faqSections.filter(
            (faqSection) => faqSection._id === selectedSection.value
          )[0].faqs.length,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const newFaqSections = faqSections.map((faqSection) => {
          if (faqSection._id === selectedSection.value) {
            return {
              ...faqSection,
              faqs: [...faqSection.faqs, res.data],
            };
          }
          return faqSection;
        });
        setFaqSections(newFaqSections);
        setOpenModalFaq(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editFaqMutation = async () => {
    const question = document.getElementById("question").value;
    const answer = document.getElementById("answer").value;

    const currentErrors = [
      { id: "question", error: null },
      { id: "answer", error: null },
      { id: "faq_section_id", error: null },
    ];

    if (question === "") {
      currentErrors[0].error = true;
    }

    if (answer === "") {
      currentErrors[1].error = true;
    }

    if (!selectedSection) {
      currentErrors[2].error = true;
    }

    setError(currentErrors);

    if (
      currentErrors[0].error ||
      currentErrors[1].error ||
      currentErrors[2].error
    ) {
      return;
    }

    const token = localStorage.getItem("token");
    const faqSectionChanged = editFaq.faq_section_id !== selectedSection.value;

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/faqUpdate/${editFaq._id}`,
        {
          question: question,
          answer: answer,
          tags: tags,
          faq_section_id: selectedSection.value,
          position: faqSectionChanged
            ? faqSections.filter(
                (faqSection) => faqSection._id === selectedSection.value
              )[0].faqs.length
            : editFaq.position,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("faq updated", res);
        const newFaqSections = faqSections.map((faqSection) => {
          if (faqSection._id === selectedSection.value) {
            const faqExists = faqSection.faqs.find(
              (faq) => faq._id === editFaq._id
            );
            return {
              ...faqSection,
              faqs: faqExists
                ? faqSection.faqs.map((faq) =>
                    faq._id === editFaq._id ? res.data : faq
                  )
                : [...faqSection.faqs, res.data],
            };
          } else {
            return {
              ...faqSection,
              faqs: faqSection.faqs.filter((faq) => faq._id !== editFaq._id),
            };
          }
        });
        setEditFaq(null);
        setFaqSections(newFaqSections);
        setOpenModalFaq(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => {
            setOpenModalFaq(false);
            setEditFaq(null);
          }}
          className="mt-0 md:mt-10"
          shouldCloseOnOverlayClick={false}
          width="large"
        >
          <ModalHeader>
            <div className="flex items-center gap-x-4">
              {editFaq ? (
                <h3 className="text-2xl">Modifica Faq</h3>
              ) : (
                <h3 className="text-2xl">Aggiungi Faq</h3>
              )}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalFaq(false);
                setEditFaq(null);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold">Domanda</p>
              <input
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="question"
                defaultValue={editFaq ? editFaq.question : ""}
                placeholder={`Domanda`}
              ></input>
              {error[0].error && (
                <p className="-mt-2 text-sm text-red-500">
                  Inserisci una domanda
                </p>
              )}
              <p className="font-semibold">Seleziona Faq Section</p>
              <Select
                isMulti={false}
                closeMenuOnSelect
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                id="faq_section_id"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    minHeight: "35px",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                defaultValue={
                  editFaq
                    ? {
                        value: editFaq.faq_section_id,
                        label: faqSections.filter(
                          (faqSection) =>
                            faqSection._id === editFaq.faq_section_id
                        )[0].name,
                      }
                    : null
                }
                options={faqSections.map((faqSection) => ({
                  value: faqSection._id,
                  label: faqSection.name,
                }))}
                onChange={(value) => {
                  setSelectedSection(value);
                }}
              />
              {error[2].error && (
                <p className="-mt-2 text-sm text-red-500">
                  Seleziona una sezione
                </p>
              )}
              <p className="w-full font-semibold">Tag di ricerca</p>
              <div className="w-full">
                <TagsInput
                  value={tags}
                  onChange={(tags) => {
                    setIsWritingRequirements(false);
                    setTags(tags);
                  }}
                  inputProps={{
                    placeholder: "Aggiungi un tag",
                    onInput: () => setIsWritingRequirements(true),
                    onBlur: () => setIsWritingRequirements(false),
                  }}
                  id="notSuitableFor"
                />
                {isWritingRequirements && (
                  <div className="w-full px-1">
                    <div className="relative w-full px-4 pt-1 pb-2 -mt-px bg-white rounded-b-lg shadow-lg">
                      <p className="text-sm text-slate-600">
                        Premi invio per aggiungere un elemento
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="font-semibold">Risposta</p>
              <textarea
                className="h-full p-2 border-2 border-gray-200 rounded-lg resize-none"
                id="answer"
                defaultValue={editFaq ? editFaq.answer : ""}
              ></textarea>
              {error[1].error && (
                <p className="-mt-2 text-sm text-red-500">
                  Inserisci una risposta
                </p>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
              <button
                className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                onClick={() => {
                  setOpenModalFaq(false);
                  setEditFaq(null);
                }}
              >
                Annulla
              </button>
              <button
                className="w-full h-10 text-sm text-white rounded-lg bg-primary md:w-28"
                onClick={() => {
                  if (editFaq) {
                    editFaqMutation();
                  } else {
                    addFaq();
                  }
                }}
              >
                {editFaq ? "Modifica" : "Aggiungi"}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
