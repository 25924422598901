import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { FavouriteContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import ExperienceIcon from "./ExperienceIcon";

export default function SingleBox({ item, selectedTab }) {
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `SingleBoxContainer`,
    });

  const { favourite, setFavourite } = useContext(FavouriteContext);

  const avarageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].escoBoxes.find(
          (escoBox) => escoBox.escoBox_id === item._id
        )
      ) {
        const newEscoBoxes = favourite[0].escoBoxes.filter(
          (escoBox) => escoBox.escoBox_id !== item._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: newEscoBoxes,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Box rimosso dai preferiti con successo.");
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].escoBoxes
                  : []),
                { escoBox_id: item._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Box aggiunto ai preferiti con successo.");
          });
      }
    }
  };

  return (
    <div
      key={item._id}
      className="relative flex flex-col border rounded-[30px] shadow-lg cursor-pointer mb-14 hover:shadow-xl"
      onClick={() => {
        navigate(`/escoboxes/${item._id}`);
      }}
    >
      <div
        style={{
          backgroundImage: `url(${item.cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt={item.name}
        className="relative w-full h-56 rounded-[30px] shadow-[inset_0px_-60px_40px_0px_rgba(0,0,0,0.6)]"
      />
      <div className="absolute flex flex-row items-start justify-between w-full p-2 top-2">
        <div className="flex items-center mt-1">
          {/*<div className="relative z-10 flex items-center justify-center rounded-full bg-slate-600 w-9 h-9">
             {selectedTab === "wine" ? (
              <img src={food} alt="wine" className="w-6 h-6" />
            ) : selectedTab === "art" ? (
              <img src={art} alt="art" className="w-6 h-6" />
            ) : selectedTab === "outdoor" ? (
              <img src={outdoor} alt="outdoor" className="w-6 h-6" />
            ) : selectedTab === "authentic" ? (
              <img src={authentic} alt="authentic" className="w-6 h-6" />
            ) : null} 
          </div>*/}
          <div
            className="flex items-center justify-center h-6 pl-4 pr-2 -ml-2 text-xl text-white rounded-r-lg"
            // style={
            //   selectedTab === "wine"
            //     ? { backgroundColor: "#612430" }
            //     : selectedTab === "art"
            //     ? { backgroundColor: "#C8531C" }
            //     : selectedTab === "outdoor"
            //     ? { backgroundColor: "#4C8AFB" }
            //     : selectedTab === "authentic"
            //     ? { backgroundColor: "#CA9526" }
            //     : null
            // }
          >
            <span className="font-bold">esco</span>&nbsp;
            <span className="font-light">box</span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-y-2">
          <button
            className="flex items-center justify-center bg-white rounded-full line-clamp-2 w-9 h-9"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addToFavourites();
            }}
          >
            {/* <img src={heart} alt="heart" className="w-5" /> */}
            {favourite &&
            favourite.length > 0 &&
            favourite[0].escoBoxes.find(
              (escoBox) => escoBox.escoBox_id === item._id
            ) ? (
              <img src={heartFull} alt="heartFull" className="w-5" />
            ) : (
              <img src={heart} alt="heart" className="w-5" />
            )}
          </button>
          {item.category
            .filter((c, index, self) => self.indexOf(c) === index)
            .map((c, index) => (
              <div
                className="flex items-center justify-center rounded-full w-9 h-9 "
                key={`${c}-${index}`}
                style={
                  c === "wine"
                    ? { backgroundColor: "#612430" }
                    : c === "art"
                    ? { backgroundColor: "#C8531C" }
                    : c === "outdoor"
                    ? { backgroundColor: "#4C8AFB" }
                    : c === "authentic"
                    ? { backgroundColor: "#CA9526" }
                    : null
                }
              >
                <ExperienceIcon kind={c} dimension={5} />
              </div>
            ))}
        </div>
      </div>
      <div className="relative ">
        <div className="absolute w-full px-2 font-bold text-center text-white -mt-14">
          {item.name}
        </div>
      </div>
      <div className="flex flex-col flex-1 w-full h-full p-2">
        <div className="flex flex-row justify-around text-sm">
          <div className="flex items-start w-3/4 gap-x-2">
            <img src={geotag} alt="geotag" className="w-4" />
            <div>{item.city}</div>
          </div>
          <div className="flex items-start justify-end w-1/4 h-full gap-x-2">
            <img src={stella} alt="stella" className="w-4" />
            <div>{item.reviews ? avarageReviews(item.reviews) : 0}/5</div>
          </div>
        </div>
      </div>
      <div className="p-3 pt-0 pb-8 overflow-hidden text-sm rounded-b-lg">
        <div className="line-clamp-3">{item.description}</div>
      </div>
      <div className="relative flex items-center justify-center w-full">
        <div className="absolute flex items-center justify-center w-4/5 px-4 py-2 bg-gray-100 rounded-full gap-x-2">
          <div className="font-thin">a partire da</div>
          <div className="font-bold">{(item.price / 100).toFixed(0)}€</div>
        </div>
      </div>
    </div>
  );
}
