import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ElementsCartContext } from "../App";
import Footer from "../components/Footer";
import Header from "../components/Header";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Payment() {
  const [userLogged, setUserLogged] = useState(null);

  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState(null);
  const { elements, setElements } = useContext(ElementsCartContext);
  const { clientCartSecret } = useParams();

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function getCart() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/cart/${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        if (!res.data.message) {
          setCart(res.data.cart);
          setElements(res.data.elements);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    document.title = "Esco | Pagamento";
    Start();
  }, []);

  useEffect(() => {
    if (userLogged !== null) {
      getCart();
    }
  }, [userLogged]);

  return (
    <div>
      <header>
        <div className="relative z-50 -mt-16">
          <Header />
        </div>
      </header>
      <main>
        <div className="mx-0 2xl:mx-16">
          <div className="relative mx-16 max-w-screen-2xl 2xl:mx-auto">
            <div className="flex flex-col justify-center w-full bg-[#F8F9F9] mt-16 pt-36 rounded-[60px] p-8 py-20">
              <div className="flex items-center gap-x-4">
                {/* <img src={carrello} alt="carrello" className="w-8 h-8" /> */}
                <h1 className="text-3xl text-primary">Completa il pagamento</h1>
              </div>
              {!loading && cart && elements.length > 0 ? (
                <div className="flex flex-col mt-8">
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                      clientSecret: clientCartSecret,
                    }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="flex items-center justify-center h-96">
                      <UiduSpinner size="medium" className="text-primary" />
                    </div>
                  ) : (
                    <p className="mt-4 text-xl text-gray-500">
                      Il tuo carrello è vuoto, aggiungi dei prodotti per
                      visualizzarli
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
