import { useLoadScript } from "@react-google-maps/api";
import Tooltip from "@uidu/tooltip";
import React from "react";
import accessibility from "../assets/icons/ACCESSIBILITY.svg";
import cancellazione from "../assets/icons/CANCELLAZIONE.svg";
import lingue from "../assets/icons/LINGUE.svg";
import mappa from "../assets/icons/MAPPA.svg";
import recensioni from "../assets/icons/REVIEW.svg";
import stella from "../assets/icons/STELLA.svg";
import Map from "../components/Map";
import RoomCard from "../components/RoomCard";
import SingleReview from "../components/SingleReview";

export default function StructureBody({ structure, rooms, fromModal = false }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  return (
    <>
      <div className="w-full border-b border-primary" />
      <div className="grid grid-cols-1 gap-8 py-4 md:grid-cols-2">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-start gap-x-2">
            <img src={cancellazione} alt="cancellazione" className="w-6" />
            <div className="text-lg font-semibold">
              Cancellazione gratuita{" "}
              <span className="text-base font-normal">
                {structure.cancellationPolicy === 1
                  ? "fino a 24 ore prima"
                  : structure.cancellationPolicy === 2
                  ? "fino a 48 ore prima"
                  : "fino a 72 ore prima"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="flex items-start gap-x-2">
            <img src={lingue} alt="lingue" className="w-6" />
            <div className="text-lg font-semibold">
              Lingua{" "}
              {structure.lenguages.map((language, index) => (
                <span className="text-base font-normal" key={language}>
                  {language}
                  {index < structure.lenguages.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      {structure.accessibility.length > 0 && (
        <div className="flex flex-wrap items-center gap-2">
          <div className="flex items-center gap-x-2">
            <img src={accessibility} alt="accessibilità" className="w-6" />
            <div className="text-lg font-semibold">Accessibilità:</div>
          </div>
          {structure.accessibility
            .filter((accessibility) => accessibility !== "altro")
            .map((accessibility) => (
              <span
                className="px-2 text-base font-normal border rounded text-primary border-primary"
                key={accessibility}
              >
                {accessibility === "visivi"
                  ? "Ausili visivi: segnaletica tattile"
                  : accessibility === "sedia"
                  ? "Accessibile in sedia a rotelle"
                  : "Altro"}
              </span>
            ))}
          {structure.accessibility.includes("altro") && (
            <>
              {structure.extra_accessibility.map((accessibility) => (
                <span
                  className="px-2 text-base font-normal border rounded text-primary border-primary"
                  key={accessibility}
                >
                  {accessibility}
                </span>
              ))}
            </>
          )}
        </div>
      )}
      <div className="w-full border-b border-primary" />
      <div className="flex flex-col gap-y-6">
        <div className="text-xl font-semibold">
          {fromModal ? "Camera inclusa nel box:" : "Le nostre camere:"}
        </div>
        {rooms.map((room) => (
          <RoomCard
            key={room.id}
            room={room}
            kind={structure.kind}
            structure={structure}
          />
        ))}
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex flex-col gap-y-4">
        <div className="flex items-start gap-x-4">
          <div className="flex items-center gap-x-2">
            <img src={mappa} alt="luogo" className="w-6" />
            <div className="text-lg font-semibold">Luogo:</div>
          </div>
          <Tooltip
            className="w-3/4"
            content={
              structure.address && structure.address?.formatted_address
                ? structure.address?.formatted_address
                : "via Roma 1, Pertica Bassa"
            }
            position="bottom"
          >
            <div className="mt-1 ml-4 text-base font-normal truncate">
              {structure.address && structure.address?.formatted_address
                ? structure.address?.formatted_address
                : "via Roma 1, Pertica Bassa"}
            </div>
          </Tooltip>
        </div>
        <div className="w-full border rounded-md shadow-md h-80">
          <Map
            address={{
              latitude: structure?.address?.latitude || 45.7658776,
              longitude: structure?.address?.longitude || 10.3767045,
            }}
            isLoaded={isLoaded}
            loadError={loadError}
          />
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <img src={recensioni} alt="recensioni" className="w-6" />
          <div className="text-lg font-semibold">Recensioni:</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex items-center gap-x-2">
            <img src={stella} alt="stella" className="w-5" />
            <div>
              {structure.reviews ? averageReviews(structure.reviews) : 0}
              /5
            </div>
          </div>
          <div className="flex items-center gap-x-1.5 text-base text-primary">
            <div>
              {structure.reviews ? structure.reviews.length : 0} recensioni
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {structure.reviews ? (
          structure.reviews.map((review) => (
            <SingleReview key={review.id} review={review} />
          ))
        ) : (
          <div className="flex text-gray-400">
            Nessuna recensione disponibile al momento
          </div>
        )}
      </div>
    </>
  );
}
