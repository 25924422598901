import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import BaseComponent from "../BaseComponent";

export default function Settings() {
  const [fees, setFees] = useState({});

  const [loading, setLoading] = useState(true);
  const [editFee, setEditFee] = useState(false);
  const [error, setError] = useState([
    { id: "experienceFee", error: null },
    { id: "structureFee", error: null },
  ]);

  const FeeDetail = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/fee`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("fees", res.data);
        setFees(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const UpdateFee = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const currentErrors = [
      { id: "experienceFee", error: null },
      { id: "structureFee", error: null },
    ];
    const experienceFee = document
      .getElementById("experienceFee")
      .value.replace(",", ".")
      .replace("%", "");
    const structureFee = document
      .getElementById("structureFee")
      .value.replace(",", ".")
      .replace("%", "");

    if (experienceFee === "" || parseFloat(experienceFee) < 0) {
      currentErrors[0].error = "Invalid fee";
    }
    if (structureFee === "" || parseFloat(structureFee) < 0) {
      currentErrors[1].error = "Invalid fee";
    }

    if (currentErrors[0].error || currentErrors[1].error) {
      setError(currentErrors);
      return;
    }

    const data = {
      user_id: id,
      experiences: parseFloat(experienceFee),
      structures: parseFloat(structureFee),
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/feeUpdate`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("fees", res.data);
        setFees(res.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });

    setEditFee(false);
  };

  useEffect(() => {
    FeeDetail();
  }, []);

  return (
    <BaseComponent
      title="Impostazioni Esco"
      sidebar={
        !loading &&
        !editFee &&
        fees.structures &&
        fees.experiences && (
          <button
            onClick={() => setEditFee(true)}
            className="self-center px-6 py-1 text-white rounded-md bg-primary w-fit"
          >
            Modifica fee
          </button>
        )
      }
    >
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[512px] overflow-y-auto p-10 my-4">
        {!loading && !editFee && fees.message === "Fee not found" ? (
          <div className="flex flex-col justify-center h-full gap-y-4 item-center">
            <div className="text-2xl text-center text-gray-500">
              Fee non impostate!
            </div>
            <button
              onClick={() => setEditFee(true)}
              className="self-center px-6 py-2 text-white rounded-md bg-primary w-fit"
            >
              Imposta le fee
            </button>
          </div>
        ) : loading ? (
          <div className="flex items-center justify-center h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : !editFee ? (
          <div className="flex items-center w-full text-lg gap-x-4">
            <div className="flex justify-between w-full p-4 border-2 rounded-md border-primary">
              <p className="font-semibold">Fee esperienze</p>
              <p>{fees.experiences.fee}%</p>
            </div>
            <div className="flex justify-between w-full p-4 text-lg border-2 rounded-md border-primary">
              <p className="font-semibold">Fee Strutture</p>
              <p>{fees.structures.fee}%</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-around h-full">
            <div className="flex flex-col gap-y-2">
              <div className="grid items-center w-full grid-cols-5 gap-x-4">
                <p className="font-semibold">Fee esperienze</p>
                <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                  <CurrencyInput
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                    placeholder="10%"
                    id="experienceFee"
                    min={0}
                    suffix="%"
                    decimalsLimit={2}
                    decimalScale={2}
                    allowNegativeValue={false}
                    defaultValue={fees?.experiences ? fees.experiences.fee : ""}
                  />
                </div>
              </div>
              <div className="grid items-center w-full grid-cols-5 gap-x-4">
                <div />
                <p className="h-16 text-sm text-red-500">{error[0].error}</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="grid items-center w-full grid-cols-5 gap-x-4">
                <p className="font-semibold">Fee Strutture</p>
                <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                  <CurrencyInput
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                    placeholder="10%"
                    id="structureFee"
                    min={0}
                    suffix="%"
                    decimalsLimit={2}
                    decimalScale={2}
                    allowNegativeValue={false}
                    defaultValue={fees?.structures ? fees.structures.fee : ""}
                  />
                </div>
              </div>
              <div className="grid items-center w-full grid-cols-5 gap-x-4">
                <div />
                <p className="h-16 text-sm text-red-500">{error[1].error}</p>
              </div>
            </div>
            <div className="flex justify-end w-full gap-x-4">
              <button
                onClick={() => setEditFee(false)}
                className="px-6 py-2 bg-gray-300 rounded-md w-fit"
              >
                Annulla
              </button>
              <button
                onClick={() => UpdateFee()}
                className="px-6 py-2 text-white rounded-md bg-primary w-fit"
              >
                Aggiorna
              </button>
            </div>
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
