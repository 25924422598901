import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import {
  insertFacebookPixel,
  insertGTMBodyScript,
  insertGTMHeadScript,
  insertGoogleAnalytics,
  insertLegalBlinkScripts,
} from "./startScripts";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Main() {
  useEffect(() => {
    insertGTMHeadScript(process.env.REACT_APP_GTM_ID);
    insertGTMBodyScript(process.env.REACT_APP_GTM_ID);
    insertFacebookPixel(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    insertGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    insertLegalBlinkScripts();
  }, []);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

root.render(<Main />);
