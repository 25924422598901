import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BaseComponent from "../BaseComponent";

function SingleTicket({ ticket, handleArchive }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col border-b border-gray-300">
      <div
        className="flex items-center justify-between w-full h-20 px-4 py-2 cursor-pointer gap-x-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-x-4">
          <FontAwesomeIcon icon={faUserCircle} className="text-3xl" />
          <div className="flex flex-col">
            <p className="font-semibold">{ticket.user_fullname}</p>
            <p className="-mt-1 text-xs text-gray-500">{ticket.user_email}</p>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <p className="text-sm text-gray-500">
            {dayjs(ticket.createdAt).format("DD/MM/YYYY")}
          </p>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      {isOpen && (
        <div className="flex items-center px-4 pb-4 gap-x-8">
          <div className="w-5/6">
            <p className="text-sm text-escoBlack">{ticket.description}</p>
          </div>
          <div className="flex items-center justify-end w-1/6 gap-x-2">
            <button
              className="px-2 py-1 text-white rounded-md bg-primary"
              onClick={() => {
                window.location.href = `mailto:${ticket.user_email}`;
              }}
            >
              Rispondi
            </button>
            <button
              className="px-2 py-1 bg-gray-300 rounded-md"
              onClick={() => handleArchive(ticket)}
            >
              Archivia
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function SupportTickets() {
  const [supportTickets, setSupportTickets] = useState(null);
  const [ticketToShow, setTicketToShow] = useState("open");
  const [filteredTickets, setFilteredTickets] = useState(null);

  const [loading, setLoading] = useState(true);

  const TicketsList = async () => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/supportTicketsList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSupportTickets(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleArchive = async (ticket) => {
    const token = localStorage.getItem("token");
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/supportTicketUpdate/${ticket._id}`,
        {
          status: "archived",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data, "res");
        setSupportTickets(
          supportTickets.map((ticket) =>
            ticket._id === res.data._id
              ? { ...ticket, status: "archived" }
              : ticket
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (supportTickets) {
      setFilteredTickets(
        supportTickets.filter((ticket) => ticket.status === ticketToShow)
      );
    }
  }, [ticketToShow, supportTickets]);

  useEffect(() => {
    TicketsList();
  }, []);

  return (
    <BaseComponent title="Richieste di supporto">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md min-h-[512px] overflow-y-auto py-4 px-10 my-4">
        <div className="flex items-center gap-x-4">
          <button
            className={`px-4 py-2 text-lg ${
              ticketToShow === "open"
                ? "text-primary border-b border-primary"
                : ""
            }`}
            onClick={() => setTicketToShow("open")}
          >
            Aperte
          </button>
          <button
            className={`px-4 py-2 text-lg ${
              ticketToShow === "archived"
                ? "text-primary border-b border-primary"
                : ""
            }`}
            onClick={() => setTicketToShow("archived")}
          >
            Archiviate
          </button>
        </div>
        {!loading && filteredTickets ? (
          filteredTickets.map((ticket) => (
            <SingleTicket
              key={ticket._id}
              ticket={ticket}
              handleArchive={handleArchive}
            />
          ))
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-[480px]">
            Nessuna richiesta di supporto trovata
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
