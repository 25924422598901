import React from "react";
import itinerario from "../../assets/icons/ITINERARIO.svg";

export default function ItineraryRender({ experience }) {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center gap-x-2">
        <img src={itinerario} alt="itinerario" className="w-6" />
        <div className="text-lg font-semibold">Itinerario:</div>
      </div>
      <div className="flex-col justify-center w-full">
        {experience.steps.map((step, index) => {
          const calculateTime = (duration) => {
            const hours = +`${duration}`.split(".")[0];
            let minutes = +`${duration}`.split(".")[1];
            if (`${minutes}`.length === 1) {
              minutes = minutes * 10;
            }
            let timeString = "";
            if (hours > 0) {
              timeString += `${hours} ${hours === 1 ? "ora" : "ore"}`;
            }
            if (minutes > 0) {
              if (timeString) timeString += " ";
              timeString += `${minutes} min`;
            }
            return timeString;
          };

          return (
            <>
              <div
                key={step.title}
                className="flex flex-row justify-between px-6 py-2 border border-gray-300 rounded-full"
              >
                <div className="w-[70%] border-r">{step.title}</div>
                <div className="self-center w-[30%] pl-4 pr-2 text-sm">
                  {calculateTime(step.duration)}
                </div>
              </div>
              {index !== experience.steps.length - 1 && (
                <div className="h-8 ml-10 border-l border-gray-300" />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
