import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ElementsCartContext, FavouriteContext, UserContext } from "../App";

export default function RegistrationForm({ serverLink, setUserLogged }) {
  const [error, setError] = useState(null);
  const { register, handleSubmit, setValue } = useForm();
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPromotionals, setCheckedPromotionals] = useState(false);
  const { setFavourite } = useContext(FavouriteContext);
  const { setUser } = useContext(UserContext);
  const { setElements } = useContext(ElementsCartContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const registerUser = (data) => {
    const {
      name,
      surname,
      email,
      password,
      confirmPassword,
      promotionals,
      terms,
    } = data;
    if (!name || !surname || !email || !password || !confirmPassword) {
      setError("Compila tutti i campi");
      return;
    }
    if (!terms) {
      setError("Per procedere accetta i termini e le condizioni");
      return;
    }
    if (password !== confirmPassword) {
      setError("Le password non coincidono");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/signup`, {
        name,
        surname,
        email,
        password,
        promotionals,
        terms,
      })
      .then((res) => {
        if (res.data.err) {
          setError(res.data.err[0].msg);
        } else {
          console.log(res.data, "logged");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", res.data.user._id);
          setUserLogged(true);
          setUser(res.data.user);
          setFavourite([]);
          setElements([]);
          navigate(`/profile/${res.data.user._id}`);
        }
      })
      .catch(() => {
        setError("Errore di connessione al database");
      });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(registerUser)}>
      <div className="flex flex-col gap-y-4">
        <div key={"register"} className="flex flex-col gap-y-6 md:gap-y-4">
          <div className="flex flex-col items-center gap-6 md:gap-4 md:flex-row">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Name"
              name="name"
              {...register("name")}
            />
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Surname"
              name="surname"
              {...register("surname")}
            />
          </div>
          <input
            className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          <div className="flex flex-col items-center gap-6 md:gap-4 md:flex-row">
            <div className="relative w-full">
              <input
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                {...register("password")}
              />
              <span
                className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
            <div className="relative w-full">
              <input
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                {...register("confirmPassword")}
              />
              <span
                className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              >
                {showConfirmPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
          </div>
          <div className="grid items-center gap-6 text-sm md:grid-cols-2 md:gap-4 text-primary">
            <label htmlFor="terms" className="flex items-center gap-x-2">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                className="hidden"
                defaultChecked={false}
                {...register("terms")}
              />
              <div
                className={`w-4 h-4 border rounded-sm shadow-sm text-white flex items-center justify-center ${
                  checkedTerms
                    ? "bg-primary border-primary"
                    : "bg-white border-gray-400"
                }`}
                onClick={() => {
                  setValue("terms", checkedTerms);
                  setCheckedTerms(!checkedTerms);
                }}
              >
                {checkedTerms && (
                  <FontAwesomeIcon className="h-3" icon={faCheck} />
                )}
              </div>
              Accetto i{" "}
              <span
                className="border-b cursor-pointer text-primary hover:border-b-primary border-b-transparent"
                onClick={() => {
                  window.open(
                    `https://escopublic.s3.eu-north-1.amazonaws.com/T%26C+RIELABORATI.pdf`,
                    "_blank"
                  );
                }}
              >
                termini e condizioni
              </span>{" "}
              *
            </label>
            <label htmlFor="promotionals" className="flex items-center gap-x-2">
              <input
                type="checkbox"
                id="promotionals"
                defaultChecked={false}
                className="hidden"
                {...register("promotionals")}
              />
              <div
                className={`w-4 h-4 border rounded-sm shadow-sm text-white flex items-center justify-center ${
                  checkedPromotionals
                    ? "bg-primary border-primary"
                    : "bg-white border-gray-400"
                }`}
                onClick={() => {
                  setValue("promotionals", checkedPromotionals);
                  setCheckedPromotionals(!checkedPromotionals);
                }}
              >
                {checkedPromotionals && (
                  <FontAwesomeIcon className="h-3" icon={faCheck} />
                )}
              </div>
              Desidero ricevere mail promozionali
            </label>
          </div>
        </div>
        {error && (
          <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
            {error}
          </div>
        )}
        <div className="w-full border-b border-tertiary" />
        {/* <div className="grid items-center grid-cols-1 gap-y-4 md:grid-cols-2 gap-x-4 text-primary"> */}
        <input
          className="w-full py-2 text-primary border border-tertiary rounded-[37px]"
          type="submit"
          value="Crea account"
        />
      </div>
    </form>
  );
}
