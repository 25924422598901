import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserLoggedContext } from "../App";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import LoginForm, { SignInButton } from "../components/LoginForm";
import MobileNavbar from "../components/MobileNavbar";
import Navbar from "../components/Navbar";
import RegistrationForm from "../components/RegistrationForm";

export default function Login() {
  const [isRegistering, setIsRegistering] = useState(true);
  const { userLogged, setUserLogged } = useContext(UserLoggedContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    if (userLogged !== null) {
      setLoading(false);
    }
  }, [userLogged]);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (userLogged) {
    navigate(`/profile/${localStorage.getItem("user")}`, { replace: true });
  }

  if (!loading && userLogged) {
    return <LoadingSpinnerPage />;
  }

  if (!loading && !userLogged) {
    document.title = "Esco | Accedi";

    return (
      <div>
        <header>
          <div className="mx-0 2xl:mx-8">
            <div className="hidden mx-8 lg:block max-w-screen-2xl 2xl:mx-auto">
              <Navbar />
            </div>
          </div>
          <div className="block md:hidden h-[81px]">
            <MobileNavbar />
          </div>
        </header>
        <main>
          <div className="flex items-center justify-center w-screen pt-6 md:pt-48">
            <div className="w-full max-w-xl h-full min-h-[450px] mx-4 md:mx-0">
              <div className="relative z-30 w-full h-12 md:h-16 grid grid-cols-2 text-white rounded-[37px] md:shadow-lg bg-tertiary">
                <button
                  className="relative z-20 flex items-center justify-center w-full h-full"
                  onClick={() => setIsRegistering(true)}
                >
                  {isRegistering && (
                    <motion.div
                      layoutId="selectedTab"
                      className="absolute z-10 w-full h-full rounded-[37px] bg-primary "
                    ></motion.div>
                  )}
                  <span className="absolute z-20">Registrati</span>
                </button>
                <button
                  className="relative z-20 flex items-center justify-center w-full h-full"
                  onClick={() => setIsRegistering(false)}
                >
                  {!isRegistering && (
                    <motion.div
                      layoutId="selectedTab"
                      className="absolute z-10 w-full h-full rounded-[37px] bg-primary "
                    ></motion.div>
                  )}
                  <span className="absolute z-20">Accedi</span>
                </button>
              </div>
              <div className="flex relative -mt-10 pt-16 flex-col items-center justify-center w-full h-full p-8 bg-gray-100 md:bg-white rounded-[37px] md:shadow-lg">
                {isRegistering ? (
                  <AnimatePresence mode="wait">
                    <motion.div
                      className="w-full px-2"
                      key={"register"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <RegistrationForm
                        serverLink={process.env.REACT_APP_SERVER_URL}
                        setUserLogged={setUserLogged}
                      />
                    </motion.div>
                  </AnimatePresence>
                ) : (
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={"login"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="w-full"
                    >
                      <LoginForm
                        serverLink={process.env.REACT_APP_SERVER_URL}
                        setUserLogged={setUserLogged}
                      />
                    </motion.div>
                  </AnimatePresence>
                )}
                <div className="w-full px-2 py-4">
                  <SignInButton />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
