import React from "react";
import agriturismWhite from "../assets/agriturism.svg";
import agriturism from "../assets/agriturismoBlack.svg";
import BeBWhite from "../assets/b&b.svg";
import BeB from "../assets/b&bBlack.svg";
import campingWhite from "../assets/camping.svg";
import camping from "../assets/campingBlack.svg";
import glampingWhite from "../assets/glamping.svg";
import glamping from "../assets/glampingBlack.svg";
import hostelWhite from "../assets/ostello.svg";
import hostel from "../assets/ostelloBlack.svg";
import rifugioWhite from "../assets/rifugio.svg";
import rifugio from "../assets/rifugioBlack.svg";

export default function StructureIcon({ kind, color, dimension = 10 }) {
  return kind === "ostello" ? (
    <img
      src={color === "black" ? hostel : hostelWhite}
      alt="ostello"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "B&B" ? (
    <img
      src={color === "black" ? BeB : BeBWhite}
      alt="B&B"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "camping" ? (
    <img
      src={color === "black" ? camping : campingWhite}
      alt="camping"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "glamping" ? (
    <img
      src={color === "black" ? glamping : glampingWhite}
      alt="glamping"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "rifugio" ? (
    <img
      src={color === "black" ? rifugio : rifugioWhite}
      alt="rifugio"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "agriturismo" ? (
    <img
      src={color === "black" ? agriturism : agriturismWhite}
      alt="agriturismo"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : null;
}
