import {
  faCircleQuestion,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import minus from "../../assets/icons/MINUS.svg";
import plus from "../../assets/icons/PLUS.svg";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function Itinerary() {
  const { id } = useParams();

  const { experience, setExperience, errors, setErrors } =
    useContext(ProposeContext);

  const initialSteps = [
    {
      title: "",
      duration: "",
    },
  ];

  const [steps, setSteps] = useState(
    experience.steps && experience.steps.length > 0
      ? experience.steps
      : initialSteps
  );

  const [hasItinerary, setHasItinerary] = useState(experience.steps.length > 0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (steps) {
      updateExperience();
    }
  }, []);

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    let currentError = [
      {
        error: null,
      },
    ];

    if (hasItinerary) {
      steps.forEach((step) => {
        if (
          step.title === "" ||
          !step.title ||
          step.duration === "" ||
          !step.duration
        ) {
          currentError = [
            {
              error: "Devi inserire un nome per ogni tappa e la sua durata",
            },
          ];
        }
      });
    }

    const stepsDuration = steps.reduce((acc, step) => {
      const duration = `${step.duration}`;
      const hours = +duration.split(".")[0];
      let minutes = +duration.split(".")[1];
      if (`${minutes}`.length === 1) {
        minutes = minutes * 10;
      }
      return acc + hours + (minutes ? minutes / 60 : 0);
    }, 0);

    const totalHours = Math.floor(stepsDuration);
    const fractionalHour = stepsDuration - totalHours;
    const totalMinutes = Math.round(fractionalHour * 60);

    console.log(totalHours, totalMinutes);

    if (+`${totalHours}.${totalMinutes}` > +experience.duration) {
      currentError = [
        {
          error: `La durata totale dell'itinerario supera la durata massima dell'esperienza (${experience.duration} h)`,
        },
      ];
    }

    setErrors(hasItinerary ? currentError : []);

    if (!hasItinerary && experience.steps.length > 0) {
      setSteps(initialSteps);

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
          {
            steps: [],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setExperience(res.data.experience);
        })
        .catch(() => {
          alert("Errore durante l'aggiornamento dell'esperienza");
        });
    }

    if (currentError[0].error === null && hasItinerary) {
      let data = {
        steps: steps,
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setExperience(res.data.experience);
        })
        .catch(() => {
          alert("Errore durante l'aggiornamento dell'esperienza");
        });
    }
  };

  useEffect(() => {
    if (experience && steps && experience.steps !== steps) {
      updateExperience();
    }
  }, [steps]);

  useEffect(() => {
    updateExperience();
  }, [hasItinerary]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BaseComponent
      title="Itinerario di viaggio"
      sidebar={
        !hasItinerary ? null : (
          <button
            onClick={() => setHasItinerary(false)}
            className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
          >
            Disattiva itinerario
          </button>
        )
      }
    >
      <div className="bg-gray-100 min-h-[400px]  flex flex-col gap-y-4 rounded-md overflow-y-auto py-4 px-4 md:px-10 my-4">
        {experience && steps && hasItinerary ? (
          <>
            <div className="flex flex-col gap-y-4">
              <div className="grid w-full grid-cols-4 gap-x-4">
                <p className="col-span-3 font-semibold">
                  Raccontaci il tuo itinerario
                </p>
                <div className="flex items-center justify-between w-full gap-x-2">
                  <p className="font-semibold">
                    Durata{" "}
                    <span className="hidden text-xs font-normal md:inline">
                      (Max: {`${experience?.duration}`.replace(".", ":")} h)
                    </span>
                  </p>
                  <Tooltip
                    content="Indica la durata approssimativa di ciascuna tappa. N.B. per indicare una durata inferiore a 1 ora, inserisci il valore in questo formato: 0.30 h per 30 minuti."
                    position="bottom"
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="mt-2 cursor-pointer text-primary"
                    />
                  </Tooltip>
                </div>
              </div>
              {/* {error && <p className="-mt-2 text-sm text-gray-400">{error}</p>} */}
            </div>
            {steps.map((step, index) => {
              return (
                <div>
                  <div
                    className="grid w-full grid-cols-4 gap-x-2 md:gap-x-4"
                    key={index}
                  >
                    <div className="relative col-span-3">
                      <input
                        className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="stepName"
                        defaultValue={step.title}
                        placeholder={`tappa ${index + 1}`}
                        onChange={(e) => {
                          const newSteps = [...steps];
                          newSteps[index].title = e.target.value;
                          setSteps(newSteps);
                        }}
                      ></input>
                      {steps.length > 1 && (
                        <button
                          className="absolute top-0 right-0 flex items-center justify-center p-0 -mt-2 -mr-2 font-bold text-gray-500 bg-white rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                          onClick={() => {
                            const newSteps = [...steps];
                            newSteps.splice(index, 1);
                            setSteps(newSteps);
                          }}
                        >
                          <img src={minus} alt="minus" className="w-6 h-6" />
                        </button>
                      )}
                    </div>
                    <CurrencyInput
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                      placeholder="2 h"
                      id="stepDuration"
                      min={0}
                      suffix=" h"
                      decimalsLimit={2}
                      decimalScale={2}
                      decimalSeparator=":"
                      allowNegativeValue={false}
                      defaultValue={step.duration}
                      onChange={(e) => {
                        const newSteps = [...steps];
                        newSteps[index].duration = +e.target.value
                          .split(" h")[0]
                          .replace(":", ".");
                        setSteps(newSteps);
                      }}
                    />
                  </div>
                  {index !== steps.length - 1 ? (
                    <div className="h-6 ml-10 -mb-4 border-l-2 border-gray-200 md:h-8" />
                  ) : (
                    <div>
                      <div className="h-6 ml-10 -mb-2 border-l-2 border-gray-200 md:h-8" />
                      <button
                        className="flex items-center justify-center p-0 font-bold text-gray-500 bg-white rounded-full ml-[25px] hover:bg-gray-50 focus:outline-none focus:bg-gray-200"
                        onClick={() => {
                          const newSteps = [...steps];
                          newSteps.push({
                            name: "",
                            time: "",
                          });
                          setSteps(newSteps);
                        }}
                      >
                        <img src={plus} alt="plus" className="w-8 h-8" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : experience && !hasItinerary ? (
          <div className="flex flex-col p-4 text-white rounded-md md:flex-row bg-primary gap-x-4">
            <div className="flex flex-col w-full text-sm md:w-2/3 gap-y-4">
              <div className="flex items-center text-lg gap-x-4">
                <FontAwesomeIcon icon={faCircleQuestion} />
                <p className="font-semibold">
                  Cosa si intende per itinerario di viaggio?
                </p>
              </div>
              <div className="w-full overflow-hidden rounded-md md:hidden">
                <img
                  src="https://escopublic.s3.eu-north-1.amazonaws.com/itinerary-example.png"
                  alt="itinerary"
                  className="w-full rounded-md"
                />
              </div>
              <p>
                {`L'itinerario di viaggio è la sequenza di tappe che compongono la
                tua esperienza. Per ciascuna tappa o punto saliente, sarà
                necessario inserire un nome e la sua durata approssimativa. ${
                  windowWidth > 768 ? "A lato" : "Sopra"
                } un anteprima di come potrebbe apparire il tuo itinerario
                sul sito.`}
              </p>
              <label
                htmlFor="hasItinerary"
                className="flex items-center text-base font-semibold gap-x-3"
              >
                <input
                  type="checkbox"
                  id="hasItinerary"
                  name="hasItinerary"
                  className="hidden"
                  defaultChecked={false}
                />
                <div
                  className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                  onClick={() => {
                    setHasItinerary(true);
                  }}
                >
                  {hasItinerary && (
                    <FontAwesomeIcon className="h-3" icon={faCheck} />
                  )}
                </div>
                L'esperienza prevede un itinerario di viaggio
              </label>
            </div>
            <div className="hidden w-1/3 overflow-hidden rounded-md md:block">
              <img
                src="https://escopublic.s3.eu-north-1.amazonaws.com/itinerary-example.png"
                alt="itinerary"
                className="w-full rounded-md"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
