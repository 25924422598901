import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bio from "../assets/icons/BIO.svg";
import indietro from "../assets/icons/CHEVRON_LEFT.svg";
import esperienza from "../assets/icons/ESPERIENZA.svg";
import requisiti from "../assets/icons/REQUISITI.svg";
import tipologia from "../assets/icons/TIPOLOGIA.svg";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import NavbarCreator from "../components/NavbarCreator";
import UserProfileCard from "../components/UserProfileCard";
import ApprovalType from "../components/proposePages/ApprovalType";
import Biography from "../components/proposePages/Biography";
import BookingTime from "../components/proposePages/BookingTime";
import Details from "../components/proposePages/Details";
import Escobox from "../components/proposePages/Escobox";
import ExperienceKind from "../components/proposePages/ExperienceKind";
import Itinerary from "../components/proposePages/Itinerary";
import Location from "../components/proposePages/Location";
import MaxPartecipants from "../components/proposePages/MaxPartecipants";
import Media from "../components/proposePages/Media";
import Price from "../components/proposePages/Price";
import Recurrency from "../components/proposePages/Recurrency";
import Requirements from "../components/proposePages/Requirements";

function SingleLink({ item, baseUrl }) {
  const { id } = useParams();
  const currentPath = window.location.pathname.split("/").pop();
  const navigate = useNavigate();
  const isSelected =
    item.title === "Biografia"
      ? currentPath === id
      : `/${id}/${currentPath}` === item.link ||
        (item.children &&
          item.children.some(
            (child) => child.link === `/${id}/${currentPath}`
          ));

  return (
    <>
      <button
        onClick={() => {
          if (item.active || item.children.length > 0) {
            if (item.link === "" || !item.children.length) {
              navigate(`${baseUrl}${item.link}`);
            } else navigate(`${baseUrl}${item.children[0].link}`);
          }
        }}
        className="flex items-center justify-between w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
        style={{
          borderColor: isSelected ? "#246155" : "",
          opacity: item.active ? 1 : 0.5,
          cursor: item.active ? "pointer" : "not-allowed",
        }}
      >
        <div className="flex items-center gap-x-4">
          <img src={item.icon} alt={item.title} className="w-5 h-5 mr-4" />
          <p className="font-semibold">{item.title}</p>
        </div>
        {item.completed && (
          <FontAwesomeIcon icon={faCheck} className="text-green-500" />
        )}
        {item.children.length > 0 &&
          item.children.every((child) => child.completed) && (
            <FontAwesomeIcon icon={faCheck} className="text-green-500" />
          )}
      </button>
      {item.children.length > 0 && isSelected && (
        <div className="flex flex-col gap-y-0.5 w-full">
          {item.children.map((child, index) => (
            <button
              key={index}
              onClick={() => {
                if (child.active) {
                  navigate(`${baseUrl}${child.link}`);
                }
              }}
              className="pl-20 flex items-center justify-between w-full h-10 px-4 py-2 gap-x-4 hover:bg-gray-200 rounded-[30px] border border-gray-100"
              style={{
                backgroundColor:
                  child.link === `/${id}/${currentPath}`
                    ? "rgb(229 231 235) "
                    : "",
                borderColor:
                  child.link === `/${id}/${currentPath}`
                    ? "rgb(229 231 235) "
                    : "",
                opacity: child.active ? 1 : 0.5,
                cursor: child.active ? "pointer" : "not-allowed",
              }}
            >
              <p className="font-semibold">{child.title}</p>
              {child.completed && child.active && (
                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
              )}
            </button>
          ))}
        </div>
      )}
    </>
  );
}

export const ProposeContext = createContext();

export default function Propose() {
  const { tab, id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState(null);
  const [user, setUser] = useState(null);
  const [experience, setExperience] = useState(null);
  const [errors, setErrors] = useState([]);
  const [openEditRequested, setOpenEditRequested] = useState(false);

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function experienceDetail() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/experience/${id}`)
        .then((res) => {
          setExperience(res.data.experience);
          setUser(res.data.user);
        })
        .catch(() => {
          alert("Errore durante il caricamento dell'esperienza");
        });
    }
  }

  useEffect(() => {
    if (user && experience) {
      setLoading(false);
    }
  }, [user, experience]);

  useEffect(() => {
    if (userLogged) {
      experienceDetail();
    }
  }, [userLogged]);

  useEffect(() => {
    Start();
  }, []);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  const schema = [
    {
      title: "Biografia",
      label: "Parlaci di te",
      icon: bio,
      children: [],
      link: `/${id}`,
      component: <Biography />,
      completed: user?.bio ? true : false,
      active: true,
    },
    {
      title: "Tipo di esperienza",
      label: "A quale mondo appartiene la tua esperienza?",
      icon: tipologia,
      children: [],
      link: `/${id}/kind`,
      component: <ExperienceKind />,
      completed: experience?.category ? true : false,
      active: user?.bio ? true : false,
    },
    {
      title: "L'esperienza",
      icon: esperienza,
      children: [
        {
          title: "Luogo",
          label: "Dove vorresti organizzare la tua esperienza? ",
          link: `/${id}/location`,
          component: <Location />,
          completed: experience?.address ? true : false,
          active: experience?.category ? true : false,
        },
        {
          title: "Dettagli",
          label: "Andiamo nel dettaglio",
          link: `/${id}/details`,
          component: <Details />,
          completed:
            experience?.name && experience?.description
              ? experience?.languages?.length > 0 && experience?.duration
                ? true
                : false
              : false,
          active: experience?.address ? true : false,
        },
        {
          title: "Requisiti",
          label: "Requisiti",
          link: `/${id}/requirements`,
          component: <Requirements />,
          completed:
            (experience.category === "outdoor"
              ? experience?.experience_requirements && experience?.difficulty
              : true) &&
            experience?.minimum_age &&
            experience?.included?.length > 0
              ? true
              : false,
          active:
            experience?.name && experience?.description
              ? experience?.languages?.length > 0 && experience?.duration
                ? true
                : false
              : false,
        },
        {
          title: "Foto/video",
          label: "Aggiungi le tue foto",
          link: `/${id}/media`,
          component: <Media />,
          completed: experience?.images?.length > 0 ? true : false,
          active:
            (experience.category === "outdoor"
              ? experience?.experience_requirements && experience?.difficulty
              : true) &&
            experience?.minimum_age &&
            experience?.included?.length > 0
              ? true
              : false,
        },
        {
          title: "Itinerario",
          label: "Itinerario di viaggio",
          link: `/${id}/itinerary`,
          component: <Itinerary />,
          completed: true,
          active: experience?.images?.length > 0 ? true : false,
        },
      ],
      active: experience?.category ? true : false,
    },
    {
      title: "Impostazioni",
      icon: requisiti,
      children: [
        {
          title: "N° max partecipanti",
          label: "Posti disponibili",
          link: `/${id}/maxParticipants`,
          component: <MaxPartecipants />,
          completed: experience?.max_group_size ? true : false,
          active: experience?.images?.length > 0 ? true : false,
        },
        {
          title: "Tariffa",
          label: "Tariffa",
          link: `/${id}/price`,
          component: <Price />,
          completed: experience.price ? true : false,
          active: experience?.max_group_size ? true : false,
        },
        {
          title: "Tempi di prenotazione",
          label: "Tempi di prenotazione",
          link: `/${id}/bookingTime`,
          component: <BookingTime />,
          completed:
            experience.bookable_until && experience.cancellation_policy
              ? true
              : false,
          active: experience.price ? true : false,
        },
        {
          title: "Approvazione",
          label: "Abilita l'approvazione automatica",
          link: `/${id}/approval`,
          component: <ApprovalType />,
          completed: true,
          active:
            experience.bookable_until && experience.cancellation_policy
              ? true
              : false,
        },
        {
          title: experience.isRecurrent ? "Ricorrenza" : "Data e ora",
          label: "Data e ora",
          link: `/${id}/recurrence`,
          component: <Recurrency />,
          completed: experience.data_info.start_time ? true : false,
          active:
            experience.bookable_until && experience.cancellation_policy
              ? true
              : false,
        },
        {
          title: "Escobox",
          label: "Aderisci ad Escobox",
          link: `/${id}/escobox`,
          component: <Escobox />,
          completed: experience.terms_accepted_at,
          active: experience.data_info.start_time ? true : false,
        },
      ],
      active: experience?.images?.length > 0 ? true : false,
    },
  ];

  const schemaWithoutChildren = schema.flatMap((item) =>
    item.children.length > 0 ? item.children : item
  );

  const completion = () => {
    let index = 0;
    for (let i = 0; i < schema.length; i++) {
      const item = schema[i];
      if (item.link === `/${id}/${tab}`) {
        return index;
      } else if (item.children) {
        if (
          item.children.findIndex((child) => child.link === `/${id}/${tab}`) !==
          -1
        ) {
          index += item.children.findIndex(
            (child) => child.link === `/${id}/${tab}`
          );
          return index;
        }
        if (!item.children.some((child) => child.link === `/${id}/${tab}`)) {
          index += item.children.length;
        }
      }
      if (item.children.length === 0) {
        index++;
      }
    }
    return index;
  };

  const previous = () => {
    const currentIndex = schema.findIndex(
      (item) =>
        item.link === `/${id}/${tab}` ||
        (item.children &&
          item.children.some((child) => child.link === `/${id}/${tab}`))
    );

    let previousLink;

    if (currentIndex > 0) {
      const previousItem = schema[currentIndex];
      if (previousItem.children) {
        previousLink = previousItem.children.findIndex(
          (child) => child.link === `/${id}/${tab}`
        );
        previousLink = previousItem.children[previousLink - 1]?.link;
      }
    }

    if (!previousLink) {
      previousLink = schema[currentIndex - 1]?.link
        ? schema[currentIndex - 1]?.link
        : schema[currentIndex - 1]?.children[
            schema[currentIndex - 1]?.children.length - 1
          ]?.link;
    }

    return previousLink;
  };

  const next = () => {
    const currentIndex = !tab
      ? 0
      : schema.findIndex(
          (item) =>
            item.link === `/${id}/${tab}` ||
            (item.children &&
              item.children.some((child) => child.link === `/${id}/${tab}`))
        );

    let nextLink;

    if (currentIndex < schema.length - 1 || completion() !== 12) {
      const nextItem = schema[currentIndex];
      if (nextItem.children) {
        nextLink = nextItem.children.findIndex(
          (child) => child.link === `/${id}/${tab}`
        );
        nextLink = nextItem.children[nextLink + 1]?.link;
      }
      if (!nextLink) {
        nextLink = schema[currentIndex + 1]?.link
          ? schema[currentIndex + 1]?.link
          : schema[currentIndex + 1]?.children[0]?.link;
      }
    }

    return nextLink;
  };

  const matchingChild = schema
    .flatMap((item) => item.children || [])
    .find((child) => child.link === `/${id}/${tab}`);

  const matchingItem = matchingChild
    ? { component: matchingChild.component }
    : schema.find((item) => item.link === `/${id}/${tab}`);

  const notify = () =>
    toast.error(
      errors
        .map((error) => error.error && error.error)
        .filter(Boolean)
        .join(", "),
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      }
    );

  document.title = "Esco | La tua esperienza";

  return (
    <div className="relative">
      <header>
        <NavbarCreator />
      </header>
      <main>
        <div className="mx-0 2xl:mx-8">
          <div className="flex h-full mx-4 mt-24 md:mt-32 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
            <div className="flex-col hidden w-1/4 h-full md:flex gap-y-4 ">
              {!experience.request_of_approval_made_at && (
                <button
                  className="flex flex-col w-full bg-gray-100 rounded-[30px] p-4 gap-y-4"
                  onClick={() => navigate(`/editEventKind/${id}`)}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5" />
                    <div className="flex items-center justify-center w-full">
                      <p className="text-sm font-bold">
                        Modifica tipologia di esperienza
                      </p>
                    </div>
                  </div>
                </button>
              )}
              {experience.edit_requested ? (
                <div className="flex flex-col gap-4 w-full bg-gray-100 rounded-[30px] p-4 gap-x-4">
                  <div className="flex items-center justify-between gap-x-4">
                    <div className="flex items-center gap-x-2">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="w-5 h-5 text-red-500"
                      />
                      <p className="text-sm font-bold">
                        L'esperienza richiede modifiche
                      </p>
                    </div>
                    <button
                      onClick={() => setOpenEditRequested((prev) => !prev)}
                      className="text-xs text-gray-400"
                    >
                      {openEditRequested ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </button>
                  </div>
                  {openEditRequested && (
                    <div className="w-full text-sm text-gray-400">
                      {experience.edit_requested}
                    </div>
                  )}
                </div>
              ) : (
                <UserProfileCard user={user} setUser={setUser} />
              )}
              <div className="flex flex-col items-center justify-center w-full bg-gray-100 rounded-[20px] gap-y-0.5 ">
                {schema.map((item, index) => (
                  <SingleLink key={index} item={item} baseUrl={`/propose`} />
                ))}
              </div>
            </div>
            <ProposeContext.Provider
              value={{
                user,
                setUser,
                experience,
                setExperience,
                errors,
                setErrors,
              }}
            >
              <div className="relative flex flex-col w-full pb-32 md:w-3/4">
                <div className="flex items-center justify-between w-full py-4 gap-x-4 md:hidden">
                  <button
                    className="px-3 py-2 border-2 rounded-full border-primary h-fit"
                    onClick={() => {
                      setErrors([]);
                      if (previous()) {
                        navigate(`/propose${previous()}`);
                      } else {
                        navigate(`/propose${schema[0].link}`);
                      }
                    }}
                  >
                    <img alt="back" src={indietro} className="w-4 h-4" />
                  </button>
                  <div className="w-full font-bold text-center flex items-center justify-center text-primary h-[48px]">
                    {tab
                      ? schema[
                          schema.findIndex(
                            (item) =>
                              item.link === `/${id}/${tab}` ||
                              (item.children &&
                                item.children.some(
                                  (child) => child.link === `/${id}/${tab}`
                                ))
                          )
                        ].children.length > 0
                        ? schema[
                            schema.findIndex(
                              (item) =>
                                item.link === `/${id}/${tab}` ||
                                (item.children &&
                                  item.children.some(
                                    (child) => child.link === `/${id}/${tab}`
                                  ))
                            )
                          ].children.find(
                            (child) => child.link === `/${id}/${tab}`
                          ).label
                        : schema[
                            schema.findIndex(
                              (item) =>
                                item.link === `/${id}/${tab}` ||
                                (item.children &&
                                  item.children.some(
                                    (child) => child.link === `/${id}/${tab}`
                                  ))
                            )
                          ].label
                      : schema[0].label}
                  </div>
                  <button
                    className="px-3 py-2 border-2 rounded-full border-primary h-fit"
                    onClick={() => {
                      if (
                        schemaWithoutChildren[!tab ? 0 : completion()]
                          .completed &&
                        !errors.some((e) => e.error !== null)
                      ) {
                        setErrors([]);
                        if (next()) {
                          navigate(`/propose${next()}`);
                        } else {
                          navigate(`/experience/preview/${id}`);
                        }
                      } else {
                        notify();
                      }
                    }}
                  >
                    <img
                      alt="next"
                      src={indietro}
                      className="w-4 h-4 transform rotate-180"
                    />
                  </button>
                </div>
                {matchingItem?.component || <Biography />}
              </div>
            </ProposeContext.Provider>
            {experience.edit_requested && (
              <div className="relative md:hidden">
                <div
                  className={`fixed z-50 ${
                    openEditRequested
                      ? "w-full h-full bottom-0 right-0 bg-white"
                      : "w-16 h-16 bottom-14 right-4 rounded-full bg-primary shadow-md border-2 border-white"
                  } transition-all duration-300 ease-in-out`}
                  onClick={() => setOpenEditRequested((prev) => !prev)}
                >
                  {openEditRequested ? (
                    <div className="flex flex-col gap-4 p-6">
                      <div className="flex items-center gap-x-4">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="w-5 h-5 text-red-500"
                        />
                        <p className="text-lg font-bold">
                          L'esperienza richiede modifiche
                        </p>
                      </div>
                      <p className="text-gray-600">
                        {experience.edit_requested}
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full h-full">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="w-6 h-6 mb-1 text-white"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
            />
          </div>
        </div>
        <div className="fixed bottom-0 w-full ">
          <div className="h-full pt-4 mx-4 md:mx-8">
            <div className="relative flex max-w-screen-2xl 2xl:mx-auto">
              <div className="hidden w-1/4 mx-2 md:flex" />
              <div className="w-full bg-white border-t md:ml-12 md:w-3/4 ">
                <div className="w-full my-6 md:shadow-inner md:shadow-md flex items-center md:px-12 md:py-4 md:bg-gray-100 gap-x-4 rounded-[40px] ">
                  <div className="hidden md:block">
                    {tab && (
                      <button
                        className="px-2 py-1 border border-black rounded-full "
                        onClick={() => {
                          setErrors([]);
                          if (previous()) {
                            navigate(`/propose${previous()}`);
                          } else {
                            navigate(`/propose${schema[0].link}`);
                          }
                        }}
                      >
                        <img alt="back" src={indietro} className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                  <div className="w-full">
                    <ProgressBar
                      bgColor="#246155"
                      customLabel={
                        tab ? `${((100 / 12) * completion()).toFixed(0)}%` : 0
                      }
                      isLabelVisible={false}
                      labelAlignment="center"
                      labelClassName={`${
                        !tab ? "text-[#e0e0de]" : "text-[#246155] md:text-white"
                      } text-sm font-normal`}
                      maxCompleted={12}
                      completed={!tab ? 0 : completion()}
                    />
                  </div>
                  <div className="hidden md:block">
                    {completion() !== 12 && (
                      <button
                        className="px-2 py-1 border border-black rounded-full "
                        onClick={() => {
                          if (
                            schemaWithoutChildren[!tab ? 0 : completion()]
                              .completed &&
                            !errors.some((e) => e.error !== null)
                          ) {
                            setErrors([]);
                            navigate(`/propose${next()}`);
                          } else {
                            notify();
                          }
                        }}
                      >
                        <img
                          alt="next"
                          src={indietro}
                          className="w-4 h-4 transform rotate-180"
                        />
                      </button>
                    )}
                    {completion() === 12 && (
                      <button
                        className="px-2 py-1 border border-black rounded-full "
                        onClick={() => {
                          if (
                            schemaWithoutChildren[12].completed &&
                            !errors.some((e) => e.error !== null)
                          ) {
                            setErrors([]);
                            navigate(`/experience/preview/${id}`);
                          } else notify();
                        }}
                      >
                        <p className="font-semibold">Anteprima</p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
