import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faSquarePen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalNewFaq from "./components/ModalNewFaq";
import ModalNewFaqSection from "./components/ModalNewFaqSection";

function SingleFaq({ faq, setEditFaq, setOpenModalFaq, deleteFaq }) {
  const [openDetailFaq, setOpenDetailFaq] = useState(false);

  return (
    <div className="flex items-start justify-between w-full p-2 px-4 rounded-lg bg-primary">
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center gap-x-4">
          <p className="font-semibold text-white text-primary">
            {faq.question}
          </p>
          <button
            className="flex items-center text-sm text-white"
            onClick={() => {
              setOpenDetailFaq(!openDetailFaq);
            }}
          >
            {openDetailFaq ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </button>
        </div>
        {openDetailFaq && (
          <>
            <div className="flex items-center text-sm gap-x-2">
              {faq?.tags.map((tag, index) => (
                <span
                  key={index}
                  className="px-2 bg-white rounded-md text-primary"
                >
                  {tag}
                </span>
              ))}
            </div>
            <p className="text-xs text-white">{faq.answer}</p>
          </>
        )}
      </div>
      <div className="flex items-center gap-x-4 mt-0.5">
        <button
          className="flex items-center text-xl text-white"
          onClick={() => {
            setEditFaq(faq);
            setOpenModalFaq(true);
          }}
        >
          <FontAwesomeIcon icon={faSquarePen} />
        </button>
        <button
          className="flex items-center text-white"
          onClick={() => deleteFaq(faq)}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </div>
    </div>
  );
}

export default function ManageFaq() {
  const [faqSections, setFaqSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalFaqSection, setOpenModalFaqSection] = useState(false);
  const [openModalFaq, setOpenModalFaq] = useState(false);
  const [editFaqSection, setEditFaqSection] = useState(null);
  const [editFaq, setEditFaq] = useState(false);
  const [randomKey, setRandomKey] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/faqSectionsList`)
      .then((res) => {
        setFaqSections(res.data);
        setLoading(false);
        setTimeout(() => {
          setRandomKey(Math.random());
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteFaqSection = (faqSection) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/faqSectionDelete/${faqSection._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setFaqSections(faqSections.filter((faq) => faq._id !== faqSection._id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteFaq = (faq) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/faqDelete/${faq._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFaqSections(
          faqSections.map((faqSection) => {
            if (faqSection._id === faq.faq_section_id) {
              faqSection.faqs = faqSection.faqs.filter(
                (faqItem) => faqItem._id !== faq._id
              );
            }
            return faqSection;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // se l'elemento non è stato rilasciato in una posizione valida, non fare nulla

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return; // se l'elemento è stato rilasciato nella stessa posizione da cui è stato trascinato, non fare nulla

    const faqSectionId = result.destination.droppableId.split("-")[1];
    const faqId = result.draggableId;

    if (faqSectionId !== result.source.droppableId.split("-")[1]) return;

    const token = localStorage.getItem("token");

    setFaqSections(
      faqSections.map((faqSection) => {
        if (faqSection._id === faqSectionId) {
          const faq = faqSection.faqs.splice(result.source.index, 1)[0];
          faqSection.faqs.splice(result.destination.index, 0, faq);
        }
        return faqSection;
      })
    );

    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/faqUpdate/${faqId}`,
        {
          position: result.destination.index,
          faq_section_id: faqSectionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("position updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const moveSection = (direction, faqSection) => {
    const token = localStorage.getItem("token");
    const faqSectionsCopy = [...faqSections];
    const index = faqSectionsCopy.indexOf(faqSection);
    if (direction === "up") {
      if (index === 0) return;
      const temp = faqSectionsCopy[index];
      faqSectionsCopy[index] = faqSectionsCopy[index - 1];
      faqSectionsCopy[index - 1] = temp;
    } else if (direction === "down") {
      if (index === faqSectionsCopy.length - 1) return;
      const temp = faqSectionsCopy[index];
      faqSectionsCopy[index] = faqSectionsCopy[index + 1];
      faqSectionsCopy[index + 1] = temp;
    }

    setFaqSections(faqSectionsCopy);

    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/faqSectionUpdate/${faqSection._id}`,
        {
          position: index + (direction === "up" ? -1 : 1),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("position updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <UiduSpinner size="medium" className="text-primary" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center justify-between w-full">
            <h1 className="font-bold text-primary">FAQS</h1>
            <div className="flex items-center gap-x-4">
              <button
                onClick={() => setOpenModalFaqSection(true)}
                className="flex items-center px-4 py-2 text-white rounded-md gap-x-4 bg-primary"
              >
                <p>Aggiungi Faq Section</p>
              </button>
              <button
                onClick={() => setOpenModalFaq(true)}
                className="flex items-center px-4 py-2 text-white rounded-md gap-x-4 bg-primary"
              >
                <p>Aggiungi Faq</p>
              </button>
            </div>
          </div>
          {openModalFaqSection && (
            <ModalNewFaqSection
              faqSections={faqSections}
              setFaqSections={setFaqSections}
              editFaqSection={editFaqSection}
              setEditFaqSection={setEditFaqSection}
              setOpenModalFaqSection={setOpenModalFaqSection}
            />
          )}
          {openModalFaq && (
            <ModalNewFaq
              faqSections={faqSections}
              setFaqSections={setFaqSections}
              editFaq={editFaq}
              setEditFaq={setEditFaq}
              setOpenModalFaq={setOpenModalFaq}
            />
          )}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            {faqSections.length > 0 ? (
              <div className="flex flex-col gap-y-4">
                {faqSections.map((faqSection, index) => (
                  <div className="flex items-center w-full gap-x-4">
                    <div className="flex flex-col gap-y-2">
                      <button
                        className="p-2 bg-white rounded-md text-primary"
                        onClick={() => moveSection("up", faqSection)}
                      >
                        <FontAwesomeIcon icon={faChevronUp} />
                      </button>
                      <button
                        className="p-2 bg-white rounded-md text-primary"
                        onClick={() => moveSection("down", faqSection)}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </button>
                    </div>
                    <div
                      className="flex flex-col w-full p-4 bg-white rounded-md gap-y-2"
                      key={index}
                    >
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-x-4">
                          <p className="font-semibold text-primary">
                            {faqSection.name}
                          </p>
                          <p className="text-xs text-gray-500">
                            {faqSection.faqs.length} Faq
                          </p>
                        </div>
                        <div className="flex items-center gap-x-6">
                          <button
                            className="flex items-center text-xl rounded-md gap-x-4 text-primary"
                            onClick={() => {
                              setEditFaqSection(faqSection);
                              setOpenModalFaqSection(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faSquarePen} />
                          </button>
                          <button
                            className="flex items-center rounded-md gap-x-4 text-primary"
                            onClick={() => deleteFaqSection(faqSection)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </div>
                      </div>
                      <Droppable
                        droppableId={`faqSection-${faqSection._id}`}
                        key={randomKey + index}
                        direction="vertical"
                      >
                        {(provided) => (
                          <div
                            className="flex flex-col w-full pl-6 gap-y-2"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {faqSection?.faqs.map((faq, index) => (
                              <Draggable
                                key={faq._id}
                                draggableId={faq._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <SingleFaq
                                      faq={faq}
                                      setEditFaq={setEditFaq}
                                      setOpenModalFaq={setOpenModalFaq}
                                      deleteFaq={deleteFaq}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-lg text-gray-500 ">Nessuna Faq Section</p>
            )}
          </DragDropContext>
        </div>
      )}
    </div>
  );
}
