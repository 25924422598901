import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function ImageModal({
  openModal,
  setOpenModal,
  images = [],
  imageToStart = 0,
}) {
  return (
    <ModalTransition>
      {openModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <Modal
            onClose={() => setOpenModal(false)}
            className="mt-0 md:mt-5"
            width="x-large"
          >
            <ModalHeader>
              <h3 className="text-2xl">Gallery</h3>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                loop={true}
                initialSlide={imageToStart}
              >
                {images.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt=""
                      className="object-cover rounded-lg h-[calc(100vh-200px)] w-full"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}

export default function ImageGroup({ images, h = 96 }) {
  const [openModal, setOpenModal] = useState(false);
  const [imageToStart, setImageToStart] = useState(0);

  if (images.length === 1) {
    return (
      <div
        className={`h-${h} overflow-hidden cursor-pointer`}
        onClick={() => {
          setOpenModal(true);
          setImageToStart(0);
        }}
      >
        <img
          src={images[0]}
          alt="experience"
          className="object-cover object-center w-full h-full"
        />
        {openModal && (
          <ImageModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            images={images}
            imageToStart={imageToStart}
          />
        )}
      </div>
    );
  } else if (images.length === 2) {
    return (
      <div className={`grid grid-cols-4 gap-x-4 h-${h} cursor-pointer`}>
        <div className="h-full col-span-3 overflow-hidden">
          <img
            src={images[0]}
            alt="experience"
            className="object-cover object-center w-full h-full"
            onClick={() => {
              setOpenModal(true);
              setImageToStart(0);
            }}
          />
        </div>
        <img
          src={images[1]}
          alt="experience"
          className="object-cover object-center w-full h-full"
          onClick={() => {
            setOpenModal(true);
            setImageToStart(1);
          }}
        />
        {openModal && (
          <ImageModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            images={images}
            imageToStart={imageToStart}
          />
        )}
      </div>
    );
  }

  return (
    <div className={`grid grid-cols-4 gap-x-4 h-${h} cursor-pointer`}>
      <div className="h-full col-span-3 overflow-hidden">
        <img
          src={images[0]}
          alt="experience"
          className="object-cover object-center w-full h-full"
          onClick={() => {
            setOpenModal(true);
            setImageToStart(0);
          }}
        />
      </div>
      <div className={`grid grid-rows-2 gap-y-4 h-${h}`}>
        <img
          src={images[1]}
          alt="experience"
          className="object-cover object-center w-full h-full overflow-hidden"
          onClick={() => {
            setOpenModal(true);
            setImageToStart(1);
          }}
        />
        <div className="relative">
          <img
            src={images[2]}
            alt="experience"
            className="object-cover object-center w-full h-full overflow-hidden"
            onClick={() => {
              setOpenModal(true);
              setImageToStart(2);
            }}
          />
          {images.length > 3 && (
            <div
              className="absolute inset-0 flex items-center justify-center w-full h-full text-3xl text-white bg-black bg-opacity-50"
              onClick={() => {
                setOpenModal(true);
                setImageToStart(2);
              }}
            >
              + {images.length - 3}
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <ImageModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          images={images}
          imageToStart={imageToStart}
        />
      )}
    </div>
  );
}
