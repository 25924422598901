import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ElementsCartContext, FavouriteContext, UserContext } from "../App";

export function SignInButton() {
  const { fromApp } = useContext(UserContext);

  const handleGoogleSuccess = async (response) => {
    const idToken = response.credential;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/google/mobile`,
        { token: idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.data;
      if (res.status === 200 || res.status === 201) {
        // Gestisci il successo del login
        console.log("Login successful", data);
      } else {
        // Gestisci l'errore del login
        console.error("Login failed", data);
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In error", error);
  };

  if (fromApp) {
    return (
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
        useOneTap
        className="items-center justify-center w-full flex gap-x-4 py-2 text-primary border border-tertiary rounded-[37px] cursor-pointer hover:bg-gray-50"
      />
    );
  } else {
    return (
      <a
        href={`${process.env.REACT_APP_SERVER_URL}/api/loginGoogle`}
        target="_self"
        className="items-center justify-center w-full flex gap-x-4 py-2 text-primary border border-tertiary rounded-[37px] cursor-pointer hover:bg-gray-50"
      >
        <img
          className="h-5"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/google/google-original.svg"
        />
        <div>Accedi con Google</div>
      </a>
    );
  }
}

export default function LoginForm({ serverLink, setUserLogged = null }) {
  const [error, setError] = useState(null);
  const [checkedConnected, setCheckedConnected] = useState(false);
  const methods = useForm();
  const { register, handleSubmit, setValue } = useForm();
  const { setFavourite } = useContext(FavouriteContext);
  const { setElements } = useContext(ElementsCartContext);
  const { setUser } = useContext(UserContext);

  const getFavouriteAndCart = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/favourite/${user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFavourite(res.data);
        await axios
          .get(
            `${
              process.env.REACT_APP_SERVER_URL
            }/api/cart/${localStorage.getItem("user")}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (!res.data.message) {
              setElements(res.data.elements);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logUser = async (data) => {
    if (setUserLogged === null) {
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/loginAdmin`, data)
        .then((res) => {
          if (res.data.msg === "User is authorized") {
            setError(null);
            const now = new Date();
            const item = {
              value: true,
              expiry: now.getTime() + 1000 * 60 * 60 * 3,
            };

            localStorage.setItem("isEscoAuthorizedUser", JSON.stringify(item));
            window.location.reload();
          } else {
            setError("Utente non autorizzato");
          }
        })
        .catch(() => {
          setError("Errore durante la connessione al database");
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/login`, data)
        .then((res) => {
          console.log(res.data);
          if (res.data.user !== false) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", res.data.user._id);
            setUser(res.data.user);
            setUserLogged(true);
            getFavouriteAndCart();
          } else {
            setError("Email o password errati");
          }
        })
        .catch(() => {
          setError("Errore durante la connessione al database");
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className="w-full h-full" onSubmit={handleSubmit(logUser)}>
        <div className="flex flex-col justify-center justify-between h-full gap-y-4 md:">
          <div
            key={"login"}
            className="flex flex-col justify-center h-full gap-y-4"
          >
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="Email"
              id="email"
              name="email"
              {...register("email")}
            />
            <input
              className="w-full px-3 py-2 mt-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Password"
              id="password"
              name="password"
              {...register("password")}
            />
            {error && (
              <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
                {error}
              </div>
            )}
            {setUserLogged && (
              <div className="flex items-center justify-between text-sm gap-x-4 text-primary">
                <label htmlFor="connect" className="flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    id="connect"
                    name="connect"
                    className="hidden"
                    defaultChecked={false}
                    {...register("connect")}
                  />
                  <div
                    className={`w-4 h-4 border rounded-sm shadow-sm text-white flex items-center justify-center ${
                      checkedConnected
                        ? "bg-primary border-primary"
                        : "bg-white border-gray-400"
                    }`}
                    onClick={() => {
                      setValue("connect", checkedConnected);
                      setCheckedConnected(!checkedConnected);
                    }}
                  >
                    {checkedConnected && (
                      <FontAwesomeIcon className="h-3" icon={faCheck} />
                    )}
                  </div>
                  Rimani collegato
                </label>
                <Link
                  to="/forgotPassword"
                  className="text-primary hover:underline"
                >
                  Password dimenticata?
                </Link>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="w-full border-b border-tertiary" />
            <input
              className="w-full py-2 text-primary border border-tertiary rounded-[37px] self-end"
              type="submit"
              value={"Accedi"}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
