import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";

export default function ConfirmEmail() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useParams();
  const initialized = useRef(false);

  useEffect(() => {
    document.title = "Esco | Confirm Email";
    document
      .getElementById("confirmEmailPage")
      .scrollIntoView({ behavior: "smooth" }, { block: "start" });
  }, []);

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/confirmEmail`, {
        token,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.msg === "Email verified") {
          setSuccess("Email verificata con successo!");
        } else {
          setError("Errore durante la conferma dell'email");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Errore durante la conferma dell'email");
      });
  }, []);

  return (
    <div id="confirmEmailPage">
      <header>
        <Header />
      </header>
      <main>
        <div className={`relative`}>
          <div
            className={`flex flex-col w-full rounded-[60px] h-[calc(100vh-24rem)] md:mx-10 max-w-screen-2xl 2xl:mx-auto`}
          >
            <div className="relative flex flex-col items-center justify-center mt-48 gap-y-4">
              <div className="md:w-full max-w-xl h-full min-h-[250px] mx-4 md:mx-0">
                <div className="flex relative pt-16 flex-col items-center border justify-between md:w-full h-full p-8 bg-gray-100 md:bg-white rounded-[37px] md:shadow-lg">
                  <div className="flex flex-col w-full h-full gap-y-4">
                    {loading ? (
                      <div className="flex flex-col w-full gap-y-4">
                        <div className="flex items-center justify-center w-full h-full">
                          <UiduSpinner size="large" />
                        </div>
                        <p className="pt-4 text-lg text-center text-primary">
                          Stiamo verificando il tuo account...
                        </p>
                      </div>
                    ) : error ? (
                      <div className="flex flex-col w-full gap-y-4">
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          size="3x"
                          className="text-red-500"
                        />
                        <p className="text-lg text-center text-red-500">
                          Qualcosa è andato storto...
                        </p>
                        <p className="text-sm text-center text-gray-700">
                          {error}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col w-full gap-y-4">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="3x"
                          className="text-green-500"
                        />
                        <p className="text-lg text-center text-green-500">
                          Account verificato con successo!
                        </p>
                        <p className="text-sm text-center text-gray-700">
                          Accedi al tuo account per iniziare a utilizzare Esco
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
