import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function EditPassword({ setOpenEditPassword }) {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const { id } = useParams();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const oldPassword = document.getElementById("oldPassword").value;
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    if (oldPassword === "" || password === "" || confirmPassword === "") {
      setError("Compilare tutti i campi");
      return;
    }
    if (password !== confirmPassword) {
      setError("Le password non corrispondono");
      return;
    }
    if (password.length < 8) {
      setError("La password deve essere di almeno 8 caratteri");
      return;
    }
    if (password === oldPassword) {
      setError("La nuova password non può essere uguale a quella vecchia");
      return;
    }
    setError(null);
    const data = {
      oldPassword,
      password,
    };
    const token = localStorage.getItem("token");
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/user/editPassword/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "Password updated") {
          setOpenEditPassword(false);
        } else {
          console.log(res.data);
          setError("Errore durante l'aggiornamento della password");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Errore durante l'aggiornamento della password");
      });
  };

  return (
    <div className="flex flex-col justify-between w-full gap-y-6">
      <div className="flex flex-col w-full gap-y-4">
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">Vecchia password</p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showOldPassword ? "text" : "password"}
              id="oldPassword"
              name="oldPassword"
              placeholder="Password"
              autoComplete="new-password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowOldPassword(!showOldPassword);
              }}
            >
              {showOldPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">Nuova password</p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
        <div className="relative flex flex-col w-full gap-y-2">
          <p className="font-semibold">Conferma password</p>
          <div className="relative w-full">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none md:mt-2 focus:outline-none focus:shadow-outline"
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Password"
            />
            <span
              className="absolute right-0 mt-1.5 mr-3 text-gray-300 cursor-pointer top-2"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showConfirmPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
        </div>
      </div>
      {error && (
        <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
          {error}
        </div>
      )}
      <div className="flex justify-end w-full mt-16 mb-8 gap-x-8">
        <button
          className="flex items-center justify-center px-4 py-2 text-white bg-gray-400 rounded-md"
          type="button"
          onClick={() => setOpenEditPassword(false)}
        >
          Annulla
        </button>
        <button
          className="flex items-center justify-center px-4 py-2 text-white rounded-md bg-primary"
          type="button"
          onClick={(e) => handleUpdatePassword(e)}
        >
          Salva
        </button>
      </div>
    </div>
  );
}
