import useHover from "@react-hook/hover";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import art from "../../assets/art.svg";
import artBlack from "../../assets/artBlack.svg";
import authentic from "../../assets/auth.svg";
import authenticBlack from "../../assets/authBlack.svg";
import outdoor from "../../assets/out.svg";
import outdoorBlack from "../../assets/outBlack.svg";
import wine from "../../assets/wine.svg";
import wineBlack from "../../assets/wineBlack.svg";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

function SingleButton({ item, selectedKind, setSelectedKind }) {
  const target = useRef(null);
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });

  return (
    <button
      ref={target}
      className="w-full h-full text-black border-2 rounded-lg"
      style={{
        borderColor: item.color,
        backgroundColor:
          isHovering || selectedKind === item.id ? item.color : "",
        color: isHovering || selectedKind === item.id ? "white" : "",
      }}
      onClick={() => setSelectedKind(item.id)}
    >
      <div className="flex flex-col items-center justify-center w-full h-full gap-2 md:flex-row">
        {isHovering || selectedKind === item.id ? (
          <img className="w-5 h-5" src={item.img} alt="wine" />
        ) : (
          <img className="w-5 h-5" src={item.imgBlack} alt="wine" />
        )}
        <p className="text-center">{item.text}</p>
      </div>
    </button>
  );
}

export default function ExperienceKind() {
  const { id } = useParams();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [selectedKind, setSelectedKind] = useState(
    experience?.category || null
  );

  const updateExperience = async () => {
    const token = localStorage.getItem("token");

    const data = {
      category: selectedKind,
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante la modifica dell'esperienza");
      });
  };

  useEffect(() => {
    if (experience && selectedKind && selectedKind !== experience.category) {
      setErrors([]);
      updateExperience();
    } else if (selectedKind === null) {
      setErrors([
        { error: "Devi selezionare una categoria per la tua esperienza!" },
      ]);
    }
  }, [selectedKind, experience]);

  const schema = [
    {
      text: "food & wine",
      id: "wine",
      color: "#612430",
      img: wine,
      imgBlack: wineBlack,
    },
    {
      text: "arte e cultura",
      id: "art",
      color: "#C8531C",
      img: art,
      imgBlack: artBlack,
    },
    {
      text: "outdoor",
      id: "outdoor",
      color: "#4C8AFB",
      img: outdoor,
      imgBlack: outdoorBlack,
    },
    {
      text: "authentic",
      id: "authentic",
      color: "#CA9526",
      img: authentic,
      imgBlack: authenticBlack,
    },
  ];

  return (
    <BaseComponent title="A quale mondo appartiene la tua esperienza?">
      <div className="grid grid-cols-2 gap-6 p-10 my-4 min-h-[400px]  overflow-y-auto bg-gray-100 rounded-md">
        {experience ? (
          <>
            {schema.map((item, index) => (
              <SingleButton
                key={index}
                item={item}
                selectedKind={selectedKind}
                setSelectedKind={setSelectedKind}
              />
            ))}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full col-span-2">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
