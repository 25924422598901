import {
  faCircleQuestion,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TimePicker from "react-time-picker";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";
import "./timepicker.css";

export default function Conditions() {
  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [valueCheckIn, onChangeCheckIn] = useState(
    structure?.check_in_time || "15:00"
  );
  const [valueCheckOut, onChangeCheckOut] = useState(
    structure?.check_out_time || "11:00"
  );
  const [selectedCancellation, setSelectedCancellation] = useState(
    structure?.cancellation_policy || ""
  );
  const animatedComponents = makeAnimated();
  const { id } = useParams();

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "checkIn", error: null },
      { id: "checkOut", error: null },
      { id: "minNights", error: null },
      { id: "cancellation", error: null },
    ];

    if (!valueCheckIn) {
      currentErrors[0].error = "Seleziona un orario di check-in";
    }

    if (!valueCheckOut) {
      currentErrors[1].error = "Seleziona un orario di check-out";
    }

    if (document.getElementById("minNights").value === "") {
      currentErrors[2].error = "Il numero minimo di notti deve essere definito";
    }

    if (!selectedCancellation) {
      currentErrors[3].error = "Seleziona un tempo di cancellazione";
    }

    setErrors(currentErrors);

    if (
      currentErrors[0].error ||
      currentErrors[1].error ||
      currentErrors[2].error ||
      currentErrors[3].error
    )
      return;

    const data = {
      check_in_time: valueCheckIn,
      check_out_time: valueCheckOut,
      minimum_stay: document
        .getElementById("minNights")
        .value.replace(" notti", ""),
      cancellation_policy: selectedCancellation,
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    if (structure) {
      if (valueCheckIn !== structure?.check_in_time) {
        updateStructure();
      }
      if (valueCheckOut !== structure?.check_out_time) {
        updateStructure();
      }
      if (selectedCancellation !== structure?.cancellation_policy) {
        updateStructure();
      }
    }
  }, [valueCheckIn, structure, valueCheckOut, selectedCancellation]);

  return (
    <BaseComponent title="Condizioni">
      <div className="flex flex-col px-4 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md md:px-10 gap-y-6">
        {structure ? (
          <>
            <p className="font-semibold">Check-in</p>
            <div className="w-full bg-white h-[35px] shadow border rounded border-gray-200 pl-2">
              <TimePicker
                onChange={onChangeCheckIn}
                value={valueCheckIn}
                disableClock={true}
                name="checkIn"
                className="w-full h-full"
                hourPlaceholder="15"
                minutePlaceholder="00"
              />
            </div>
            {/* {error[0].error && (
              <p className="-mt-3 text-sm text-gray-400">{error[0].error}</p>
            )} */}
            <p className="font-semibold">Check-out</p>
            <div className="w-full bg-white h-[35px] shadow border rounded border-gray-200 pl-2">
              <TimePicker
                onChange={onChangeCheckOut}
                value={valueCheckOut}
                disableClock={true}
                name="checkOut"
                className="w-full h-full"
                hourPlaceholder="12"
                minutePlaceholder="00"
              />
            </div>
            {/* {error[1].error && (
              <p className="-mt-3 text-sm text-gray-400">{error[1].error}</p>
            )} */}
            <div className="flex items-center justify-between">
              <p className="font-semibold">Numero minimo di notti</p>
              <Tooltip
                content="Il numero minimo di notti che un utente può prenotare nella tua struttura"
                position="left"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="cursor-pointer text-primary"
                />
              </Tooltip>
            </div>
            <CurrencyInput
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
              placeholder="2 notti"
              id="minNights"
              min={0}
              suffix=" notti"
              decimalsLimit={0}
              decimalScale={0}
              allowNegativeValue={false}
              defaultValue={structure?.minimum_stay || ""}
              onValueChange={() => updateStructure()}
            />
            {/* {error[2].error && (
              <p className="-mt-3 text-sm text-gray-400">{error[2].error}</p>
            )} */}
            <div className="flex items-center justify-between">
              <p className="font-semibold">Tipologia di cancellazione</p>
              <Tooltip
                content="Il tempo massimo entro cui un utente può cancellare la prenotazione. es: 1 settimana prima della data di check-in"
                position="left"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="cursor-pointer text-primary"
                />
              </Tooltip>
            </div>
            <div className="w-full">
              <Select
                closeMenuOnSelect
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                defaultValue={
                  structure?.cancellation_policy && {
                    value: structure?.cancellation_policy,
                    label:
                      structure?.cancellation_policy.charAt(0).toUpperCase() +
                      structure?.cancellation_policy.slice(1),
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={[
                  {
                    value: "flessibile",
                    label: "Flessibile",
                  },
                  {
                    value: "moderata",
                    label: "Moderata",
                  },
                  {
                    value: "rigida",
                    label: "Rigida",
                  },
                ]}
                onChange={(value) => {
                  setSelectedCancellation(value.value);
                }}
              />
            </div>
            <div className="flex p-6 text-white rounded-md bg-primary">
              <div className="flex flex-col justify-between w-full text-sm">
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center text-lg gap-x-4">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <p className="font-semibold">
                      Comprendi le tipologie &nbsp; &nbsp; 🧑‍🏫
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <p>
                      Esco dà ai propri partner la possibilità di definire i
                      tempi massimi in cui un utente può cancellare la
                      prenotazione e ottenere un rimborso completo. le tipologie
                      di cancellazione disponibili sono le seguenti:
                    </p>
                    <dl className="max-w-xl mt-2 space-y-3 text-white lg:max-w-none">
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Flessibile:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata entro 7 giorni dalla data di
                          check-in, sarà rimborsato il 50% se la cancellazione
                          avviene entro 48 ore dalla data di check-in.
                        </dd>
                      </div>
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Moderata:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata entro 15 giorni dalla data di
                          check-in, sarà rimborsato il 50% se la cancellazione
                          avviene entro 7 giorni dalla data di check-in.
                        </dd>
                      </div>
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Rigida:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata entro 30 giorni dalla data di
                          check-in, sarà rimborsato il 50% se la cancellazione
                          avviene entro 15 giorni dalla data di check-in.
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {/* {error[3].error && (
              <p className="-mt-3 text-sm text-gray-400">{error[3].error}</p>
            )} */}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
