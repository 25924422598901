import { faChevronRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import StructureIcon from "../StructureIcon";

export default function StructureToApprove() {
  const [structures, setStructures] = useState(null);

  const [loading, setLoading] = useState(true);

  const structuresDetail = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/structuresToApprove/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.msg === "No structures to approve") {
          setStructures([]);
        } else {
          setStructures(res.data);
        }
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    structuresDetail();
  }, []);

  return (
    <BaseComponent title="Strutture da approvare">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[512px] overflow-y-auto py-4 px-10 my-4">
        {!loading && structures.length > 0 ? (
          structures.map((structure, index) => (
            <>
              <div className="flex justify-between w-full gap-x-4">
                <div className="flex items-center w-full gap-x-4">
                  <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                    <StructureIcon kind={structure.kind} color={"black"} />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center gap-x-2">
                      <div className="font-bold">{structure.name}</div>
                      <div className="flex">
                        {Array.from({ length: structure.stars }, (_, i) => (
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-xs text-primary"
                          />
                        ))}
                      </div>
                    </div>
                    <div className="text-xs text-gray-600">
                      {structure.user_id.name} {structure.user_id.surname}
                    </div>
                  </div>
                </div>
                <Link
                  to={`/adminStructure/preview/${structure._id}`}
                  className="flex items-center justify-between w-64 h-8 px-4 text-white rounded-md bg-primary"
                >
                  Vedi anteprima
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </div>
              {index !== structures.length - 1 && (
                <div className="w-full border-b border-red" />
              )}
            </>
          ))
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <div className="flex w-full h-full text-gray-400">
            Nessuna struttura da approvare
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
