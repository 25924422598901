export const insertGTMHeadScript = (gtmId) => {
  const script = document.createElement("script");
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');`;
  const firstChild = document.head.childNodes[0];
  if (firstChild) {
    document.head.insertBefore(script, firstChild);
  } else {
    document.head.appendChild(script);
  }
};

export const insertGoogleAnalytics = (gaId) => {
  const scriptAsync = document.createElement("script");
  scriptAsync.async = true;
  scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  const firstChild = document.head.childNodes[0];
  if (firstChild) {
    document.head.insertBefore(scriptAsync, firstChild);
  } else {
    document.head.appendChild(scriptAsync);
  }
  const scriptConfig = document.createElement("script");
  scriptConfig.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gaId}');
  `;
  document.head.insertBefore(scriptConfig, scriptAsync.nextSibling);
};
export const insertGTMBodyScript = (gtmId) => {
  const noscript = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  iframe.height = "0";
  iframe.width = "0";
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  noscript.appendChild(iframe);
  document.body.insertBefore(noscript, document.body.firstChild);
};

export const insertFacebookPixel = (pixelId) => {
  const script = document.createElement("script");
  script.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');
  `;
  document.head.insertBefore(script, document.head.childNodes[0]);
};

export const insertLegalBlinkScripts = () => {
  const scriptSrc = document.createElement("script");
  scriptSrc.type = "text/javascript";
  scriptSrc.src = "https://app.legalblink.it/api/scripts/lb_cs.js";
  document.body.appendChild(scriptSrc);

  scriptSrc.onload = () => {
    const scriptInit = document.createElement("script");
    scriptInit.type = "text/javascript";
    scriptInit.id = "lb_cs";
    scriptInit.innerHTML = `lb_cs("666478df88c4fd001a95c2b2");`;
    document.body.appendChild(scriptInit);
  };
};
