import React from "react";

export default function CardUser({ user }) {
  console.log("user", user);
  return (
    <div className="flex flex-col p-4 bg-gray-200 border rounded-lg shadow-lg">
      <div className="flex flex-row items-center">
        <img
          src={
            user.profile_pic
              ? user.profile_pic
              : "https://www.gravatar.com/avatar/?d=mp&s=200"
          }
          alt="user"
          className="object-cover w-12 h-12 rounded-full"
        />
        <div className="flex flex-col ml-4">
          <div className="font-semibold">{user.name}</div>
          <div className="text-xs text-gray-500">{user.title}</div>
        </div>
      </div>
      <div className="flex flex-col mt-4 line-clamp-3">{user.bio}</div>
    </div>
  );
}
