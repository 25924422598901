import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import AWS from "aws-sdk";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TagsInput from "react-tagsinput";
import plus from "../../assets/icons/PLUS.svg";
import xmark from "../../assets/icons/XMARK.svg";
import BaseComponent from "../BaseComponent";
import { LocationValleys } from "../utils/LocationValleys";

export default function Articles() {
  const animatedComponents = makeAnimated();
  const [articles, setArticles] = useState(null);
  const cityList = LocationValleys.flatMap((valley) => valley.name);
  const [loading, setLoading] = useState(true);
  const [createNew, setCreateNew] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [images, setImages] = useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState([
    { id: "title", error: null },
    { id: "subtitle", error: null },
    { id: "body", error: null },
    { id: "tags", error: null },
    { id: "images", error: null },
    { id: "city", error: null },
  ]);

  const getArticles = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/articles`)
      .then((res) => {
        console.log(res.data);
        setArticles(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [...prev, ...tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [...prev, ...tempImages];
          });
        }
      }
    });
  };

  const createArticle = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const body = document.getElementById("body").value;
    const currentErrors = [
      { id: "title", error: null },
      { id: "subtitle", error: null },
      { id: "body", error: null },
      { id: "tags", error: null },
      { id: "images", error: null },
      { id: "city", error: null },
    ];

    if (title === "") {
      currentErrors[0].error = "Il titolo non può essere vuoto";
    }
    if (subtitle === "") {
      currentErrors[1].error = "Il sottotitolo non può essere vuoto";
    }
    if (body === "") {
      currentErrors[2].error = "Il corpo dell'articolo non può essere vuoto";
    }
    if (tags.length === 0) {
      currentErrors[3].error = "Devi inserire almeno un tag";
    }
    if (uploadedImages.length === 0) {
      currentErrors[4].error = "Devi inserire almeno un'immagine";
    }
    if (selectedCity === "" || selectedCity === null) {
      currentErrors.find((e) => e.id === "city").error = "Inserisci la città";
    }

    setErrors(currentErrors);

    if (currentErrors.some((e) => e.error !== null)) return;

    const article = {
      title,
      sub_title: subtitle,
      body,
      tags,
      images: uploadedImages,
      user_id: id,
      city: selectedCity,
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/article`, article, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        getArticles();
        setCreateNew(false);
        setUploadedImages([]);
        setImages([]);
        setTags([]);
        setSelectedCity(null);
        setSelectedArticle(null);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const updateArticle = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const body = document.getElementById("body").value;
    const currentErrors = [
      { id: "title", error: null },
      { id: "subtitle", error: null },
      { id: "body", error: null },
      { id: "tags", error: null },
      { id: "images", error: null },
      { id: "city", error: null },
    ];

    if (title === "") {
      currentErrors[0].error = "Il titolo non può essere vuoto";
    }
    if (subtitle === "") {
      currentErrors[1].error = "Il sottotitolo non può essere vuoto";
    }
    if (body === "") {
      currentErrors[2].error = "Il corpo dell'articolo non può essere vuoto";
    }
    if (tags.length === 0) {
      currentErrors[3].error = "Devi inserire almeno un tag";
    }
    if (uploadedImages.length === 0) {
      currentErrors[4].error = "Devi inserire almeno un'immagine";
    }
    if (selectedCity === "" || selectedCity === null) {
      currentErrors.find((e) => e.id === "city").error = "Inserisci la città";
    }

    setErrors(currentErrors, selectedCity);

    console.log(currentErrors);

    if (currentErrors.some((e) => e.error !== null)) return;

    const article = {
      title,
      sub_title: subtitle,
      body,
      tags,
      images: uploadedImages,
      user_id: id,
      city: selectedCity,
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/article/edit/${selectedArticle._id}`,
        article,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        getArticles();
        setCreateNew(false);
        setUploadedImages([]);
        setImages([]);
        setTags([]);
        setSelectedArticle(null);
        setSelectedCity(null);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  return (
    <>
      <BaseComponent
        title={
          createNew && selectedArticle
            ? "Modifica articolo"
            : createNew
            ? "Nuovo articolo"
            : "Articoli"
        }
        sidebar={
          createNew ? null : (
            <button
              onClick={() => setCreateNew(true)}
              className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
            >
              Nuovo articolo
            </button>
          )
        }
      >
        {createNew ? (
          <div className="flex flex-col px-10 py-4 my-4 mb-20 overflow-y-auto bg-gray-100 rounded-md gap-y-4">
            <div className="grid w-full grid-cols-5 gap-x-4">
              <p className="mt-2 font-semibold">Titolo</p>
              <div className="relative flex flex-col w-full col-span-4 gap-y-4">
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="title"
                  defaultValue={selectedArticle?.title}
                  placeholder={`titolo articolo`}
                  //   onChange={() => updateExperience()}
                ></input>
                {
                  <p className="-mt-2 text-sm text-red-400">
                    {errors.find((e) => e.id === "title")?.error}
                  </p>
                }
              </div>
            </div>
            <div className="flex flex-col w-full -mt-2 gap-y-2">
              <p className="mb-2 font-semibold">Città</p>
              <div className="relative flex flex-col w-fullì gap-y-2">
                <Select
                  isSearchable
                  isMulti={false}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  id="city"
                  options={cityList.map((c) => ({
                    value: c,
                    label: c,
                  }))}
                  defaultValue={
                    selectedArticle
                      ? {
                          value: selectedArticle.city,
                          label: selectedArticle.city,
                        }
                      : null
                  }
                  onChange={(value) => {
                    console.log(value.value);
                    setSelectedCity(value.value);
                  }}
                />
                <p className="-mt-1 text-sm text-red-400">
                  {errors.find((e) => e.id === "city")?.error}
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-y-4 ">
              <p className="w-full font-semibold">Tags</p>
              <div className="w-full">
                <TagsInput
                  value={tags}
                  onChange={(tags) => {
                    setTags(tags);
                  }}
                  inputProps={{
                    placeholder: "Aggiungi un tag",
                  }}
                  id="tags"
                />
              </div>
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "tags")?.error}
              </p>
            </div>
            <p className="font-semibold">Sottotitolo</p>
            <div className="flex flex-col gap-y-1">
              <textarea
                className=" p-6 border-2 border-gray-200 resize-none rounded-[40px] "
                placeholder="Aggiungi un sottotitolo"
                rows={2}
                id="subtitle"
                defaultValue={selectedArticle?.sub_title}
                // onChange={() => updateExperience()}
              ></textarea>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "subtitle")?.error}
              </p>
            </div>
            <p className="font-semibold">Immagini</p>
            <div className="h-72">
              <div className="h-full">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={20}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="relative w-full h-full upload__image-wrapper">
                      <button
                        className="border-4 border-dashed border-gray-400 rounded-[40px] text-gray-700 p-4 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img
                          src={plus}
                          alt="plus"
                          className="w-16 h-16 p-2 mb-4"
                        />
                        Clicca o trascina le tue foto che vuoi aggiungere
                        all'articolo qui
                      </button>
                      {isUploading && (
                        <div className="relative px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4">
                          {uploadedImages.length > 0 &&
                            uploadedImages.map((image, index) => (
                              <div
                                key={index}
                                className="relative min-w-36 w-36 image-item"
                              >
                                <img
                                  src={image}
                                  alt=""
                                  className="object-cover h-24 w-36 min-w-36"
                                />
                                <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                                  <button
                                    onClick={() => {
                                      setUploadedImages(
                                        uploadedImages.filter(
                                          (_, i) => i !== index
                                        )
                                      );
                                    }}
                                    className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                                  >
                                    <img
                                      src={xmark}
                                      alt="plus"
                                      className="w-5 h-5"
                                    />
                                  </button>
                                </div>
                              </div>
                            ))}
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              className="relative flex items-center justify-center h-24 bg-gray-200 rounded-md min-w-36 w-36 image-item"
                            >
                              <UiduSpinner
                                size="large"
                                className="text-gray-500"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {uploadedImages.length > 0 && !isUploading && (
                        <div className="relative px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4">
                          {uploadedImages.map((image, index) => (
                            <div
                              key={index}
                              className="relative min-w-36 w-36 image-item"
                            >
                              <img
                                src={image}
                                alt=""
                                className="object-cover h-24 w-36 min-w-36"
                              />
                              <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                                <button
                                  onClick={() => {
                                    setUploadedImages(
                                      uploadedImages.filter(
                                        (_, i) => i !== index
                                      )
                                    );
                                  }}
                                  className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                                >
                                  <img
                                    src={xmark}
                                    alt="plus"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                          {/* {imageList.map((image, index) => (
                        <div
                          key={index}
                          className="relative min-w-36 w-36 image-item"
                        >
                          <img
                            src={image["data_url"]}
                            alt=""
                            className="object-cover h-24 w-36 min-w-36"
                          />
                          <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                            <button
                              onClick={() => onImageRemove(index)}
                              className="flex items-center justify-center w-5 h-5 bg-white border-2 border-gray-200 rounded-full"
                            >
                              <img src={xmark} alt="plus" className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      ))} */}
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <p className="-mt-2 text-sm text-red-400">
              {errors.find((e) => e.id === "images")?.error}
            </p>
            <p className="font-semibold">Corpo articolo</p>
            <div className="flex flex-col gap-y-1">
              <textarea
                className=" p-6 border-2 border-gray-200 resize-none rounded-[40px] "
                placeholder="Corpo articolo"
                rows={8}
                id="body"
                defaultValue={selectedArticle?.body}
                // onChange={() => updateExperience()}
              ></textarea>
              <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "body")?.error}
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md min-h-[512px] overflow-x-auto overflow-y-auto py-4 px-10 my-4">
            {articles && articles.length > 0 && !loading ? (
              <div className="flow-root h-[512px] ">
                <div className="py-4 -mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                          >
                            Cover
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Tags
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                          >
                            Creato il
                          </th>
                          <th
                            scope="col"
                            className="relative py-3 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 ">
                        {articles.map((article, index) => (
                          <tr key={article._id}>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                              <div className="flex items-center">
                                <div className="flex items-center justify-center w-12 h-8 rounded-full ">
                                  <img
                                    src={article.images[0]}
                                    alt=""
                                    className="object-cover w-12 h-8 rounded-md"
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {article.title}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm text-gray-500">
                                {article.tags.join(", ")}
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm text-gray-500">
                                {dayjs(article.createdAt).format("DD/MM/YYYY")}
                              </div>
                            </td>
                            <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                              <div className="flex items-center justify-end gap-x-6">
                                <button
                                  onClick={() => {
                                    setSelectedArticle(article);
                                    setTags(article.tags);
                                    setUploadedImages(article.images);
                                    setCreateNew(true);
                                  }}
                                  className="text-xl font-semibold text-primary"
                                >
                                  <FontAwesomeIcon icon={faPencilSquare} />
                                </button>
                                <button
                                  onClick={() => {
                                    const token = localStorage.getItem("token");
                                    const id = localStorage.getItem("user");
                                    const data = {
                                      id: article._id,
                                      user_id: id,
                                    };
                                    axios
                                      .post(
                                        `${process.env.REACT_APP_SERVER_URL}/api/articleDelete`,
                                        data,
                                        {
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                          },
                                        }
                                      )
                                      .then((res) => {
                                        getArticles();
                                      })
                                      .catch((err) => {
                                        console.log(err, "err");
                                      });
                                  }}
                                  className="text-lg font-semibold text-red-400"
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <UiduSpinner size="medium" className="text-primary" />
              </div>
            ) : (
              <div className="flex w-full h-full text-gray-400">
                Nessun articolo
              </div>
            )}
          </div>
        )}
      </BaseComponent>
      {createNew && (
        <div className="fixed bottom-0 w-full bg-white border-t max-w-screen-2xl 2xl:mx-auto">
          <div className="w-[71%]">
            <div className="flex justify-end w-full py-2">
              <div className="flex gap-x-4">
                <button
                  onClick={() => {
                    setCreateNew(false);
                    setUploadedImages([]);
                    setImages([]);
                    setTags([]);
                    setSelectedArticle(null);
                  }}
                  className="flex items-center justify-between px-4 py-1 text-white rounded-md bg-primary"
                >
                  Annulla
                </button>
                <button
                  onClick={() => {
                    if (selectedArticle) {
                      updateArticle();
                    } else {
                      createArticle();
                    }
                  }}
                  className="flex items-center justify-between px-4 py-1 text-white rounded-md bg-primary"
                >
                  {selectedArticle ? "Modifica" : "Crea"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
