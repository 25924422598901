export const generalServices = [
  {
    id: 1,
    name: "Wi-Fi",
  },
  {
    id: 2,
    name: "Parcheggio",
  },
  {
    id: 3,
    name: "Piscina",
  },
  {
    id: 4,
    name: "Aria condizionata",
  },
  {
    id: 5,
    name: "Riscaldamento",
  },
  {
    id: 6,
    name: "Animali ammessi",
  },
  {
    id: 7,
    name: "Servizio in camera",
  },
  {
    id: 8,
    name: "Servizio lavanderia",
  },
  {
    id: 9,
    name: "Servizio stireria",
  },
  {
    id: 10,
    name: "Servizio navetta",
  },
  {
    id: 11,
    name: "Servizio baby-sitter",
  },
  {
    id: 12,
    name: "Servizio lavaggio a secco",
  },
  {
    id: 13,
    name: "Area ristorante",
  },
  {
    id: 14,
    name: "Palestra",
  },
  {
    id: 15,
    name: "Spa e centro benessere",
  },
  {
    id: 16,
    name: "Sale riunioni/banchetti",
  },
  {
    id: 17,
    name: "Bar",
  },
  {
    id: 17,
    name: "Servizio di check-in/check-out express",
  },
  {
    id: 19,
    name: "Servizio concierge",
  },
  {
    id: 20,
    name: "Servizio di pulizia giornaliero",
  },
  {
    id: 21,
    name: "Servizio sveglia",
  },
  {
    id: 22,
    name: "Deposito bagagli",
  },
  {
    id: 23,
    name: "Reception 24 ore su 24",
  },
  {
    id: 24,
    name: "Servizio di portineria",
  },
  {
    id: 25,
    name: "Servizio di noleggio auto",
  },
  {
    id: 26,
    name: "Servizio di trasferimento aeroportuale",
  },
];

export const securityServices = [
  {
    id: 1,
    name: "Sorveglianza 24 ore su 24",
  },
  {
    id: 2,
    name: "Cassaforte",
  },
  {
    id: 3,
    name: "Allarme antincendio",
  },
  {
    id: 4,
    name: "Allarme antifurto",
  },
  {
    id: 5,
    name: "Videosorveglianza nelle aree comuni",
  },
  {
    id: 6,
    name: "Accesso con chiave elettronica",
  },
  {
    id: 7,
    name: "Accesso con codice di sicurezza",
  },
  {
    id: 8,
    name: "Guardia di sicurezza",
  },
  {
    id: 9,
    name: "Porte di sicurezza",
  },
  {
    id: 10,
    name: "Estintori",
  },
  {
    id: 11,
    name: "Rilevatori di fumo",
  },
  {
    id: 12,
    name: "Rilevatori di monossido di carbonio",
  },
  {
    id: 13,
    name: "Casseforti nelle camere",
  },
  {
    id: 14,
    name: "Sicurezza alimentare",
  },
  {
    id: 15,
    name: "Primo soccorso disponibile",
  },
  {
    id: 16,
    name: "Sistema di allarme collegato alla reception",
  },
  {
    id: 17,
    name: "Formazione del personale sulla sicurezza",
  },
  {
    id: 18,
    name: "Parcheggio custodito",
  },
  {
    id: 19,
    name: "CCTV nelle aree comuni",
  },
  {
    id: 20,
    name: "Sistema di sicurezza con telecamere nelle aree esterne",
  },
];

export const comfortServices = [
  {
    id: 1,
    name: "Riscaldamento",
  },
  {
    id: 2,
    name: "Aria condizionata",
  },
  {
    id: 3,
    name: "Camino",
  },
  {
    id: 4,
    name: "Vasca idromassaggio",
  },
  {
    id: 5,
    name: "Sauna",
  },
  {
    id: 6,
    name: "Piscina interna",
  },
  {
    id: 7,
    name: "Piscina esterna",
  },
  {
    id: 8,
    name: "Spa",
  },
  {
    id: 9,
    name: "Palestra",
  },
  {
    id: 10,
    name: "Servizio in camera",
  },
  {
    id: 11,
    name: "Mini bar",
  },
  {
    id: 12,
    name: "TV a schermo piatto",
  },
  {
    id: 13,
    name: "WiFi gratuito",
  },
  {
    id: 14,
    name: "Bagno privato",
  },
  {
    id: 15,
    name: "Balcone",
  },
  {
    id: 16,
    name: "Vista panoramica",
  },
  {
    id: 17,
    name: "Terrazza",
  },
  {
    id: 18,
    name: "Giardino",
  },
  {
    id: 19,
    name: "Cucina",
  },
  {
    id: 20,
    name: "Lavatrice",
  },
];

export const hospitalityServices = [
  {
    id: 1,
    name: "Bar",
  },
  {
    id: 2,
    name: "Ristorante",
  },
  {
    id: 3,
    name: "Servizio in camera",
  },
  {
    id: 4,
    name: "Servizio lavanderia",
  },
  {
    id: 5,
    name: "Servizio stireria",
  },
  {
    id: 6,
    name: "Servizio navetta",
  },
  {
    id: 7,
    name: "Servizio baby-sitter",
  },
  {
    id: 8,
    name: "Servizio lavaggio a secco",
  },
  {
    id: 9,
    name: "Servizio concierge",
  },
  {
    id: 10,
    name: "Servizio di parcheggio e riconsegna auto",
  },
  {
    id: 11,
    name: "Servizio di pulizia giornaliero",
  },
  {
    id: 12,
    name: "Servizio di portineria",
  },
  {
    id: 13,
    name: "Servizio di prenotazione biglietti",
  },
  {
    id: 14,
    name: "Servizio di prenotazione tour",
  },
  {
    id: 15,
    name: "Servizio di risveglio",
  },
  {
    id: 16,
    name: "Servizio di trasporto da e per l'aeroporto",
  },
  {
    id: 17,
    name: "Servizio di noleggio auto",
  },
  {
    id: 18,
    name: "Servizio di noleggio biciclette",
  },
  {
    id: 19,
    name: "Servizio di noleggio attrezzature sportive",
  },
  {
    id: 20,
    name: "Servizio di organizzazione eventi",
  },
];
