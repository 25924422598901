import { faker } from "@faker-js/faker";

export function generateFakeData({ kind, amount, category }) {
  const data = [];

  const cities = [
    "Acquafredda",
    "Adro",
    "Agnosine",
    "Alfianello",
    "Anfo ",
    "Angolo Terme ",
    "Artogne",
    "Azzano Mella",
    "Bagnolo Mella",
    "Bagolino",
    "Barbariga",
    "Barghe",
    "Bassano Bresciano",
    "Bedizzole",
    "Berlingo",
    "Berzo Demo ",
    "Berzo Inferiore",
    "Bienno",
    "Bione ",
    "Borgo San Giacomo",
    "Borgosatollo",
    "Borno ",
    "Botticino",
    "Bovegno ",
    "Bovezzo",
    "Brandico",
    "Braone ",
    "Breno",
    "Brescia",
    "Brione ",
    "Caino ",
    "Calcinato",
    "Calvagese della Riviera",
    "Calvisano",
    "Capo di Ponte ",
    "Capovalle ",
    "Capriano del Colle",
    "Capriolo",
    "Carpenedolo",
    "Castegnato",
    "Castelcovati ",
    "Castel Mella",
    "Castenedolo",
    "Casto",
    "Castrezzato",
    "Cazzago San Martino",
    "Cedegolo ",
    "Cellatica",
    "Ceto",
    "Cerveno",
    "Cevo ",
    "Chiari",
    "Cigole ",
    "Cimbergo ",
    "Cividate Camuno",
    "Coccaglio",
    "Collebeato",
    "Collio ",
    "Cologne",
    "Comezzano-Cizzago ",
    "Concesio",
    "Corte Franca",
    "Corteno Golgi ",
    "Corzano",
    "Darfo Boario Terme",
    "Dello",
    "Desenzano del Garda",
    "Edolo ",
    "Erbusco",
    "Esine ",
    "Fiesse",
    "Flero",
    "Gambara",
    "Gardone Riviera",
    "Gardone Val Trompia ",
    "Gargnano ",
    "Gavardo",
    "Ghedi",
    "Gianico ",
    "Gottolengo",
    "Gussago",
    "Idro",
    "Incudine ",
    "Irma ",
    "Iseo",
    "Isorella",
    "Lavenone",
    "Leno",
    "Limone sul Garda ",
    "Lodrino",
    "Lograto",
    "Lonato",
    "Longhena",
    "Losine ",
    "Lozio ",
    "Lumezzane",
    "Maclodio",
    "Magasa ",
    "Mairano",
    "Malegno ",
    "Malonno ",
    "Manerba sul Garda",
    "Manerbio",
    "Marcheno ",
    "Marmentino ",
    "Marone ",
    "Mazzano",
    "Milzano",
    "Moniga del Garda",
    "Monno ",
    "Monte Isola ",
    "Monticelli Brusati",
    "Montichiari",
    "Montirone",
    "Mura",
    "Muscoline",
    "Nave",
    "Niardo",
    "Nuvolento",
    "Nuvolera",
    "Odolo",
    "Offlaga",
    "Ome",
    "Ono San Pietro ",
    "Orzinuovi",
    "Orzivecchi ",
    "Ospitaletto",
    "Ossimo ",
    "Padenghe sul Garda ",
    "Paderno Franciacorta",
    "Paisco Loveno ",
    "Paitone",
    "Palazzolo sull'Oglio",
    "Paratico",
    "Paspardo ",
    "Passirano",
    "Pavone Mella",
    "San Paolo",
    "Pertica Alta ",
    "Pertica Bassa",
    "Pezzaze",
    "Pian Camuno",
    "Piancogno",
    "Pisogne",
    "Polaveno ",
    "Polpenazze del Garda",
    "Pompiano",
    "Poncarale",
    "Ponte di Legno ",
    "Pontevico",
    "Pontoglio",
    "Pozzolengo",
    "Pralboino",
    "Preseglie",
    "Prevalle",
    "Provaglio d'Iseo ",
    "Provaglio Valsabbia ",
    "Puegnago sul Garda",
    "Quinzano d'Oglio",
    "Remedello",
    "Rezzato",
    "Roccafranca ",
    "Rodengo Saiano",
    "Roè Volciano ",
    "Roncadelle",
    "Rovato",
    "Rudiano ",
    "Sabbio Chiese",
    "Sale Marasino ",
    "Salò ",
    "San Felice del Benaco",
    "San Gervasio Bresciano",
    "San Zeno Naviglio",
    "Sarezzo ",
    "Saviore dell'Adamello ",
    "Sellero",
    "Seniga",
    "Serle",
    "Sirmione",
    "Soiano del Lago",
    "Sonico ",
    "Sulzano ",
    "Tavernole sul Mella ",
    "Temù ",
    "Tignale ",
    "Torbole Casaglia",
    "Toscolano Maderno ",
    "Travagliato ",
    "Tremosine ",
    "Trenzano",
    "Treviso Bresciano",
    "Urago d'Oglio ",
    "Vallio",
    "Valvestino ",
    "Verolanuova",
    "Verolavecchia",
    "Vestone",
    "Vezza d'Oglio",
    "Villa Carcina",
    "Villachiara",
    "Villanuova Sul Clisi ",
    "Vione ",
    "Visano ",
    "Vobarno",
    "Zone",
  ];

  const names = [
    "Gita in barca a vela",
    "Degustazione di vini della Franciacorta",
    "Scopri la città di Brescia",
    "Visita al museo di Santa Giulia",
    "Visita al museo di Mille Miglia",
    "Passeggiata per le vie del centro storico",
    "Lezioni di cucina",
    "Impara a fare il pane",
    "Impara a fare la pasta",
    "Impara a fare la pizza",
    "Impara a fare la focaccia",
    "Impara a fare la piadina",
    "Impara a fare la torta",
    "Visita dell'azienda agricola",
    "Visita del caseificio",
    "Visita del frantoio",
    "Visita del mulino",
    "Visita del birrificio",
    "Visita del vigneto",
    "Scopri l'arte della ceramica",
    "Scopri l'arte della tessitura",
    "Scopri l'arte della lavorazione del ferro",
    "Scopri l'arte della lavorazione del legno",
    "Scopri l'arte della lavorazione del vetro",
    "Scopri l'arte della lavorazione della pietra",
    "Giro in mongolfiera",
    "Giro in elicottero",
    "Giro in auto d'epoca",
    "Giro in moto d'epoca",
    "Giro in bicicletta per le vie del centro storico",
    "Giro in bicicletta per le vie del lago",
    "Giro in bicicletta per le vie della Franciacorta",
    "Giro a cavallo",
    "Giro in carrozza",
    "Giro in slitta",
  ];

  const structureNames = [
    "Hotel bella vista ★★★★",
    "Hotel al lago ★★★",
    "Agriturismo Piane",
    "Agriturismo La cascina",
    "Agriturismo La quercia",
    "Agriturismo La rocca",
    "Agriturismo La torre",
    "Agriturismo La casa",
    "Agriturismo La vigna",
    "Agriturismo La collina",
    "Campign La cascina",
    "Camping La quercia",
    "Camping La rocca",
    "Camping La torre",
    "Camping La casa",
    "La casa di Maria",
    "La casa di Giuseppe",
    "La casa Torre",
    "Campign La vigna",
    "Glamour camping La collina",
    "Glamour camping La cascina",
    "Glamour camping La quercia",
    "Glamour camping La rocca",
    "B&B La torre",
    "B&B La casa",
    "B&B La vigna",
    "B&B La collina",
    "B&B La cascina",
    "B&B La quercia",
    "B&B La rocca",
    "hostel La torre",
    "hostel La casa",
    "hostel La vigna",
    "hostel La collina",
    "hostel La cascina",
    "hostel La quercia",
  ];

  const categories = ["wine", "art", "outdoor", "authentic"];
  const structureCategories = [
    "hotel",
    "b&b",
    "hostel",
    "glamping",
    "agriturism",
    "camping",
    "home",
  ];

  const languages = ["italian", "english", "french", "german", "spanish"];

  console.log("kind", amount);

  if (kind === "experience") {
    for (let i = 0; i < amount; i++) {
      data.push({
        id: faker.number.bigInt(),
        name: names[faker.number.int({ min: 0, max: names.length - 1 })],
        city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
        address: faker.location.streetAddress(),
        addressDetails: {
          latitude: faker.location.latitude(),
          longitude: faker.location.longitude(),
        },
        reviews: [
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
        ],
        price: faker.commerce.price(),
        image: faker.image.urlLoremFlickr({
          category: "experience",
          width: 2000,
          height: 1000,
        }),
        detailImages: [
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
        ],
        tags: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
        category: category
          ? category
          : categories[
              faker.number.int({ min: 0, max: categories.length - 1 })
            ],
        description: faker.lorem.paragraphs(10),
        duration: faker.number.int({ min: 1, max: 8 }),
        languages: [
          languages[faker.number.int({ min: 0, max: languages.length - 1 })],
          languages[faker.number.int({ min: 0, max: languages.length - 1 })],
        ],
        cancellationPolicy: faker.number.int({ min: 1, max: 3 }),
        includedStuff: [
          faker.lorem.words(6),
          faker.lorem.words(5),
          faker.lorem.words(4),
        ],
        requirements: [
          faker.lorem.words(6),
          faker.lorem.words(5),
          faker.lorem.words(4),
        ],
        steps: [
          {
            title: faker.lorem.words(3),
            duration: faker.number.int({ min: 1, max: 8 }),
          },
          {
            title: faker.lorem.words(4),
            duration: faker.number.int({ min: 1, max: 8 }),
          },
          {
            title: faker.lorem.words(3),
            duration: faker.number.int({ min: 1, max: 8 }),
          },
          {
            title: faker.lorem.words(4),
            duration: faker.number.int({ min: 1, max: 8 }),
          },
        ],
        user: {
          id: faker.number.bigInt(),
          name: faker.person.firstName(),
          surname: faker.person.lastName(),
          image: faker.image.avatar(),
          bio: faker.person.bio(),
          title: faker.person.jobTitle(),
        },
      });
    }
  } else if (kind === "structure") {
    for (let i = 0; i < amount; i++) {
      data.push({
        id: faker.number.bigInt(),
        name: structureNames[
          faker.number.int({ min: 0, max: names.length - 1 })
        ],
        city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
        description: faker.lorem.paragraphs(10),
        tags: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
        reviews: [
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
        ],
        detailImages: [
          faker.image.urlLoremFlickr({
            category: "room",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "nature",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "hotel",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "room",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "hotel",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
        ],
        languages: [
          languages[faker.number.int({ min: 0, max: languages.length - 1 })],
          languages[faker.number.int({ min: 0, max: languages.length - 1 })],
        ],
        userFavoritesAmenities: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
        rooms: [
          {
            id: faker.number.bigInt(),
            name: faker.lorem.words(),
            doubleBeds: faker.number.int({ min: 0, max: 2 }),
            singleBeds: faker.number.int({ min: 0, max: 2 }),
            surface: faker.number.int({ min: 10, max: 50 }),
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            price: faker.commerce.price(),
            offerBreakfast: faker.datatype.boolean(),
            maxAvailability: faker.number.int({ min: 1, max: 5 }),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
          {
            id: faker.number.bigInt(),
            name: faker.lorem.words(),
            doubleBeds: faker.number.int({ min: 0, max: 2 }),
            singleBeds: faker.number.int({ min: 0, max: 2 }),
            surface: faker.number.int({ min: 10, max: 50 }),
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            price: faker.commerce.price(),
            offerBreakfast: faker.datatype.boolean(),
            maxAvailability: faker.number.int({ min: 1, max: 5 }),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
          {
            id: faker.number.bigInt(),
            name: faker.lorem.words(),
            doubleBeds: faker.number.int({ min: 0, max: 2 }),
            singleBeds: faker.number.int({ min: 0, max: 2 }),
            surface: faker.number.int({ min: 10, max: 50 }),
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            price: faker.commerce.price(),
            offerBreakfast: faker.datatype.boolean(),
            maxAvailability: faker.number.int({ min: 1, max: 5 }),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
        ],
        user: {
          id: faker.number.bigInt(),
          name: faker.person.firstName(),
          surname: faker.person.lastName(),
          image: faker.image.avatar(),
          bio: faker.person.bio(),
          title: faker.person.jobTitle(),
        },
        cancellationPolicy: faker.number.int({ min: 1, max: 3 }),
        address: faker.location.streetAddress(),
        addressDetails: {
          latitude: faker.location.latitude(),
          longitude: faker.location.longitude(),
        },
        price: faker.commerce.price(),
        image: faker.image.urlLoremFlickr({ category: "hotel" }),
        category:
          structureCategories[
            faker.number.int({
              min: 0,
              max: structureCategories.length - 1,
            })
          ],
      });
    }
  } else if (kind === "article") {
    for (let i = 0; i < amount; i++) {
      data.push({
        id: faker.number.bigInt(),
        title: faker.lorem.words(),
        image: faker.image.urlLoremFlickr({ category: "nature" }),
        text: faker.lorem.paragraphs(),
        createdAt: faker.date.past(),
        tags: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
      });
    }
  } else if (kind === "escoShare") {
    for (let i = 0; i < amount; i++) {
      data.push({
        id: faker.number.bigInt(),
        title: faker.lorem.words(),
        image: faker.image.urlLoremFlickr({ category: "nature" }),
        text: faker.lorem.paragraphs(),
        createdAt: faker.date.past(),
        tags: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
        user: {
          id: faker.number.bigInt(),
          name: faker.person.firstName(),
          surname: faker.person.lastName(),
          image: faker.image.avatar(),
        },
        comments: [
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            likes: faker.number.int({ min: 1, max: 25 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            likes: faker.number.int({ min: 1, max: 25 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            likes: faker.number.int({ min: 1, max: 25 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            likes: faker.number.int({ min: 1, max: 25 }),
            createdAt: faker.date.past(),
          },
        ],
        likes: faker.number.int({ min: 1, max: 50 }),
      });
    }
  } else if (kind === "escoBox") {
    for (let i = 0; i < amount; i++) {
      data.push({
        id: faker.number.bigInt(),
        name: names[faker.number.int({ min: 0, max: names.length - 1 })],
        city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
        description: faker.lorem.paragraphs(10),
        tags: [
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
          faker.lorem.word(),
        ],
        reviews: [
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
          {
            id: faker.number.bigInt(),
            user: {
              id: faker.number.bigInt(),
              name: faker.person.firstName(),
              surname: faker.person.lastName(),
              image: faker.image.avatar(),
            },
            text: faker.lorem.paragraphs(2),
            rating: faker.number.int({ min: 1, max: 5 }),
            createdAt: faker.date.past(),
          },
        ],
        structures: [
          {
            id: faker.number.bigInt(),
            name: structureNames[
              faker.number.int({ min: 0, max: names.length - 1 })
            ],
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            reviews: [
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
            ],
            kind: structureCategories[
              faker.number.int({
                min: 0,
                max: structureCategories.length - 1,
              })
            ],
            city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
            price: faker.commerce.price(),
            withFreeCancellation: faker.datatype.boolean(),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "hotel",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "house",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
          {
            id: faker.number.bigInt(),
            name: structureNames[
              faker.number.int({ min: 0, max: names.length - 1 })
            ],
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            reviews: [
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
            ],
            kind: structureCategories[
              faker.number.int({
                min: 0,
                max: structureCategories.length - 1,
              })
            ],
            city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
            price: faker.commerce.price(),
            withFreeCancellation: faker.datatype.boolean(),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "hotel",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "house",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
          {
            id: faker.number.bigInt(),
            name: structureNames[
              faker.number.int({ min: 0, max: names.length - 1 })
            ],
            services: [
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
              faker.lorem.word(),
            ],
            kind: structureCategories[
              faker.number.int({
                min: 0,
                max: structureCategories.length - 1,
              })
            ],
            reviews: [
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
            ],
            city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
            price: faker.commerce.price(),
            withFreeCancellation: faker.datatype.boolean(),
            images: [
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "hotel",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "room",
                  width: 600,
                  height: 400,
                }),
              },
              {
                id: faker.number.bigInt(),
                url: faker.image.urlLoremFlickr({
                  category: "house",
                  width: 600,
                  height: 400,
                }),
              },
            ],
          },
        ],
        detailImages: [
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
          faker.image.urlLoremFlickr({
            category: "experience",
            width: 600,
            height: 400,
          }),
        ],
        user: {
          id: faker.number.bigInt(),
          name: faker.person.firstName(),
          surname: faker.person.lastName(),
          image: faker.image.avatar(),
          bio: faker.person.bio(),
          title: faker.person.jobTitle(),
        },
        experiences: [
          {
            id: faker.number.bigInt(),
            name: names[faker.number.int({ min: 0, max: names.length - 1 })],
            city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
            reviews: [
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
            ],
            addressDetails: {
              latitude: faker.location.latitude(),
              longitude: faker.location.longitude(),
            },
            image: faker.image.urlLoremFlickr({
              category: "experience",
              width: 2000,
              height: 1000,
            }),
            description: faker.lorem.paragraphs(10),
            category:
              categories[
                faker.number.int({ min: 0, max: categories.length - 1 })
              ],
          },
          {
            id: faker.number.bigInt(),
            name: names[faker.number.int({ min: 0, max: names.length - 1 })],
            city: cities[faker.number.int({ min: 0, max: cities.length - 1 })],
            reviews: [
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
              {
                id: faker.number.bigInt(),
                user: {
                  id: faker.number.bigInt(),
                  name: faker.person.firstName(),
                  surname: faker.person.lastName(),
                  image: faker.image.avatar(),
                },
                text: faker.lorem.paragraphs(2),
                rating: faker.number.int({ min: 1, max: 5 }),
                createdAt: faker.date.past(),
              },
            ],
            description: faker.lorem.paragraphs(10),
            image: faker.image.urlLoremFlickr({
              category: "experience",
              width: 2000,
              height: 1000,
            }),
            category:
              categories[
                faker.number.int({ min: 0, max: categories.length - 1 })
              ],
          },
        ],
        address: faker.location.streetAddress(),
        price: faker.commerce.price(),
        image: faker.image.urlLoremFlickr({ category: "adventure" }),
        category: category
          ? category
          : categories[
              faker.number.int({
                min: 0,
                max: categories.length - 1,
              })
            ],
      });
    }
  }

  return data;
}
