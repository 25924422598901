import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function MaxPartecipants() {
  const { id } = useParams();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [isRecurrent, setIsRecurrent] = useState(experience.is_recurrent);
  const [hasMaxPrivate, setHasMaxPrivate] = useState(
    experience.max_private_group_size !== -1 &&
      experience.max_private_group_size
  );

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    setErrors([]);

    if (
      document.getElementById("maxPublic") &&
      (document.getElementById("maxPublic").value < 0 ||
        document.getElementById("maxPublic").value === "")
    ) {
      console.log("error");
      setErrors([
        {
          error: "Il numero massimo di partecipanti dev'essere definito",
        },
      ]);
      return;
    }

    const data = {
      max_group_size:
        document.getElementById("maxPublic") &&
        document.getElementById("maxPublic").value
          ? document.getElementById("maxPublic").value
          : -1,
      max_private_group_size:
        hasMaxPrivate &&
        document.getElementById("maxPrivate") &&
        document.getElementById("maxPrivate").value
          ? document.getElementById("maxPrivate").value
          : -1,
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante la modifica dell'esperienza");
      });
  };

  useEffect(() => {
    updateExperience();
  }, []);

  useEffect(() => {
    if (!hasMaxPrivate && isRecurrent) {
      updateExperience();
    }
  }, [hasMaxPrivate]);

  return (
    <BaseComponent title="Posti disponibili">
      <div className="flex flex-col px-4 md:px-10 min-h-[400px]  py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-4">
        {experience ? (
          <>
            {isRecurrent ? (
              <>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Numero massimo partecipanti</p>
                  <Tooltip
                    content="Il numero massimo di persone che possono partecipare all'esperienza"
                    position="left"
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="cursor-pointer text-primary"
                    />
                  </Tooltip>
                </div>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="10 persone"
                  id="maxPublic"
                  min={0}
                  defaultValue={
                    experience?.max_group_size === -1
                      ? ""
                      : experience?.max_group_size
                  }
                  onChange={() => updateExperience()}
                />
                <label
                  htmlFor="hasMaxPrivate"
                  className="flex items-center gap-x-3"
                >
                  <input
                    type="checkbox"
                    id="hasMaxPrivate"
                    name="hasMaxPrivate"
                    className="hidden"
                    defaultChecked={false}
                  />
                  <div
                    className={`w-5 min-w-5 h-5 border rounded-md shadow-sm text-white flex items-center justify-center ${
                      hasMaxPrivate
                        ? "bg-primary border-primary"
                        : "bg-white border-gray-300"
                    }`}
                    onClick={() => {
                      setHasMaxPrivate(!hasMaxPrivate);
                    }}
                  >
                    {hasMaxPrivate && (
                      <FontAwesomeIcon className="h-3" icon={faCheck} />
                    )}
                  </div>
                  L'esperienza può essere prenotata da gruppi privati
                </label>
                {hasMaxPrivate && (
                  <>
                    <p className="font-semibold">
                      Gruppi privati (dimensione massima)
                    </p>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                      type="number"
                      placeholder="5 persone"
                      id="maxPrivate"
                      min={0}
                      defaultValue={
                        experience?.max_private_group_size === -1
                          ? ""
                          : experience?.max_private_group_size
                      }
                      onChange={() => updateExperience()}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <p className="font-semibold">Numero massimo partecipanti</p>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="number"
                  placeholder="100 persone"
                  id="maxPublic"
                  min={0}
                  defaultValue={
                    experience?.max_group_size === -1
                      ? ""
                      : experience?.max_group_size
                  }
                  onChange={() => updateExperience()}
                />
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
