import { faker } from "@faker-js/faker";
import {
  faEllipsisH,
  faExclamationTriangle,
  faEye,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import avanti from "../../assets/icons/AVANTI.svg";
import elimina from "../../assets/icons/ELIMINA.svg";
import geotag from "../../assets/icons/GEOTAG.svg";
import modifica from "../../assets/icons/MODIFICA.svg";
import reviews from "../../assets/icons/REVIEW.svg";
import stella from "../../assets/icons/STELLA.svg";
import BaseComponent from "../BaseComponent";
import ExperienceIcon from "../ExperienceIcon";
import PopUp from "../PopUp";

function SingleExperience({ experience, setExperiences }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const detailImages = useMemo(() => {
    const images = [
      faker.image.urlLoremFlickr({
        category: "nature",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "montain",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "lake",
        width: 600,
        height: 400,
      }),
    ];
    return images;
  }, []);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const deleteExperience = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${experience._id}`,
        {
          is_suspended: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log("response", res); // 'response', 'ok
        setExperiences((prev) =>
          prev.filter((item) => item._id !== experience._id)
        );
        setOpenDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative flex flex-col w-full border rounded-lg shadow-lg md:flex-row md:h-72">
      <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 h-72">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          loop={true}
        >
          {experience.images.length > 0
            ? experience.images.map((image) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt="room"
                    className="object-cover w-full rounded-lg h-72"
                  />
                </SwiperSlide>
              ))
            : detailImages.map((image) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt="room"
                    className="object-cover w-full rounded-lg h-72"
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div className="flex flex-col w-full p-4 text-sm md:ml-4 md:w-7/12 gap-y-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center w-full gap-x-4">
            <div className="flex items-center justify-center w-6 h-6 rounded-full ">
              <ExperienceIcon kind={experience.category} color="black" />
            </div>
            <div className="font-bold">{experience.name}</div>
          </div>
          <PopUp
            icon={
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-xs text-white"
              />
            }
            items={
              <>
                <Link
                  className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                  to={`/propose/${experience._id}`}
                >
                  <img src={modifica} alt="modifica" className="w-6 h-6 pr-2" />
                  <div>Modifica</div>
                </Link>
                <div className="mx-4 border-b" />
                <div className="flex items-center w-full h-8 px-6 py-5 text-sm cursor-pointer text-primary hover:bg-gray-50">
                  <FontAwesomeIcon icon={faEye} className="pr-2 text-primary" />
                  <div>Rendi visibile</div>
                </div>
                <div className="mx-4 border-b" />
                <button
                  onClick={() => setOpenDelete(true)}
                  type="button"
                  className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                >
                  <img src={elimina} alt="elimina" className="w-[22px] pr-2" />
                  <div>Elimina</div>
                </button>
              </>
            }
          />
        </div>
        <div className="flex items-center w-full mt-2 gap-x-4">
          <div className="flex items-center mr-12 gap-x-2">
            <img src={geotag} alt="geotag" className="w-4" />
            <div className="text-center">
              {experience.address
                ? experience.address.municipality
                  ? experience.address.municipality
                  : experience.address.village
                : "Pertica Bassa"}
            </div>
          </div>
          {experience.approved_at && (
            <div className="flex items-center gap-x-1.5">
              <img src={stella} alt="stella" className="w-4" />
              <div>
                {experience.reviews ? averageReviews(experience.reviews) : "0"}
                /5
              </div>
            </div>
          )}
        </div>
        <div
          className="grid h-full mt-4 gap-y-2"
          style={
            experience.approved_at
              ? { gridTemplateRows: "repeat(5, 1fr)" }
              : windowWidth >= 768
              ? { gridTemplateRows: "repeat(4, 1fr)" }
              : { gridTemplateRows: "repeat(1, 1fr)" }
          }
        >
          {experience.approved_at ? (
            <>
              <div className="flex items-center gap-x-4">
                <div className="font-semibold">Prenotazioni:</div>
                <div className="text-primary">0</div>
              </div>
              <div className="flex items-center gap-x-4">
                <div className="font-semibold">Recensioni:</div>
                <div className="text-primary">
                  {experience.reviews ? experience.reviews.length : "0"}
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <div className="font-semibold">Ultima prenotazione:</div>
                <div className="text-primary">Mai</div>
              </div>
              <div />
            </>
          ) : experience.request_of_approval_made_at ? (
            <div className="flex flex-col justify-center gap-x-4">
              <div className="text-lg font-semibold">
                In attesa di approvazione
              </div>
              <div className="text-sm text-gray-400">
                La tua esperienza è in fase di revisione da parte del nostro
                team, riceverai una mail di conferma non appena sarà approvata.
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center gap-x-4">
                <div className="text-lg font-semibold">
                  Completa il caricamento
                </div>
                <div className="text-sm text-gray-400">
                  Ci risulta che tu abbia iniziato a caricare la tua esperienza,
                  ma delle informazioni sono ancora mancanti. Aggiungi le
                  informazioni mancanti per rendere la tua esperienza visibile.
                </div>
              </div>
              <Link
                to={`/propose/${experience._id}`}
                className="flex items-center justify-between w-full h-8 px-6 py-5 text-sm rounded-lg cursor-pointer text-primary hover:bg-gray-50"
              >
                <div>Completa caricamento</div>
                <img src={avanti} alt="avanti" className="w-6 h-6 pl-2" />
              </Link>
            </>
          )}
          {experience.approved_at && (
            <div className="grid w-full grid-cols-2 divide-x gap-x-4">
              <div className="flex items-center justify-center cursor-pointer gap-x-4">
                <img src={reviews} alt="reviews" className="w-5 h-5" />
                <div className="text-primary">Recensioni</div>
              </div>
              <div
                className="flex items-center justify-center cursor-pointer gap-x-4"
                onClick={() => navigate(`/experiences/${experience._id}`)}
              >
                {/* <img src={escobox} alt="reviews" className="w-5 h-5" /> */}
                <FontAwesomeIcon icon={faGlobe} className="text-primary" />
                <div className="text-primary">Vedi sul sito</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalTransition>
        {openDelete && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
            <Modal
              onClose={() => setOpenDelete(false)}
              className="mt-0 md:mt-20"
              shouldCloseOnOverlayClick={false}
            >
              <ModalHeader>
                <div className="flex items-center gap-x-4">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="w-6 h-6 text-yellow-500"
                  />
                  <h3 className="text-2xl">Attenzione</h3>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDelete(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </ModalHeader>
              <ModalBody>
                <div className="text-gray-400 ">
                  Sei sicuro di voler eliminare l'esperienza {experience.name}?
                  <br />
                  Questa azione è irreversibile.
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
                  <button
                    className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                    onClick={() => setOpenDelete(false)}
                  >
                    Annulla
                  </button>
                  <button
                    className="w-full h-10 text-sm text-white bg-red-500 rounded-lg md:w-28"
                    onClick={() => deleteExperience()}
                  >
                    Elimina
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </ModalTransition>
    </div>
  );
}

export default function ManageExperiences({ experiences, setExperiences }) {
  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tue esperienze
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {experiences.map((experience) => (
          <SingleExperience
            key={experience.id}
            experience={experience}
            setExperiences={setExperiences}
          />
        ))}
      </div>
    </BaseComponent>
  );
}
