import { motion } from "framer-motion";
import React from "react";
import art from "../assets/art.svg";
import artOrange from "../assets/art_orange.svg";
import authentic from "../assets/auth.svg";
import authenticColored from "../assets/authentic_colored.svg";
import outdoor from "../assets/out.svg";
import outdoorColored from "../assets/outdoor_colored.svg";
import wine from "../assets/wine.svg";
import wineColored from "../assets/wine_colored.svg";

export default function SearchBarMobile({ setSelectedTab, selectedTab }) {
  return (
    <div className="relative z-20 pb-4 mx-8 max-w-screen-2xl 2xl:mx-auto">
      <div className="absolute grid items-center justify-center w-full grid-cols-4 rounded-full -mt-7 gap-x-3">
        <div className="relative">
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none "
            onClick={() => setSelectedTab("wine")}
            style={{
              border: selectedTab === "wine" ? "" : "2px solid #612430",
              transform: selectedTab === "wine" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "wine" ? (
              <img src={wine} alt="wine" className="relative z-20 w-6 h-6" />
            ) : (
              <img
                src={wineColored}
                alt="wine"
                className="relative z-20 w-5 h-5"
              />
            )}

            {selectedTab === "wine" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-wine"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "wine" ? "600" : "400",
            }}
          >
            food & wine
          </div>
        </div>
        <div className="relative">
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none "
            onClick={() => setSelectedTab("art")}
            style={{
              border: selectedTab === "art" ? "" : "2px solid #C8531C",
              transform: selectedTab === "art" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "art" ? (
              <img src={art} alt="art" className="relative z-20 w-6 h-6" />
            ) : (
              <img
                src={artOrange}
                alt="artOrange"
                className="relative z-20 w-5 h-5"
              />
            )}
            {selectedTab === "art" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-art "
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "art" ? "600" : "400",
            }}
          >
            art & culture
          </div>
        </div>
        <div className="relative">
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none "
            onClick={() => setSelectedTab("outdoor")}
            style={{
              border: selectedTab === "outdoor" ? "" : "2px solid #4C8AFB",
              transform: selectedTab === "outdoor" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "outdoor" ? (
              <img
                src={outdoor}
                alt="outdoor"
                className="relative z-20 w-6 h-6"
              />
            ) : (
              <img
                src={outdoorColored}
                alt="outdoor"
                className="relative z-20 w-5 h-5"
              />
            )}
            {selectedTab === "outdoor" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-outdoor "
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "outdoor" ? "600" : "400",
            }}
          >
            outdoor
          </div>
        </div>
        <div className="relative">
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none "
            onClick={() => setSelectedTab("authentic")}
            style={{
              border: selectedTab === "authentic" ? "" : "2px solid #CA9526",
              transform: selectedTab === "authentic" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "authentic" ? (
              <img
                src={authentic}
                alt="authentic"
                className="relative z-20 w-6 h-6"
              />
            ) : (
              <img
                src={authenticColored}
                alt="authentic"
                className="relative z-20 w-5 h-5"
              />
            )}

            {selectedTab === "authentic" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-authentic "
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "authentic" ? "600" : "400",
            }}
          >
            authentic
          </div>
        </div>
      </div>
    </div>
  );
}
