import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

function SingleMeal({
  meal,
  index,
  handleOptionClick,
  updateStructure,
  schema,
  setSchema,
}) {
  const { structure } = useContext(ProposeStructureContext);
  const [hasCurrentMeal, setHasCurrentMeal] = useState(
    structure?.meals.find((m) => m.kind === meal.id)
  );

  const resetCurrentMeal = () => {
    const updatedSchema = [...schema];
    updatedSchema[index].options = updatedSchema[index].options.map(
      (option) => {
        return {
          ...option,
          selected: false,
        };
      }
    );
    setSchema(updatedSchema);
    updateStructure();
  };

  return (
    <div
      key={index}
      className="px-4 py-2 mt-6 border border-primary rounded-[20px]"
    >
      <label
        htmlFor="hasCurrentMeal"
        className="flex items-center justify-between text-base font-semibold "
      >
        {meal.name}
        <input
          type="checkbox"
          id="hasCurrentMeal"
          name="hasCurrentMeal"
          className="hidden"
          defaultChecked={false}
        />
        <div
          className={`bg-white border-gray-300 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
          onClick={() => {
            if (hasCurrentMeal) {
              document.getElementById(`${meal.id}Price`).value = "";
              resetCurrentMeal();
            }
            setHasCurrentMeal(!hasCurrentMeal);
          }}
        >
          {hasCurrentMeal && <FontAwesomeIcon className="h-3" icon={faCheck} />}
        </div>
      </label>
      {hasCurrentMeal && (
        <>
          <div className="flex items-center pb-4 mt-4 gap-x-2">
            <p className="font-semibold">Prezzo</p>
            <CurrencyInput
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
              placeholder="€20"
              id={`${meal.id}Price`}
              min={0}
              prefix="€"
              decimalsLimit={2}
              decimalScale={2}
              allowNegativeValue={false}
              defaultValue={
                structure?.meals.find((m) => m.kind === meal.id)
                  ? structure?.meals.find((m) => m.kind === meal.id).price / 100
                  : ""
              }
              onValueChange={() => updateStructure()}
            />
          </div>
          <p className="pb-2 font-semibold">Opzioni disponibili</p>
          <div className="grid grid-cols-1 text-black md:grid-cols-2 gap-y-4 gap-x-4">
            {meal.options.map((option, i) => (
              <label key={i} className="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  id={option.value}
                  name={option.value}
                  className="hidden"
                  checked={option.selected}
                />
                <div
                  className={`w-5 h-5 border rounded-md shadow-sm text-white flex items-center justify-center ${
                    option.selected
                      ? "bg-primary border-primary"
                      : "bg-white border-gray-300"
                  }`}
                  onClick={() => {
                    handleOptionClick(index, i);
                  }}
                >
                  {option.selected && (
                    <FontAwesomeIcon className="h-3" icon={faCheck} />
                  )}
                </div>
                {option.name}
              </label>
            ))}
          </div>
        </>
      )}

      {/* {error.find((err) => err.id === meal.id).error && (
                    <p className="mt-2 text-sm text-gray-400">
                      {error.find((err) => err.id === meal.id).error}
                    </p>
                  )} */}
    </div>
  );
}

export default function Meals() {
  const { id } = useParams();

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [schema, setSchema] = useState([
    {
      name: "Colazione",
      id: "breakfast",
      options: [
        {
          name: "vegana",
          value: "vegan",
          selected: false,
        },
        {
          name: "senza lattosio",
          value: "lactoseFree",
          selected: false,
        },
        {
          name: "con prodotti locali",
          value: "local",
          selected: false,
        },
      ],
      selected: false,
    },
    {
      name: "Pranzo",
      id: "lunch",
      options: [
        {
          name: "vegana",
          value: "vegan",
          selected: false,
        },
        {
          name: "senza lattosio",
          value: "lactoseFree",
          selected: false,
        },
        {
          name: "con prodotti locali",
          value: "local",
          selected: false,
        },
      ],
      selected: false,
    },
    {
      name: "Cena",
      id: "dinner",
      options: [
        {
          name: "vegana",
          value: "vegan",
          selected: false,
        },
        {
          name: "senza lattosio",
          value: "lactoseFree",
          selected: false,
        },
        {
          name: "con prodotti locali",
          value: "local",
          selected: false,
        },
      ],
      selected: false,
    },
  ]);

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "breakfast", error: null },
      { id: "lunch", error: null },
      { id: "dinner", error: null },
    ];

    // there i an error if an option is selected but the price is not set
    schema.forEach((meal) => {
      if (
        meal.options.some((option) => option.selected) &&
        document.getElementById(`${meal.id}Price`) &&
        document.getElementById(`${meal.id}Price`).value === ""
      ) {
        currentErrors.find((err) => err.id === meal.id).error =
          "Inserisci il prezzo";
      }
      if (
        document.getElementById(`${meal.id}Price`) &&
        document.getElementById(`${meal.id}Price`).value !== "" &&
        !meal.options.some((option) => option.selected)
      ) {
        currentErrors.find(
          (err) => err.id === meal.id
        ).error = `Seleziona almeno un'opzione disponibile per ${meal.name}`;
      }
    });

    setErrors(currentErrors);
    if (currentErrors.some((err) => err.error)) return;

    const meals = schema.map((meal) => {
      if (meal.options.some((option) => option.selected)) {
        return {
          kind: meal.id,
          mealsOptions: meal.options
            .filter((option) => option.selected)
            .map((option) => option.value),
          price:
            parseFloat(
              +document
                .getElementById(`${meal.id}Price`)
                .value.substring(1)
                .replace(",", ".")
            ) * 100,
        };
      }
      return null;
    });

    const data = {
      meals: meals.filter((meal) => meal !== null),
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    if (structure) {
      structure.meals.forEach((meal) => {
        // for each meal kind (breakfast, lunch, dinner) we have to check the mealsOptions array and set as selected the options that are present in the array
        const updatedSchema = [...schema];
        updatedSchema.forEach((mealKind) => {
          if (mealKind.id === meal.kind) {
            mealKind.options.forEach((option) => {
              if (meal.mealsOptions.includes(option.value)) {
                option.selected = true;
              }
            });
          }
        });
        setSchema(updatedSchema);
      });
      updateStructure();
    }
  }, []);

  const handleOptionClick = (mealIndex, optionIndex) => {
    const updatedSchema = [...schema];
    updatedSchema[mealIndex].options = updatedSchema[mealIndex].options.map(
      (option, i) => {
        if (i === optionIndex) {
          return {
            ...option,
            selected: !option.selected,
          };
        }
        return option;
      }
    );
    setSchema(updatedSchema);
    updateStructure();
  };

  return (
    <BaseComponent title="La struttura offre">
      <div className="bg-gray-100 flex flex-col gap-y-6 rounded-md min-h-[400px] overflow-y-auto py-4 px-4 md:px-10 my-4">
        {/* <div className="flex -mb-4 gap-x-4">
          <FontAwesomeIcon
            className="h-4 mt-1 text-primary"
            icon={faCircleInfo}
          />
          <p className="font-thin">
            Seleziona le opzioni che la tua struttura offre, se non ne offre
            nessuna, lascia tutto deselezionato
          </p>
        </div> */}
        {structure ? (
          <>
            <div className="flex flex-col gap-y-4">
              <p className="-mb-6 font-semibold">
                Seleziona tutte le opzioni che la tua struttura offre
              </p>
              {schema.map((meal, index) => (
                <SingleMeal
                  meal={meal}
                  index={index}
                  handleOptionClick={handleOptionClick}
                  updateStructure={updateStructure}
                  schema={schema}
                  setSchema={setSchema}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
