import React from "react";
import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";

export default function Header() {
  return (
    <div className="mx-0 2xl:mx-8">
      <div className="hidden mx-8 lg:block max-w-screen-2xl 2xl:mx-auto">
        <Navbar />
      </div>
      <div className="lg:hidden">
        <MobileNavbar />
      </div>
    </div>
  );
}
