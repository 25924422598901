import { faBed, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FavouriteContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import StructureIcon from "./StructureIcon";

export default function StructureCard({
  structure,
  box,
  setOpenStructureModal,
  setStructureModal,
}) {
  const generalServices = [
    {
      id: 1,
      name: "Wi-Fi",
    },
    {
      id: 2,
      name: "Parcheggio",
    },
    {
      id: 3,
      name: "Piscina",
    },
    {
      id: 4,
      name: "Aria condizionata",
    },
    {
      id: 5,
      name: "Riscaldamento",
    },
    {
      id: 6,
      name: "Animali ammessi",
    },
    {
      id: 7,
      name: "Servizio in camera",
    },
    {
      id: 8,
      name: "Servizio lavanderia",
    },
    {
      id: 9,
      name: "Servizio stireria",
    },
    {
      id: 10,
      name: "Servizio navetta",
    },
    {
      id: 11,
      name: "Servizio baby-sitter",
    },
    {
      id: 12,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 13,
      name: "Servizio stireria",
    },
    {
      id: 14,
      name: "Servizio navetta",
    },
    {
      id: 15,
      name: "Servizio baby-sitter",
    },
    {
      id: 16,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 17,
      name: "Servizio stireria",
    },
    {
      id: 18,
      name: "Servizio navetta",
    },
    {
      id: 19,
      name: "Servizio baby-sitter",
    },
    {
      id: 20,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 21,
      name: "Servizio stireria",
    },
    {
      id: 22,
      name: "Servizio navetta",
    },
    {
      id: 23,
      name: "Servizio baby-sitter",
    },
    {
      id: 24,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 25,
      name: "Servizio stireria",
    },
    {
      id: 26,
      name: "Servizio navetta",
    },
  ];
  const navigate = useNavigate();
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `StructureCard${structure._id}`,
    });

  const roomIncluded = box.structures.find(
    (s) => s.structure_id === structure._id
  ).room_id;

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].structures.find(
          (structureFav) => structureFav.structure_id === structure._id
        )
      ) {
        const newStructures = favourite[0].structures.filter(
          (structureFav) => structureFav.structure_id !== structure._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: newStructures,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura rimossa dai preferiti con successo");
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].structures
                  : []),
                { structure_id: structure._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura aggiunta ai preferiti con successo");
          });
      }
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full border rounded-lg shadow-lg md:flex-row md:h-72">
        <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 md:h-72">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            loop={true}
          >
            {structure.images.map((image) => (
              <SwiperSlide key={image}>
                <img
                  src={image}
                  alt="structure"
                  className="object-cover w-full rounded-lg cursor-pointer h-72"
                  onClick={() => {
                    setOpenStructureModal(true);
                    setStructureModal(structure);
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          className="flex flex-col w-full p-4 cursor-pointer md:ml-4 md:w-7/12 gap-y-2"
          onClick={() => {
            setOpenStructureModal(true);
            setStructureModal(structure);
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center w-full gap-x-4">
              <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                <StructureIcon kind={structure.kind} color={"black"} />
              </div>
              <div className="font-bold">{structure.name}</div>
            </div>
            <button
              className="flex items-center justify-center bg-white rounded-full w-9 h-9"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addToFavourites();
              }}
            >
              {favourite &&
              favourite.length > 0 &&
              favourite[0].structures.find(
                (structureFav) => structureFav.structure_id === structure._id
              ) ? (
                <img src={heartFull} alt="heartFull" className="w-5" />
              ) : (
                <img src={heart} alt="heart" className="w-5" />
              )}
            </button>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="flex items-center gap-x-2">
              <img src={geotag} alt="geotag" className="w-4" />
              <div className="text-center">
                {structure.address
                  ? structure.address?.municipality
                  : "Pertica Bassa"}
              </div>
            </div>
            <div className="flex items-center gap-x-1.5">
              <img src={stella} alt="stella" className="w-4" />
              <div>
                {structure.reviews ? averageReviews(structure.reviews) : 0}/5
              </div>
            </div>
          </div>
          <div className="flex flex-col text-sm gap-y-2">
            <div className="font-semibold">Servizi:</div>
            <div className="flex flex-wrap gap-2">
              {structure.services
                .filter((service) => service.kind === "general")[0]
                .services.slice(0, 4)
                .map((service) => (
                  <div
                    key={service}
                    className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                  >
                    {generalServices.find((s) => s.id === service).name}
                  </div>
                ))}
            </div>
          </div>
          <div className="flex flex-col text-sm gap-y-2">
            <div className="font-semibold">Stanza inclusa nel box:</div>
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon icon={faBed} />
              <div>
                {structure.rooms.find((room) => room._id === roomIncluded).name}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        containerId={`StructureCard${structure._id}`}
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Bounce}
        stacked
      />
    </>
  );
}
