import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    document.title = "Esco | Reset Password";
    document
      .getElementById("resetPasswordPage")
      .scrollIntoView({ behavior: "smooth" }, { block: "start" });
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    if (password !== confirmPassword) {
      setError("Le password non corrispondono");
      setLoading(false);
      return;
    }
    if (password.length < 8) {
      setError("La password deve essere di almeno 8 caratteri");
      setLoading(false);
      return;
    }
    setError(null);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/resetPassword`, {
        token,
        password,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.msg === "Password updated") {
          setSuccess(
            "Password resettata con successo, loggati con la nuova password!"
          );
        } else {
          setError(
            "Errore durante il reset della password, prova a generare un nuovo link"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Errore durante il reset della password, prova a generare un nuovo link"
        );
      });
  };

  return (
    <div id="resetPasswordPage">
      <header>
        <Header />
      </header>
      <main>
        <div className={`relative`}>
          <div
            className={`flex flex-col w-full rounded-[60px] h-[calc(100vh-24rem)] mx-10 max-w-screen-2xl 2xl:mx-auto`}
          >
            <div className="relative flex flex-col items-center justify-center mt-40 gap-y-4">
              <div className="w-full max-w-xl h-full min-h-[250px] mx-4 md:mx-0">
                <div className="mt-8 relative z-30 w-full h-12 md:h-16 grid grid-cols-2 text-white rounded-[37px] md:shadow-lg bg-tertiary">
                  <div className="absolute z-10 w-full h-full rounded-[37px] bg-primary flex items-center justify-center">
                    <span className="absolute z-20">
                      Resetta la tua password
                    </span>
                  </div>
                </div>
                <div className="flex relative -mt-10 pt-16 flex-col items-center justify-between w-full h-full p-8 bg-gray-100 md:bg-white rounded-[37px] md:shadow-lg">
                  <div className="flex flex-col w-full h-full gap-y-4">
                    <div className="grid items-center grid-cols-3 text-sm text-gray-700 gap-x-2">
                      <p>Nuova password</p>
                      <input
                        className="w-full col-span-2 px-3 py-2 mt-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        type="password"
                        placeholder="Password"
                        id="password"
                        name="password"
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="grid items-center grid-cols-3 text-sm text-gray-700 gap-x-2">
                      <p>Conferma password</p>
                      <input
                        className="w-full col-span-2 px-3 py-2 mt-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        type="password"
                        placeholder="Conferma password"
                        id="confirmPassword"
                        name="confirmPassword"
                      />
                    </div>
                  </div>
                  {error && (
                    <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-green-500 rounded">
                      {success}
                    </div>
                  )}
                  <button
                    onClick={handleResetPassword}
                    className="w-full px-3 py-2 mt-2 text-sm leading-tight text-white border rounded shadow appearance-none bg-primary focus:outline-none focus:shadow-outline"
                  >
                    {loading ? (
                      <UiduSpinner size="small" invertColor />
                    ) : (
                      "Resetta password"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
