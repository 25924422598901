import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoScritta from "../assets/LOGOSCRITTA.svg";

export default function Footer() {
  const [openedElements, setOpenedElements] = useState([
    false,
    false,
    false,
    false,
  ]);
  return (
    <footer className="relative w-full">
      <div className="absolute w-full top-0 h-48 bg-white rounded-b-[60px] md:rounded-b-[210px] z-30"></div>
      <div className="relative z-20 flex flex-col items-center justify-center pt-56 gap-y-10 bg-primary">
        <Link to="/" className="flex items-center pt-6 pb-4 gap-x-2">
          <img src={logoScritta} alt="logo esco" className="w-44" />
        </Link>
        <div className="w-full px-8 pb-12 max-w-screen-2xl 2xl:mx-auto">
          <div className="grid grid-cols-1 px-2 text-white md:mx-16 md:grid-cols-4 gap-x-12">
            <div className="flex flex-col w-full pb-3 border-b-2 border-white md:border-b-0 md:pb-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">About us</p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      !openedElements[0],
                      false,
                      false,
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/mission">Mission</Link>
                <Link to="/vision">Vision</Link>
                {/* <Link to="/work-with-us">Lavora con noi</Link> */}
                <Link to="/propose-structure">Diventa partner</Link>
              </div>
              {openedElements[0] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/mission">Mission</Link>
                  <Link to="/vision">Vision</Link>
                  {/* <Link to="/work-with-us">Lavora con noi</Link> */}
                  <Link to="/propose-structure">Diventa partner</Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">Esplora</p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      !openedElements[1],
                      false,
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/experiences">Esperienze</Link>
                <Link to="/structures">Alloggi</Link>
                <Link to="/escoboxes">Esco box</Link>
              </div>
              {openedElements[1] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/experiences">Esperienze</Link>
                  <Link to="/structures">Alloggi</Link>
                  <Link to="/escoboxes">Esco box</Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">Supporto</p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      false,
                      !openedElements[2],
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/faq">Assistenza</Link>
                <Link to="/cancelation-policy">Cancellazione</Link>
                <Link to="/contactUs">Segnala un problema</Link>
              </div>
              {openedElements[2] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/faq">Assistenza</Link>
                  <Link to="/cancelation-policy">Cancellazione</Link>
                  <Link to="/contactUs">Segnala un problema</Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">Community</p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      false,
                      false,
                      !openedElements[3],
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/community">Community</Link>
                {/* <Link to="/esco-share">Esco share</Link>
              <Link to="/quiz">Quiz</Link> */}
              </div>
              {openedElements[3] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/community">Community</Link>
                  {/* <Link to="/esco-share">Esco share</Link>
                  <Link to="/quiz">Quiz</Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="rounded-t-[60px] md:rounded-t-[120px] bg-white py-4 text-primary text-sm text-center w-full">
          <div className="hidden px-8 md:block">
            esco.zone | Sede legale: Via Parrocchia 84, Pertica Bassa 25078,
            Brescia (Italy) | P.IVA: 04388370985 | Email: info@escozone.it
          </div>
          <div className="px-8 md:hidden">esco.zone | P.IVA: 04388370985</div>
          <div className="mx-8 my-2 border-t border-primary md:hidden"></div>
          <div className="flex justify-center pt-2 gap-x-6 md:gap-x-16">
            <div className="hidden md:block">2024 © all rights reserved</div>
            <Link
              to="https://app.legalblink.it/api/documents/666478df88c4fd001a95c2ae/privacy-policy-per-siti-web-o-e-commerce-it"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              to="https://app.legalblink.it/api/documents/666478df88c4fd001a95c2ae/cookie-policy-it"
              target="_blank"
            >
              Cookie Policy
            </Link>
            <Link to="/terms">Termini di servizio</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
