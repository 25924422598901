import { faker } from "@faker-js/faker";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FeeContext } from "../App";
import StructureIcon from "./StructureIcon";

export default function RoomCard({ room, kind, structure }) {
  const generalServices = [
    {
      id: 1,
      name: "Wi-Fi",
    },
    {
      id: 2,
      name: "Parcheggio",
    },
    {
      id: 3,
      name: "Piscina",
    },
    {
      id: 4,
      name: "Aria condizionata",
    },
    {
      id: 5,
      name: "Riscaldamento",
    },
    {
      id: 6,
      name: "Animali ammessi",
    },
    {
      id: 7,
      name: "Servizio in camera",
    },
    {
      id: 8,
      name: "Servizio lavanderia",
    },
    {
      id: 9,
      name: "Servizio stireria",
    },
    {
      id: 10,
      name: "Servizio navetta",
    },
    {
      id: 11,
      name: "Servizio baby-sitter",
    },
    {
      id: 12,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 13,
      name: "Servizio stireria",
    },
    {
      id: 14,
      name: "Servizio navetta",
    },
    {
      id: 15,
      name: "Servizio baby-sitter",
    },
    {
      id: 16,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 17,
      name: "Servizio stireria",
    },
    {
      id: 18,
      name: "Servizio navetta",
    },
    {
      id: 19,
      name: "Servizio baby-sitter",
    },
    {
      id: 20,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 21,
      name: "Servizio stireria",
    },
    {
      id: 22,
      name: "Servizio navetta",
    },
    {
      id: 23,
      name: "Servizio baby-sitter",
    },
    {
      id: 24,
      name: "Servizio lavaggio a secco",
    },
    {
      id: 25,
      name: "Servizio stireria",
    },
    {
      id: 26,
      name: "Servizio navetta",
    },
  ];
  const detailImages = [
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "toilet",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
  ];

  const { fees } = useContext(FeeContext);

  return (
    <div className="relative flex flex-col w-full border rounded-lg shadow-lg md:flex-row md:h-72">
      <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 md:h-72">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          loop={true}
        >
          {room.images.length > 0
            ? room.images.map((image) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt="room"
                    className="object-cover w-full rounded-lg h-72"
                  />
                </SwiperSlide>
              ))
            : detailImages.map((image) => (
                <SwiperSlide key={image}>
                  <img
                    src={image}
                    alt="room"
                    className="object-cover w-full rounded-lg h-72"
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div className="flex flex-col w-full md:w-7/12 gap-y-4">
        <div className="flex flex-col justify-between w-full px-4 pt-4 md:ml-4 gap-y-4">
          <div className="flex items-center w-full gap-x-4">
            <div className="flex items-center justify-center w-6 h-6 rounded-full ">
              <StructureIcon kind={kind} color={"black"} />
            </div>
            <div className="font-bold">{room.name}</div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="flex text-sm gap-x-2">
              <div className="font-semibold">Letti:</div>
              <div className="flex flex-col text-gray-400">
                {room.double_bed > 0 && (
                  <div>{`${room.double_bed} `} matrimoniali</div>
                )}
                {room.single_bed > 0 && (
                  <div>{`${room.single_bed} `} singoli</div>
                )}
              </div>
            </div>
            <div className="flex text-sm gap-x-2">
              <div className="font-semibold">Superficie:</div>
              <div className="text-gray-400">
                {room.size} m<sup>2</sup>
              </div>
            </div>
          </div>
          <div className="flex flex-col text-sm gap-y-2">
            <div className="font-semibold">Servizi:</div>
            <div className="flex flex-wrap gap-2">
              {room.services.slice(0, 4).map((service) => (
                <div
                  key={service}
                  className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                >
                  {!isNaN(service)
                    ? generalServices.find((s) => s.id === service).name
                    : service}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center w-3/4 text-sm gap-x-2">
            <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
            <div className="text-gray-400">
              Cancellazione{" "}
              {structure.cancellation_policy === "flessibile"
                ? "gratuita fino a 1 settimana prima"
                : structure.cancellation_policy === "moderata"
                ? "gratuita fino a 2 settimana prima"
                : "gratuita fino a 1 mese prima"}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center px-4 py-2 bg-gray-100 md:pt-0 md:pb-4 md:ml-4 md:bg-transparent">
          <div className="flex items-center justify-between w-full gap-x-4">
            <div className="font-semibold">Prezzo a notte:</div>
            <div className="text-xl font-bold">
              {Math.ceil(
                room.price / 100 + (fees.structures.fee * room.price) / 10000
              ).toFixed(0) + ",00"}{" "}
              €
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
