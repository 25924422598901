import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function StructureDetails() {
  const { id } = useParams();

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [phone, setPhone] = useState(structure?.phone_number || "");
  const [rating, setRating] = useState(structure?.stars || 0);

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "name", error: null },
      { id: "phone", error: null },
      { id: "rating", error: null },
    ];

    if (document.getElementById("name").value.length < 5) {
      currentErrors[0].error = "Il nome deve essere lungo almeno 5 caratteri";
    }

    if (phone.length < 5) {
      currentErrors[1].error = "Il numero di telefono non è valido";
    }

    if (rating === 0) {
      currentErrors[2].error = "Inserisci il numero di stelle";
    }

    setErrors(currentErrors);

    if (currentErrors.find((e) => e.error !== null)) return;

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        {
          name: document.getElementById("name").value,
          phone_number: phone,
          stars: rating,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    if (structure && rating !== structure.stars) updateStructure();
    if (structure && phone !== structure.phone_number) updateStructure();
  }, [rating, phone, structure]);

  return (
    <BaseComponent title="Informazioni generali">
      <div className="bg-gray-100 flex flex-col gap-y-6 rounded-md min-h-[400px] overflow-y-auto py-4 px-4 md:px-10 my-4">
        {structure ? (
          <>
            <p className="font-semibold">Nome struttura</p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="es: Agriturismo Bianchi"
              name="name"
              defaultValue={structure?.name}
              id="name"
              onChange={() => updateStructure()}
            />
            {/* {errors.find((e) => e.id === "name")?.error && (
              <p className="-mt-2 text-sm text-gray-400">
                {errors.find((e) => e.id === "name")?.error}
              </p>
            )} */}
            <p className="font-semibold">Numero di stelle</p>
            <StarRatings
              rating={rating}
              starRatedColor="#328575"
              starHoverColor="#328575"
              changeRating={(rating) => setRating(rating)}
              numberOfStars={5}
              name="rating"
              starDimension="30px"
              starSpacing="3px"
            />
            {/* {errors.find((e) => e.id === "rating")?.error && (
              <p className="-mt-2 text-sm text-gray-400">
                {errors.find((e) => e.id === "rating")?.error}
              </p>
            )} */}
            <p className="font-semibold">Numero di telefono</p>
            <PhoneInput
              country="it"
              regions={"europe"}
              placeholder="Numero di telefono"
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              searchStyle={{
                width: "100%",
                height: "35px",
                border: "1px solid rgb(229 231 235)",
              }}
              inputStyle={{
                width: "100%",
                height: "35px",
                border: "1px solid rgb(229 231 235)",
                boxShadow:
                  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              }}
              specialLabel=""
            />
            {/* {errors.find((e) => e.id === "phone")?.error && (
              <p className="-mt-2 text-sm text-gray-400">
                {errors.find((e) => e.id === "phone")?.error}
              </p>
            )} */}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
