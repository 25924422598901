import { faker } from "@faker-js/faker";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ElementsCartContext } from "../App";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import voucher from "../assets/icons/VOUCHER.svg";
import ExperienceIcon from "../components/ExperienceIcon";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StructureIcon from "../components/StructureIcon";

export default function Cart() {
  const [userLogged, setUserLogged] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [cart, setCart] = useState(null);
  const { elements, setElements } = useContext(ElementsCartContext);

  const detailImages = useMemo(() => {
    const images = [
      faker.image.urlLoremFlickr({
        category: "nature",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "montain",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "lake",
        width: 600,
        height: 400,
      }),
    ];
    return images;
  }, []);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function getCart() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/cart/${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        if (!res.data.message) {
          setCart(res.data.cart);
          setElements(res.data.elements);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const deleteCart = async (id) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/cart/delete/${id}`)
      .then((res) => {
        setCart(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCart = async (id, data) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/cart/edit/${id}`, data)
      .then((res) => {
        console.log(res.data);
        getCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    if (elements.length === 1) {
      deleteCart(cart._id);
    } else {
      const data = {
        elements: cart.elements.filter((element) => element._id !== id),
      };
      updateCart(cart._id, data);
    }
  };

  useEffect(() => {
    document.title = "Esco | Carrello";
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    Start();
  }, []);

  useEffect(() => {
    if (userLogged !== null) {
      getCart();
    }
  }, [userLogged]);

  const createSession = async () => {
    const token = localStorage.getItem("token");
    setIsCreatingSession(true);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/orderCreateStripeSession`,
        {
          cart_id: cart._id,
        }
      )
      .then((res) => {
        setIsCreatingSession(false);
        navigate(`/payment/${res.data.client_secret}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const total =
    elements &&
    elements.map((element) => element.total_price).reduce((a, b) => a + b, 0);

  return (
    <div id="page-top">
      <header>
        <div className="relative z-50 md:-mt-16">
          <Header />
        </div>
      </header>
      <main>
        <div className="mx-0 2xl:mx-8">
          <div className="relative mx-16 max-w-screen-2xl 2xl:mx-auto">
            <div className="flex flex-col w-full bg-[#F8F9F9] mt-16 pt-36 rounded-[60px] p-8 py-20 min-h-[500px]">
              <div className="flex items-center gap-x-4">
                {/* <img src={carrello} alt="carrello" className="w-8 h-8" /> */}
                <h1 className="text-3xl text-primary">Il tuo carrello</h1>
              </div>
              {!loading && cart && elements.length > 0 ? (
                <div className="grid grid-cols-6 gap-20 mt-8">
                  <div className="flex flex-col col-span-4 gap-y-4">
                    {elements.map((element, index) => {
                      if (element.experience) {
                        const experience = element.experience;
                        return (
                          <Link
                            to={`/experiences/${experience._id}`}
                            className="relative flex w-full border rounded-lg shadow-lg h-72"
                          >
                            <div className="relative w-5/12 -mt-px overflow-hidden rounded-lg h-72">
                              <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                                loop={true}
                              >
                                {experience.images.length > 0
                                  ? experience.images.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))
                                  : detailImages.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))}
                              </Swiper>
                            </div>
                            <div className="flex flex-col justify-between w-7/12 p-4 ml-4 text-sm gap-y-2">
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center w-full gap-x-4">
                                      <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                                        {element.start_time ? (
                                          <ExperienceIcon
                                            kind={experience.category}
                                            color="black"
                                          />
                                        ) : (
                                          <img
                                            src={voucher}
                                            alt="is a present"
                                            className="w-6 h-6"
                                          />
                                        )}
                                      </div>
                                      <div className="font-bold">
                                        {experience.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full mt-1 gap-x-4">
                                    <div className="flex items-center mr-12 gap-x-2">
                                      <img
                                        src={geotag}
                                        alt="geotag"
                                        className="w-4"
                                      />
                                      <div className="text-center">
                                        {experience.address
                                          ? experience.address.municipality
                                            ? experience.address.municipality
                                            : experience.address.village
                                          : "Pertica Bassa"}
                                      </div>
                                    </div>
                                    {experience.approved_at && (
                                      <div className="flex items-center gap-x-1.5">
                                        <img
                                          src={stella}
                                          alt="stella"
                                          className="w-4"
                                        />
                                        <div>
                                          {experience.reviews
                                            ? averageReviews(experience.reviews)
                                            : "0"}
                                          /5
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleDelete(element._id);
                                  }}
                                  className="absolute z-10 flex items-center justify-center w-8 h-8 text-red-500 bg-white border-2 border-gray-100 rounded-full shadow-lg -right-4 -top-4"
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </button>
                              </div>
                              {element.start_time ? (
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="flex items-center w-full h-8 ">
                                    <div className="font-semibold">Data:</div>
                                    <div className="ml-4 text-primary">
                                      {dayjs(element.start_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full h-8 ">
                                    <div className="font-semibold">
                                      N° di persone:
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults} adulti
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <div>
                                          {element.guests_number.children}{" "}
                                          bambini
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full h-8 ">
                                    <div className="font-semibold">Ora:</div>
                                    <div className="ml-4 text-primary">
                                      {element.start_time}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="flex items-start w-full h-8 col-span-2 gap-x-6 ">
                                    <div className="font-semibold">
                                      Il tuo messaggio:
                                    </div>
                                    <div className="ml-4 line-clamp-2 text-primary">
                                      {element.message}
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full h-8 ">
                                    <div className="font-semibold">
                                      N° di persone:
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults} adulti
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <div>
                                          {element.guests_number.children}{" "}
                                          bambini
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="flex items-center justify-between w-full">
                                <div className="text-lg font-bold">Prezzo:</div>
                                <div className="text-2xl font-bold">
                                  {element.total_price.toFixed(2)} €
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      }
                      if (element.structure) {
                        const structure = element.structure;
                        return (
                          <Link
                            to={`/structures/${structure._id}`}
                            className="relative flex w-full border rounded-lg shadow-lg min-h-72"
                          >
                            <div className="relative w-5/12 -mt-px overflow-hidden rounded-lg h-72">
                              <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                                loop={true}
                              >
                                {structure.images.length > 0
                                  ? structure.images.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))
                                  : detailImages.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))}
                              </Swiper>
                            </div>
                            <div className="flex flex-col justify-between w-7/12 p-4 ml-4 text-sm gap-y-2">
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center w-full gap-x-4">
                                      <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                                        {element.start_date ? (
                                          <StructureIcon
                                            kind={structure.kind}
                                            color="black"
                                          />
                                        ) : (
                                          <img
                                            src={voucher}
                                            alt="is a present"
                                            className="w-6 h-6"
                                          />
                                        )}
                                      </div>
                                      <div className="font-bold">
                                        {structure.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full mt-1 gap-x-4">
                                    <div className="flex items-center mr-12 gap-x-2">
                                      <img
                                        src={geotag}
                                        alt="geotag"
                                        className="w-4"
                                      />
                                      <div className="text-center">
                                        {structure.address
                                          ? structure.address.municipality
                                            ? structure.address.municipality
                                            : structure.address.village
                                          : "Pertica Bassa"}
                                      </div>
                                    </div>
                                    {structure.approved_at && (
                                      <div className="flex items-center gap-x-1.5">
                                        <img
                                          src={stella}
                                          alt="stella"
                                          className="w-4"
                                        />
                                        <div>
                                          {structure.reviews
                                            ? averageReviews(structure.reviews)
                                            : "0"}
                                          /5
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleDelete(element._id);
                                  }}
                                  className="absolute z-10 flex items-center justify-center w-8 h-8 text-red-500 bg-white border-2 border-gray-100 rounded-full shadow-lg -right-4 -top-4"
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </button>
                              </div>
                              <div className="grid grid-cols-2 gap-4">
                                {element.start_date ? (
                                  <div className="flex w-full h-8 ">
                                    <div className="font-semibold">
                                      Periodo:
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      <div>
                                        Dal &nbsp;
                                        {dayjs(element.start_date).format(
                                          "DD/MM/YY"
                                        )}
                                        {"  "}
                                      </div>
                                      <div>
                                        al{"  "}&nbsp;
                                        {dayjs(element.end_date).format(
                                          "DD/MM/YY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-start w-full h-8 col-span-2 gap-x-6 ">
                                    <div className="font-semibold">
                                      Il tuo messaggio:
                                    </div>
                                    <div className="ml-4 line-clamp-2 text-primary">
                                      {element.message}
                                    </div>
                                  </div>
                                )}
                                {element.start_date && (
                                  <div className="flex w-full h-8 ">
                                    <div className="font-semibold">
                                      N° di persone:
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults} adulti
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <div>
                                          {element.guests_number.children}{" "}
                                          bambini
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="grid grid-cols-2 gap-4">
                                <div className="flex w-full h-8 ">
                                  <div className="font-semibold">
                                    {element.booked_rooms.length > 1
                                      ? "Camere"
                                      : "Camera"}
                                    :
                                  </div>
                                  <div className="flex flex-col ml-4 text-primary">
                                    {element.booked_rooms.map((room) => (
                                      <div>
                                        1 x{" "}
                                        {
                                          element.structure_rooms.filter(
                                            (str_room) =>
                                              str_room._id === room.room_id
                                          )[0].name
                                        }
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {element.meals.length > 0 && (
                                  <div className="flex w-full h-8 ">
                                    <div className="font-semibold">Pasti:</div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.meals.map((meal) => (
                                        <div>{meal}</div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {!element.start_date && element.message && (
                                  <div className="flex w-full h-8 ">
                                    <div className="font-semibold">
                                      N° di persone:
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults} adulti
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <div>
                                          {element.guests_number.children}{" "}
                                          bambini
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex items-center justify-between w-full">
                                <div className="text-lg font-bold">Prezzo:</div>
                                <div className="text-2xl font-bold">
                                  {element.total_price.toFixed(2)} €
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </div>
                  <div className="flex flex-col col-span-2 gap-y-6">
                    <div className="flex flex-col p-6 rounded-lg shadow-lg gap-y-4">
                      {elements.map((element, index) => {
                        if (element.experience) {
                          const experience = element.experience;
                          return (
                            <div className="flex items-center justify-between gap-x-2">
                              <div className="flex w-8/12 fle-col">
                                <div>
                                  {experience.name},{" "}
                                  {element.guests_number.adults} adulti
                                  {element.guests_number.children
                                    ? `, ${element.guests_number.children} bambini`
                                    : ""}
                                </div>
                              </div>
                              <div className="font-bold">
                                {element.total_price.toFixed(2)} €
                              </div>
                            </div>
                          );
                        }
                        if (element.structure) {
                          const structure = element.structure;
                          return (
                            <div className="flex items-center justify-between gap-x-2">
                              <div className="flex w-7/12 fle-col">
                                <div>
                                  {structure.name},{" "}
                                  {element.guests_number.adults} adulti
                                  {element.guests_number.children
                                    ? `, ${element.guests_number.children} bambini`
                                    : ""}
                                </div>
                              </div>
                              <div className="font-bold">
                                {element.total_price.toFixed(2)} €
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="flex flex-col p-6 rounded-lg shadow-lg gap-y-4">
                      <div className="flex items-end justify-between gap-x-2">
                        <div className="flex w-6/12 fle-col">
                          <div className="font-semibold">Totale:</div>
                        </div>
                        <div className="font-bold">{total.toFixed(2)} €</div>
                      </div>
                      <div className="border-b" />
                      <Link
                        to="/"
                        className="w-full text-center px-4 py-2 text-lg font-bold text-primary border rounded-[30px] border-primary hover:bg-primary hover:text-white"
                      >
                        Continua ad esplorare
                      </Link>
                      {!isCreatingSession ? (
                        <button
                          onClick={() => createSession()}
                          className="w-full px-4 py-2 text-lg font-bold text-primary border rounded-[30px] border-primary hover:bg-primary hover:text-white"
                        >
                          Procedi all'acquisto
                        </button>
                      ) : (
                        <button className="w-full px-4 py-2 text-lg font-bold border rounded-[30px] border-primary bg-primary text-white">
                          <UiduSpinner size="small" invertColor={true} />
                        </button>
                      )}
                    </div>
                    <div className="flex flex-col p-6 rounded-lg shadow-lg gap-y-4">
                      <div className="font-bold">FAQ</div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="flex items-center justify-center h-96">
                      <UiduSpinner size="medium" className="text-primary" />
                    </div>
                  ) : (
                    <p className="mt-4 text-xl text-gray-500">
                      Il tuo carrello è vuoto, aggiungi dei prodotti per
                      visualizzarli
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
