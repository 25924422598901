import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import ExperienceIcon from "../ExperienceIcon";

export default function ExperienceToApprove() {
  const [experiences, setExperiences] = useState(null);

  const [loading, setLoading] = useState(true);

  const experiencesDetail = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesToApprove/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperiences(res.data);
        console.log(res.data, "res");
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    experiencesDetail();
  }, []);

  return (
    <BaseComponent title="Esperienze da approvare">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[512px] overflow-y-auto py-4 px-10 my-4">
        {experiences && experiences.length > 0 && !loading ? (
          experiences.map((experience, index) => (
            <>
              <div className="flex justify-between w-full gap-x-4">
                <div className="flex items-center w-full gap-x-4">
                  <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                    <ExperienceIcon kind={experience.category} color="black" />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center gap-x-2">
                      <div className="font-bold">{experience.name}</div>
                    </div>
                    <div className="text-xs text-gray-600">
                      {experience.user_id.name} {experience.user_id.surname}
                    </div>
                  </div>
                </div>
                <Link
                  to={`/adminExperience/preview/${experience._id}`}
                  className="flex items-center justify-between w-64 h-8 px-4 text-white rounded-md bg-primary"
                >
                  Vedi anteprima
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </div>
              {index !== experiences.length - 1 && (
                <div className="w-full border-b border-gray-200" />
              )}
            </>
          ))
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <div className="flex w-full h-full text-gray-400">
            Nessuna esperienza da approvare
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
