import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useHover from "@react-hook/hover";
import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ElementsCartContext,
  FavouriteContext,
  UserContext,
  UserLoggedContext,
} from "../App";
import logo from "../assets/LOGO.svg";
import carrello_white from "../assets/icons/CARRELLOWHITE.svg";
import logout from "../assets/icons/LOGOUT.svg";
import { NavbarSwitch } from "./NavbarCreator";
import PopUp from "./PopUp";

export default function NavbarPersonalArea() {
  const currentPath = window.location.pathname.split("/").pop();
  const { userLogged, setUserLogged } = useContext(UserLoggedContext);
  const target = useRef(null);
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { elements, setElements } = useContext(ElementsCartContext);
  const { setFavourite } = useContext(FavouriteContext);
  const { user, setUser } = useContext(UserContext);
  const user_id = localStorage.getItem("user");

  const logOutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate(`/`, { replace: true });
    setUserLogged(false);
    setFavourite(null);
    setElements([]);
    setUser({});
  };

  return (
    <nav className="fixed top-0 z-10 w-full text-white bg-secondary">
      <div className="mx-0 2xl:mx-8">
        <div className="flex items-center justify-between mx-8 max-w-screen-2xl 2xl:mx-auto h-[90px]">
          <div className="absolute left-0 flex items-center h-[90px] px-16 py-5 -ml-8 rounded-r-full w-[28.5%] 2xl:w-[35%] bg-primary">
            {/*  <Link to="/" className="flex items-center gap-x-2">
            <div className="flex">
              <h1 className="text-xl font-semibold">esco</h1>
              <h1 className="text-xl ">.zone</h1>
            </div> 
          </Link>*/}
          </div>
          <Link to="/" className="relative z-10">
            <img src={logo} alt="logo" className="w-36" />
          </Link>
          <div className="flex items-center px-8 py-5 font-semibold gap-x-2">
            <Link
              className={`py-2 px-4 rounded-full border border-secondary ${
                currentPath === "experiences"
                  ? "bg-primary"
                  : "hover:border-primary"
              }`}
              to="/experiences"
            >
              Esperienze
            </Link>
            <Link
              className={`py-2 px-4 rounded-full border border-secondary ${
                currentPath === "structures"
                  ? "bg-primary"
                  : "hover:border-primary"
              }`}
              to="/structures"
            >
              Alloggi
            </Link>
            <Link
              className={`py-2 px-4 rounded-full border border-secondary ${
                currentPath === "escoboxes"
                  ? "bg-primary"
                  : "hover:border-primary"
              }`}
              to="/escoboxes"
            >
              Esco box
            </Link>
            <Link
              className={`py-2 px-4 rounded-full border border-secondary ${
                currentPath === "community"
                  ? "bg-primary"
                  : "hover:border-primary"
              }`}
              to="/community"
            >
              Community
            </Link>
            <Link
              to="/cart"
              className={`relative py-2 px-4 pr-6 rounded-full border border-secondary ${
                currentPath === "cart" ? "bg-primary" : "hover:border-primary"
              }`}
            >
              <img src={carrello_white} alt="carrello" className="w-6 h-6" />
              {elements.length === 0 ? null : (
                <span className="absolute px-2 text-xs text-white rounded-full left-10 -top-2 bg-primary">
                  {elements.length}
                </span>
              )}
            </Link>
            {userLogged && user ? (
              <>
                {user.has_structures || user.has_experiences ? (
                  <PopUp
                    setIsOpen={setIsOpen}
                    iconStyle="noBackground"
                    goUnderneath={true}
                    withoutEvent={true}
                    isOpen={isOpen}
                    icon={
                      <div
                        className="flex items-center border rounded-full"
                        ref={target}
                        style={{
                          borderColor:
                            isHovering || isOpen ? "#246155" : "white",
                        }}
                      >
                        <div
                          className="flex items-center rounded-full"
                          onClick={() => navigate(`/profile/${user_id}`)}
                          style={{
                            backgroundColor:
                              isHovering || isOpen ? "#246155" : "white",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            className={`py-2.5 px-4`}
                            style={{
                              color: isHovering || isOpen ? "white" : "#246155",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => setIsOpen((prev) => !prev)}
                          className="flex items-center h-full"
                        >
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`py-2 px-2 text-white text-sm`}
                          />
                        </div>
                      </div>
                    }
                    items={
                      <>
                        <NavbarSwitch />
                        {/* <Link
                          className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-black hover:bg-gray-50"
                          to={`/profile/${user_id}`}
                        >
                          <img
                            src={backToUser}
                            alt="torna al profilo"
                            className="w-6 h-6 pr-2"
                          />
                          <div>Profilo</div>
                        </Link>
                        {user.has_structures && user.has_experiences && (
                          <>
                            <div className="mx-4 border-b" />
                            <Link
                              className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              to={`/partnerZone/${user_id}`}
                              target="blank"
                            >
                              <img
                                src={backToUser}
                                alt="torna al profilo"
                                className="w-6 h-6 pr-2"
                              />
                              <div>Partner zone</div>
                            </Link>
                          </>
                        )}
                        {user.has_structures && !user.has_experiences && (
                          <>
                            <div className="mx-4 border-b" />
                            <Link
                              className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              to={`/partner/${user_id}`}
                              target="blank"
                            >
                              <img
                                src={backToUser}
                                alt="torna al profilo"
                                className="w-6 h-6 pr-2"
                              />
                              <div>Partner zone</div>
                            </Link>
                          </>
                        )}
                        {user.has_experiences && !user.has_structures && (
                          <>
                            <div className="mx-4 border-b" />
                            <Link
                              className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                              to={`/creatorZone/${user_id}`}
                              target="blank"
                            >
                              <img
                                src={backToUser}
                                alt="torna al profilo"
                                className="w-6 h-6 pr-2"
                              />
                              <div>Partner zone</div>
                            </Link>
                          </>
                        )} */}
                        <div className="mx-4 border-b" />
                        <button
                          className="flex items-center w-full h-8 px-4 py-5 text-sm rounded-b-[30px] cursor-pointer text-black hover:bg-gray-50"
                          type="button"
                          onClick={logOutUser}
                        >
                          <img
                            src={logout}
                            alt="torna al profilo"
                            className="w-6 h-6 pr-2 mx-4"
                          />
                          <div>Logout</div>
                        </button>
                      </>
                    }
                  />
                ) : (
                  <Link
                    className={`py-2 px-4 rounded-full border border-secondary ${
                      currentPath === "login" ||
                      currentPath === `${localStorage.getItem("user")}`
                        ? "bg-primary"
                        : "hover:border-primary"
                    }`}
                    to={`/profile/${localStorage.getItem("user")}`}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </Link>
                )}
              </>
            ) : (
              <Link
                className={`py-2 px-4 rounded-full border border-secondary ${
                  currentPath === "login" ||
                  currentPath === `${localStorage.getItem("user")}`
                    ? "bg-primary"
                    : "hover:border-primary"
                }`}
                to="/login"
              >
                <FontAwesomeIcon icon={faUser} />
              </Link>
            )}
            <Link
              className={`py-2 px-4 rounded-full border border-secondary ${
                currentPath === "becomePartner"
                  ? "bg-primary"
                  : "hover:border-primary"
              }`}
              to="/becomePartner"
            >
              Diventa partner
            </Link>
            <button
              className={`py-2 px-4 rounded-full border border-secondary hover:border-primary`}
            >
              <FontAwesomeIcon icon={faFlag} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
