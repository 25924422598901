import React, { useContext, useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import TagsInput from "react-tagsinput";
import BaseComponent from "../BaseComponent";

import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ProposeContext } from "../../pages/Propose";
import "./tagsInput.css";

export default function Requirements() {
  const animatedComponents = makeAnimated();
  const experienceOptions = ["principiante", "intermedio", "avanzato"];
  const { id } = useParams();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [experienceRequirements, setExperienceRequirements] = useState(
    experience.experience_requirements || null
  );
  const difficultyOptions = ["facile", "medio", "difficile"];
  const [selectedDifficulty, setSelectedDifficulty] = useState(
    experience.difficulty || null
  );
  const [requirements, setRequirements] = useState(
    experience.requirements || []
  );
  const [stuffToBring, setStuffToBring] = useState(
    experience.partecipants_need_to_bring || []
  );
  const [includedStuff, setIncludedStuff] = useState(experience.included || []);

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      ...(experience.category === "outdoor"
        ? [
            { id: "experienceRequirements", error: null },
            { id: "difficulty", error: null },
          ]
        : []),
      { id: "minAge", error: null },
      // { id: "notSuitableFor", error: null },
      // { id: "stuffToBring", error: null },
      { id: "includedStuff", error: null },
    ];

    if (experience.category === "outdoor") {
      if (experienceRequirements === null) {
        currentErrors.find((e) => e.id === "experienceRequirements").error =
          "Devi selezionare un livello di esperienza";
      }

      if (selectedDifficulty === null) {
        currentErrors.find((e) => e.id === "difficulty").error =
          "Devi selezionare una difficoltà";
      }
    }

    if (
      document.getElementById("minAge").value <= 0 ||
      !document.getElementById("minAge").value
    ) {
      currentErrors.find((e) => e.id === "minAge").error =
        "Devi inserire un età minima";
    }

    // if (stuffToBring.length === 0) {
    //   currentErrors[4].error = "Devi inserire almeno un articolo";
    // }

    // if (requirements.length === 0) {
    //   currentErrors[3].error = "Devi inserire almeno un elemento";
    // }

    if (includedStuff.length === 0) {
      currentErrors.find((e) => e.id === "includedStuff").error =
        "Devi inserire almeno un elemento";
    }

    setErrors(currentErrors);

    if (currentErrors.some((e) => e.error !== null)) return;

    const data = {
      ...(experience.category === "outdoor" && {
        experience_requirements: experienceRequirements,
        difficulty: selectedDifficulty,
      }),
      minimum_age: document.getElementById("minAge").value,
      ...(stuffToBring.length > 0 && {
        partecipants_need_to_bring: stuffToBring,
      }),
      included: includedStuff,
      ...(requirements.length > 0 && {
        requirements: requirements,
      }),
      ...(document.getElementById("extra_info").value && {
        extra_info: document.getElementById("extra_info").value,
      }),
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante la modifica dell'esperienza");
      });
  };

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, []);

  useEffect(() => {
    if (
      experience &&
      experience.category === "outdoor" &&
      experienceRequirements !== experience.experience_requirements
    ) {
      updateExperience();
    }
    if (
      experience &&
      experience.category === "outdoor" &&
      selectedDifficulty !== experience.difficulty
    ) {
      updateExperience();
    }
    if (
      experience &&
      stuffToBring.length > 0 &&
      JSON.stringify(stuffToBring) !==
        JSON.stringify(experience.partecipants_need_to_bring)
    ) {
      updateExperience();
    }
    if (
      experience &&
      JSON.stringify(includedStuff) !== JSON.stringify(experience.included)
    ) {
      updateExperience();
    }
    if (
      experience &&
      requirements.length > 0 &&
      JSON.stringify(requirements) !== JSON.stringify(experience.requirements)
    ) {
      updateExperience();
    }
  }, [
    experienceRequirements,
    experience,
    selectedDifficulty,
    stuffToBring,
    includedStuff,
    requirements,
  ]);

  const [isWritingStuffToBring, setIsWritingStuffToBring] = useState(false);
  const [isWritingIncludedStuff, setIsWritingIncludedStuff] = useState(false);
  const [isWritingRequirements, setIsWritingRequirements] = useState(false);

  return (
    <BaseComponent title="Requisiti">
      <div className="bg-gray-100 flex flex-col gap-y-6 rounded-md min-h-[400px] overflow-y-auto py-4 md:py-8 px-4 md:px-10 my-4">
        {experience ? (
          <>
            {experience.category === "outdoor" && (
              <>
                <div className="flex flex-col gap-4 md:items-center md:flex-row">
                  <p className="font-semibold md:w-1/3">
                    Livello di esperienza
                  </p>
                  <div className="flex items-center md:w-2/3 gap-x-2 md:gap-x-4">
                    {experienceOptions.map((option) => (
                      <button
                        className={`${
                          experienceRequirements === option
                            ? "bg-primary text-white"
                            : "bg-white text-primary"
                        } text-sm border border-primary px-4 py-1 rounded-md w-full`}
                        onClick={() => setExperienceRequirements(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col gap-4 md:flex-row md:items-center">
                  <p className="font-semibold md:w-1/3">Difficoltà</p>
                  <div className="flex items-center md:w-2/3 gap-x-2 md:gap-x-4">
                    {difficultyOptions.map((option) => (
                      <button
                        className={`${
                          selectedDifficulty === option
                            ? "bg-primary text-white"
                            : "bg-white text-primary"
                        } text-sm border border-primary px-4 py-1 rounded-md w-full`}
                        onClick={() => setSelectedDifficulty(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <div className="flex items-center md:flex-col md:w-1/3 gap-y-4">
                <p className="w-full font-semibold">Età minima</p>
                <input
                  className="w-full px-3 py-2 pt-[9px] text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="minAge"
                  type="number"
                  placeholder="18"
                  min={0}
                  defaultValue={experience?.minimum_age}
                  onChange={() => updateExperience()}
                />
              </div>
              <div className="flex flex-col md:w-2/3 gap-y-4">
                <p className="flex flex-col w-full font-semibold md:flex-row md:items-center gap-y-1">
                  Requisiti partecipanti{" "}
                  <span className="text-xs text-slate-600">
                    (opzionale, l'età minima verrà aggiunta automaticamente)
                  </span>
                </p>
                <div className="w-full">
                  <TagsInput
                    value={requirements}
                    onChange={(tags) => {
                      setIsWritingRequirements(false);
                      setRequirements(tags);
                    }}
                    inputProps={{
                      placeholder: "Non adatto ai bambini sotto i 10 anni",
                      onInput: () => setIsWritingRequirements(true),
                      onBlur: () => setIsWritingRequirements(false),
                    }}
                    id="notSuitableFor"
                  />
                  {isWritingRequirements && (
                    <div className="w-full px-1">
                      <div className="relative w-full px-4 pt-1 pb-2 -mt-px bg-white rounded-b-lg shadow-lg">
                        <p className="text-sm text-slate-600">
                          Premi invio per aggiungere un elemento
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="font-semibold">
              Cosa devono portare gli ospiti{" "}
              <span className="text-xs text-slate-600">(opzionale)</span>
            </p>
            <div className="w-full">
              <TagsInput
                value={stuffToBring}
                onChange={(tags) => {
                  setIsWritingStuffToBring(false);
                  setStuffToBring(tags);
                }}
                inputProps={{
                  placeholder: "Aggiungi un articolo",
                  onInput: () => setIsWritingStuffToBring(true),
                  onBlur: () => setIsWritingStuffToBring(false),
                }}
              />
              {isWritingStuffToBring && (
                <div className="w-full px-1">
                  <div className="relative w-full px-4 pt-1 pb-2 -mt-px bg-white rounded-b-lg shadow-lg">
                    <p className="text-sm text-slate-600">
                      Premi invio per aggiungere un articolo
                    </p>
                  </div>
                </div>
              )}
            </div>
            <p className="font-semibold">Cos'è incluso</p>
            <div className="w-full">
              <TagsInput
                value={includedStuff}
                onChange={(tags) => {
                  setIsWritingIncludedStuff(false);
                  setIncludedStuff(tags);
                }}
                inputProps={{
                  placeholder: "Aggiungi un elemento",
                  onInput: () => setIsWritingIncludedStuff(true),
                  onBlur: () => setIsWritingIncludedStuff(false),
                }}
              />
              {isWritingIncludedStuff && (
                <div className="w-full px-1">
                  <div className="relative w-full px-4 pt-1 pb-2 -mt-px bg-white rounded-b-lg shadow-lg">
                    <p className="text-sm text-slate-600">
                      Premi invio per aggiungere un elemento
                    </p>
                  </div>
                </div>
              )}
            </div>
            <p className="font-semibold">
              Informazioni utili{" "}
              <span className="text-xs text-slate-600">(opzionale)</span>
            </p>
            <div className="flex flex-col gap-y-1">
              <textarea
                className=" p-4 md:p-6 border-2 border-gray-200 resize-none rounded-[12px] rounded-[40px] "
                placeholder="Tutto ciò che può essere utile per gli ospiti"
                rows={3}
                id="extra_info"
                defaultValue={experience?.extra_info}
                onChange={() => updateExperience()}
              ></textarea>
              {/* <p className="text-sm text-red-400">
                {errors.find((e) => e.id === "description")?.error}
              </p> */}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
