import { faker } from "@faker-js/faker";
import { useLoadScript } from "@react-google-maps/api";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import heart from "../assets/heart.svg";
import indietro_white from "../assets/icons/INDIETRO_WHITE.svg";
import CardUser from "../components/CardUser";
import Cover from "../components/Cover";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import StructureBody from "../components/StructureBody";
import StructureIcon from "../components/StructureIcon";
import {
  comfortServices,
  generalServices,
  hospitalityServices,
  securityServices,
} from "../components/structureComponents/StructureServices";
import StructureSubtitle from "../components/structureComponents/StructureSubtitle";

export default function StructurePreview() {
  const { id } = useParams();

  const [structure, setStructure] = useState(null);
  const [rooms, setRooms] = useState(null);
  const location = useLocation();
  const [userLogged, setUserLogged] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandText, setExpandText] = useState(false);
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });

  const detailImages = [
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "nature",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "hotel",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "hotel",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "experience",
      width: 600,
      height: 400,
    }),
  ];

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  const structureDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/structure/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento della struttura");
      });

  const userDetail = async () => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.is_admin) {
          setLoading(false);
        } else {
          navigate(`/profile/${user}`, { replace: true });
        }
      })
      .catch(() => {
        alert("Errore durante il caricamento della struttura");
      });
  };

  useEffect(() => {
    Start();
  }, []);

  useEffect(() => {
    if (userLogged) {
      structureDetail().then((res) => {
        if (location.pathname === `/adminStructure/preview/${id}`) {
          userDetail();
        } else {
          setLoading(false);
        }
        setStructure(res.structure);
        setRooms(res.rooms);
      });
    }
  }, [userLogged]);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    let data = null;
    if (location.pathname === `/adminStructure/preview/${id}`) {
      data = {
        approved_at: new Date(),
      };
    } else {
      data = {
        request_of_approval_made_at: new Date(),
      };
    }
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        if (location.pathname === `/adminStructure/preview/${id}`) {
          window.location.href = `/adminArea/${user}`;
        } else {
          window.location.href = `/partner/${user}/structure`;
        }
      })
      .catch(() => {
        alert("Errore durante la pubblicazione della struttura");
      });
  };

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (!userLogged) {
    navigate(`/login`, { replace: true });
  }

  document.title = "Esco | Anteprima struttura";

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {structure ? (
          <>
            <div>
              <Cover url={structure.images[0]} isVideo={false} />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-mt-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-center w-full px-4 mt-8 mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      <div className="flex items-center justify-center w-8 rounded-full md:w-10 md:h-10 min-w-8 ">
                        <StructureIcon kind={structure.kind} color="black" />
                      </div>
                      <h1 className="text-3xl text-center md:text-4xl text-secondary">
                        {structure.name}
                      </h1>
                      <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
                        <img src={heart} alt="heart" className="w-5" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-8 md:grid lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-4 md:gap-y-6">
                        <StructureSubtitle structure={structure} />
                        <div className="flex flex-row flex-wrap w-full gap-3">
                          {structure.services.map((service) => (
                            <>
                              {service.services.map((serv) => (
                                <div className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary">
                                  {service.kind === "general"
                                    ? generalServices.find((s) => s.id === serv)
                                        .name
                                    : service.kind === "security"
                                    ? securityServices.find(
                                        (s) => s.id === serv
                                      ).name
                                    : service.kind === "comfort"
                                    ? comfortServices.find((s) => s.id === serv)
                                        .name
                                    : hospitalityServices.find(
                                        (s) => s.id === serv
                                      ).name}
                                </div>
                              ))}
                            </>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {structure.description}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {!expandText
                              ? "Continua a leggere..."
                              : "Mostra meno"}
                          </button>
                        </div>
                        <ImageGroup images={structure.images} />
                        <StructureBody structure={structure} rooms={rooms} />
                        <div className="w-full border-b border-primary" />
                      </div>
                      {location.pathname === `/adminStructure/preview/${id}` ? (
                        <div className="fixed gap-x-12 bottom-0 left-0 right-0 flex items-center w-screen py-6 font-semibold px-12 bg-white rounded-b rounded-[60px] border-t border-gray-200 shadow-md z-50">
                          <Link
                            className="flex items-center justify-center px-6 py-2 rounded-full bg-primary"
                            to={`/adminArea/${user}`}
                          >
                            <img
                              src={indietro_white}
                              alt="indietro"
                              className="w-12"
                            />
                          </Link>
                          <button
                            className="w-full px-6 py-2 bg-gray-200 rounded-md"
                            onClick={() =>
                              (window.location.href = `mailto:${structure.user_id.email}`)
                            }
                          >
                            Suggerisci modifiche
                          </button>
                          <button
                            className="w-full px-6 py-2 text-white rounded-md bg-primary"
                            onClick={updateStructure}
                          >
                            Approva
                          </button>
                        </div>
                      ) : (
                        <div className="fixed gap-x-2 md:gap-x-12 bottom-0 left-0 right-0 flex w-screen py-4 md:py-6 font-semibold px-4 md:px-12 bg-white rounded-b rounded-[25px] md:rounded-[60px] border-t border-gray-200 shadow-md z-50">
                          <Link
                            className="w-full px-6 py-2 text-sm text-center bg-gray-200 rounded-md md:text-base"
                            to={`/proposeStructure/${structure._id}/legal`}
                          >
                            Torna a modificare
                          </Link>
                          {!structure.request_of_approval_made_at ? (
                            <button
                              className="w-full px-6 py-2 text-sm text-white rounded-md bg-primary md:text-base"
                              onClick={updateStructure}
                            >
                              Pubblica
                            </button>
                          ) : (
                            <button
                              className="w-full px-6 py-2 text-sm text-white rounded-md bg-primary md:text-base"
                              onClick={() => navigate(`/partner/${user}`)}
                            >
                              torna al profilo
                            </button>
                          )}
                        </div>
                      )}
                      <div className="flex flex-col w-full px-4 mb-24 gap-y-4 md:px-0">
                        <CardUser user={structure.user_id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </main>
    </div>
  );
}
