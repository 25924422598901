import dayjs from "dayjs";
import React from "react";
import calendar from "../../assets/icons/CALENDARIO.svg";
import geotag from "../../assets/icons/GEOTAG.svg";
import stella from "../../assets/icons/STELLA.svg";

export default function ExperienceSubtitle({ experience }) {
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  return (
    <div className="flex flex-wrap gap-4 text-lg">
      {!experience.is_recurrent && (
        <div className="flex items-center mr-6 md:mr-12 gap-x-4">
          <img src={calendar} alt="calendar" className="w-6" />
          <div>
            {dayjs(experience.data_info.start_date).format("DD MMM YY")}
          </div>
        </div>
      )}
      <div className="flex items-center mr-6 md:mr-12 gap-x-4">
        <img src={geotag} alt="calendar" className="w-6" />
        <div className="text-center">
          {experience.address
            ? experience.address.municipality
              ? experience.address.municipality
              : experience.address.village
            : "Pertica Bassa"}
        </div>
      </div>
      <div className="flex items-center mr-6 gap-x-4 md:mr-0">
        <img src={stella} alt="calendar" className="w-6" />
        <div>
          {experience.reviews ? averageReviews(experience.reviews) : "0"}
          /5
        </div>
      </div>
      <div className="flex items-center gap-x-1.5 text-base text-primary">
        <div>
          {experience.reviews ? experience.reviews.length : 0} recensioni
        </div>
      </div>
    </div>
  );
}
