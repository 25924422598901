import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BaseComponent from "./BaseComponent";

export default function Contability({ user, isExperience }) {
  const [loading, setLoading] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [accountToComplete, setAccountToComplete] = useState(null);
  const [accountCompleted, setAccountCompleted] = useState(null);

  const checkStripeAccount = async () => {
    const token = localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/checkStripeAccountStatus/${user._id}`,
        {
          is_from_experience: isExperience,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data.stripe_status === "inactive") {
          setAccountToComplete(res.data.accountLink);
        }
        if (res.data.stripe_status === "active") {
          setAccountCompleted(res.data.dashboardLink);
        }
        setCheckingStatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createStripeAccount = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/createStripeAccount/${user._id}`,
        {
          is_from_experience: isExperience,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        const { url } = res.data.accountLink;
        console.log(url);
        window.location.href = url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkStripeAccount();
  }, []);

  return (
    <BaseComponent title="">
      <h1 className="hidden text-2xl text-center md:text-left text-primary md:block">
        Contabilità
      </h1>
      <div className="flex flex-col px-4 pt-4 md:p-10 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-6 min-h-[400px]">
        {!checkingStatus ? (
          <>
            {!user.stripe_id ? (
              <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                <p className="text-lg font-semibold">
                  Collega Stripe al tuo account per ricevere pagamenti !
                </p>
                <button
                  className="flex items-center w-full px-4 py-2 font-semibold text-white rounded-md md:w-fit gap-x-4 bg-primary"
                  onClick={() => {
                    createStripeAccount();
                  }}
                >
                  {!loading ? (
                    <>
                      <p>Collega Stripe</p>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </>
                  ) : (
                    <>
                      <p>Caricamento..</p>
                      <UiduSpinner size="small" invertColor />
                    </>
                  )}
                </button>
              </div>
            ) : accountToComplete ? (
              <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                <p className="text-lg font-semibold">
                  Completa la registrazione del tuo account stripe per ricevere
                  i pagamenti
                </p>
                <a
                  href={accountToComplete}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center w-full px-4 py-2 font-semibold text-white rounded-md md:w-fit gap-x-4 bg-primary"
                >
                  <p>Completa la registrazione</p>
                  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-between w-full p-4 bg-white border rounded-md md:flex-row gap-y-6">
                <p className="text-lg font-semibold">
                  Vai alla tua dashboard per vedere il tuo saldo e i pagamenti!
                </p>
                <a
                  href={accountCompleted}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center w-full px-4 py-2 font-semibold text-white rounded-md md:w-fit gap-x-4 bg-primary"
                >
                  <p>Vai alla tua dashboard</p>
                  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full min-h-[320px]">
            <UiduSpinner size="medium" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
