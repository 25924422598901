import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsisH,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import modifica from "../../assets/icons/MODIFICA.svg";
import BaseComponent from "../BaseComponent";
import PopUp from "../PopUp";

function UpdateAvailabilityCard({
  experience,
  value,
  handleClick,
  bookingsForTheDay,
}) {
  const haveReservation = bookingsForTheDay.find(
    (booking) => booking.experience_id === experience._id && !booking.user
  );

  const [currentAmount, setCurrentAmount] = useState(
    experience.max_group_size -
      (haveReservation ? haveReservation.guests_number_reserved || 0 : 0)
  );

  useEffect(() => {
    setCurrentAmount(
      experience.max_group_size -
        (haveReservation ? haveReservation.guests_number_reserved : 0)
    );
  }, [bookingsForTheDay]);

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flex justify-between w-full text-primary">
        <h3 className="text-xl font-semibold">{experience.name}</h3>
        <p>
          {value.toLocaleDateString("it", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>
      </div>
      <div className="border rounded-[25px] md:rounded-full border-primary">
        <div className="grid items-center w-full grid-cols-2 px-4 py-2 md:pl-6 md:pr-4 ">
          <div className="font-bold md:border-r border-primary">
            Posti disponibili
          </div>
          <div className="flex items-center justify-between w-full gap-x-4">
            <div className="items-center justify-center hidden pl-12 md:flex gap-x-4">
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="minus"
                onClick={(e) => {
                  if (currentAmount > 0) {
                    setCurrentAmount(currentAmount - 1);
                    handleClick(e, experience);
                  }
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="text-center ">
                {currentAmount}
                {" su "}
                {experience.max_group_size}
              </div>
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="plus"
                onClick={(e) => handleClick(e, experience)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className="md:hidden" />
            <PopUp
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  className="text-xs text-white"
                />
              }
              items={
                <>
                  <Link
                    className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                    to={`/propose/${experience._id}/recurrence`}
                  >
                    <img
                      src={modifica}
                      alt="modifica"
                      className="w-6 h-6 pr-2"
                    />
                    <div>Modifica disponibilità</div>
                  </Link>
                  <div className="mx-4 border-b" />
                  <div className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50">
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="pr-2 text-primary"
                    />
                    <div>Cancella data</div>
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div className="flex items-center px-4 py-2 md:hidden gap-x-4">
          <button
            className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
            name="minus"
            onClick={(e) => {
              if (currentAmount > 0) {
                setCurrentAmount(currentAmount - 1);
                handleClick(e, experience);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <div className="text-center ">
            {currentAmount}
            {" su "}
            {experience.max_group_size}
          </div>
          <button
            className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
            name="plus"
            onClick={(e) => handleClick(e, experience)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Availability({ experiences }) {
  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );
  const [value, onChange] = useState(new Date());
  const [experiencesOfTheDay, setExperiencesOfTheDay] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState(null);
  const [loading, setLoading] = useState(true);

  const getBookings = async () => {
    const token = localStorage.getItem("token");
    const data = {
      date: new Date(value),
      experiences: experiencesOfTheDay.map((experience) => experience._id),
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesBookingsOnDate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data, "res");
        setBookingsForTheDay(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 200);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const createReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingExperienceReservation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => [
          ...bookingsForTheDay,
          res.data,
        ]);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const updateReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingExperienceReservationUpdate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.map((booking) => {
            if (booking._id === data.id) {
              return {
                ...booking,
                guests_number_reserved: data.guests_number_reserved,
              };
            } else {
              return booking;
            }
          });
          return updatedBookingsForTheDay;
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const deleteReservation = async (id) => {
    const token = localStorage.getItem("token");
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingExperienceReservationDelete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.filter(
            (booking) => booking._id !== id
          );
          return updatedBookingsForTheDay;
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleClick = (e, experience) => {
    e.preventDefault();
    const totalGuests = bookingsForTheDay
      .filter((booking) => booking.experience_id === experience._id)
      .reduce((acc, booking) => acc + booking.guests_number_reserved, 0);
    const bookingId = bookingsForTheDay.find(
      (booking) => booking.experience_id === experience._id && !booking.user
    );
    if (totalGuests >= experience.max_group_size && !bookingId) {
      return;
    }
    console.log(e.target.name, e.target.parentNode.name, e);
    if (
      e.target.name === "plus" ||
      e.target.parentNode.name === "plus" ||
      e.target.parentNode.parentNode.name === "plus"
    ) {
      if (bookingId) {
        if (bookingId.guests_number_reserved > 1) {
          updateReservation({
            id: bookingId._id,
            guests_number_reserved: bookingId.guests_number_reserved - 1,
          });
        } else {
          deleteReservation(bookingId._id);
        }
      } else {
        console.log("bookingId not found");
      }
    } else {
      if (bookingId) {
        if (
          totalGuests < experience.max_group_size &&
          bookingId.guests_number_reserved < experience.max_group_size
        ) {
          updateReservation({
            id: bookingId._id,
            guests_number_reserved: bookingId.guests_number_reserved + 1,
          });
        }
      } else {
        createReservation({
          experience_id: experience._id,
          date: new Date(value),
        });
      }
    }
  };

  function getExperiencesOfTheDay(date) {
    const dateFormatted = date.toISOString().slice(0, 10);
    const experiences = experiencesApproved.filter((experience) => {
      if (experience.is_recurrent) {
        if (experience.data_info.recurrency_type === "week") {
          const dayOfWeek = date.getDay();
          const experienceDayOfWeek = experience.data_info.week_days;
          const experienceDayOfWeekByNumber = experienceDayOfWeek.map((day) => {
            switch (day) {
              case "lunedi":
                return 1;
              case "martedi":
                return 2;
              case "mercoledi":
                return 3;
              case "giovedi":
                return 4;
              case "venerdi":
                return 5;
              case "sabato":
                return 6;
              default:
                return 0;
            }
          });
          return experienceDayOfWeekByNumber.includes(dayOfWeek);
        } else {
          return experience;
        }
      } else {
        const currentDate = new Date(experience.data_info.start_date);
        currentDate.setDate(currentDate.getDate() - 1);

        const formattedDate = currentDate.toLocaleDateString("it", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return (
          new Date(dateFormatted).toLocaleString("it", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) === formattedDate
        );
      }
    });
    setExperiencesOfTheDay(experiences);
  }

  useEffect(() => {
    if (experiencesOfTheDay.length > 0) {
      getBookings();
    }
  }, [experiencesOfTheDay]);

  useEffect(() => {
    getExperiencesOfTheDay(value);
  }, []);

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tua disponibilità
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getExperiencesOfTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
            tileContent={({ date, view }) => {
              if (view === "month") {
                const dateFormatted = date.toISOString().slice(0, 10);
                const experience = experiencesApproved.map((experience) => {
                  if (experience.is_recurrent) {
                    if (experience.data_info.recurrency_type === "week") {
                      const dayOfWeek = date.getDay();
                      const experienceDayOfWeek =
                        experience.data_info.week_days;
                      const experienceDayOfWeekByNumber =
                        experienceDayOfWeek.map((day) => {
                          switch (day) {
                            case "lunedi":
                              return 1;
                            case "martedi":
                              return 2;
                            case "mercoledi":
                              return 3;
                            case "giovedi":
                              return 4;
                            case "venerdi":
                              return 5;
                            case "sabato":
                              return 6;
                            default:
                              return 0;
                          }
                        });
                      if (experienceDayOfWeekByNumber.includes(dayOfWeek)) {
                        return experienceDayOfWeekByNumber.includes(dayOfWeek);
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  } else {
                    const currentDate = new Date(
                      experience.data_info.start_date
                    );
                    currentDate.setDate(currentDate.getDate() - 1);

                    const formattedDate = currentDate.toLocaleDateString("it", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    });
                    return (
                      new Date(dateFormatted).toLocaleString("it", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }) === formattedDate
                    );
                  }
                });

                const today = new Date();

                today.setDate(today.getDate() - 1);

                const currentDay = today.toLocaleDateString("it", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                });

                if (
                  experience.includes(true) &&
                  new Date(dateFormatted).toLocaleString("it", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }) >= currentDay
                ) {
                  return (
                    <div className="w-1 h-1 -mt-2 bg-green-500 rounded-full" />
                  );
                }
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full mt-6 gap-y-6">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {experiencesOfTheDay.length > 0 && bookingsForTheDay ? (
                <>
                  {experiencesOfTheDay.map((experience) => (
                    <UpdateAvailabilityCard
                      key={`${experience._id}-${value}`}
                      experience={experience}
                      value={value}
                      handleClick={handleClick}
                      bookingsForTheDay={bookingsForTheDay}
                    />
                  ))}
                </>
              ) : (
                <div className="grid items-center w-full grid-cols-2 py-2 pl-6 pr-4 border rounded-full border-primary">
                  <p className="font-bold">
                    Nessuna esperienza disponibile per questa data
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
