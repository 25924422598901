import { useLoadScript } from "@react-google-maps/api";
import Tooltip from "@uidu/tooltip";
import React from "react";
import cancellazione from "../assets/icons/CANCELLAZIONE.svg";
import durata from "../assets/icons/DURATA.svg";
import lingue from "../assets/icons/LINGUE.svg";
import mappa from "../assets/icons/MAPPA.svg";
import recensioni from "../assets/icons/REVIEW.svg";
import stella from "../assets/icons/STELLA.svg";
import Map from "../components/Map";
import SingleReview from "../components/SingleReview";
import ExperienceInfoCards from "./experienceComponents/ExperienceInfoCards";
import ItineraryRender from "./experienceComponents/ItineraryRender";

export default function ExperienceBody({ experience }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const durationTime = (duration) => {
    const hours = +`${duration}`.split(".")[0];
    let minutes = +`${duration}`.split(".")[1];
    if (`${minutes}`.length === 1) {
      minutes = minutes * 10;
    }
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} ${hours === 1 ? "ora" : "ore"}`;
    }
    if (minutes > 0) {
      if (timeString) timeString += " ";
      timeString += `${minutes} min`;
    }
    return timeString;
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-8 mt-4 md:grid-cols-2">
        <div className="flex flex-col gap-y-8">
          <div className="flex items-center gap-x-2">
            <img src={durata} alt="durata" className="w-6" />
            <div className="text-lg font-semibold">
              Durata{" "}
              <span className="text-base font-normal">
                {durationTime(experience.duration)}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <img src={cancellazione} alt="cancellazione" className="w-6" />
            <div className="text-lg font-semibold">
              Cancellazione gratuita{" "}
              <span className="text-base font-normal">
                {`fino a ${experience.cancellation_policy} ore prima`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <div className="flex items-center gap-x-2">
            <img src={lingue} alt="lingue" className="w-6" />
            <div className="text-lg font-semibold">
              Lingua{" "}
              {experience.languages.map((language, index) => (
                <span className="text-base font-normal" key={language}>
                  {language}
                  {index < experience.languages.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <ExperienceInfoCards experience={experience} />
      <div className="w-full border-b border-primary" />
      <div
        className={`grid grid-cols-1 gap-8 ${
          experience.steps.length > 1 ? "md:grid-cols-2" : ""
        }`}
      >
        {experience.steps && experience.steps.length > 1 && (
          <ItineraryRender experience={experience} />
        )}
        <div className="flex flex-col pb-24 gap-y-6">
          <div className="flex items-start gap-x-4">
            <div className="flex items-center gap-x-2">
              <img src={mappa} alt="luogo" className="w-6" />
              <div className="text-lg font-semibold">Luogo:</div>
            </div>
            <Tooltip
              className="w-3/4"
              content={
                experience.address && experience.address?.formatted_address
                  ? experience.address.formatted_address
                  : "Via parrocchia 84"
              }
              position="bottom"
            >
              <div className="mt-1 ml-4 text-base font-normal truncate">
                {experience.address && experience.address?.formatted_address
                  ? experience.address.formatted_address
                  : "Via parrocchia 84"}
              </div>
            </Tooltip>
          </div>
          <div className="min-h-[300px]">
            <Map
              address={{
                latitude: experience?.address?.latitude || 45.7658776,
                longitude: experience?.address?.longitude || 10.3767045,
              }}
              isLoaded={isLoaded}
              loadError={loadError}
            />
          </div>
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <img src={recensioni} alt="recensioni" className="w-6" />
          <div className="text-lg font-semibold">Recensioni:</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex items-center gap-x-1.5">
            <img src={stella} alt="stella" className="w-5" />
            <div>
              {experience.reviews ? averageReviews(experience.reviews) : 0}
              /5
            </div>
          </div>
          <div className="flex items-center gap-x-1.5 text-base text-primary">
            <div>
              {experience.reviews ? experience.reviews.length : 0} recensioni
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {experience.reviews ? (
          <>
            {experience.reviews.map((review) => (
              <SingleReview key={review.id} review={review} />
            ))}
          </>
        ) : (
          <div className="flex text-gray-400">
            Nessuna recensione disponibile al momento
          </div>
        )}
      </div>
    </>
  );
}
