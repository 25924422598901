import Spinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import QuizCta from "../components/QuizCta";
import SingleArticle from "../components/SingleArticle";
import { generateFakeData } from "../utils/generateFakeData";

export default function Community() {
  const [articles, setArticles] = useState([]);
  const [shareArticles, setShareArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const getArticles = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/articlesLimited`)
      .then((res) => {
        console.log(res, "articles");
        setArticles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "Esco | Community";
    setLoading(true);
    setTimeout(() => {
      getArticles();
      setShareArticles(generateFakeData({ kind: "escoShare", amount: 4 }));
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            url={"https://escopublic.s3.eu-north-1.amazonaws.com/blog.jpeg"}
            description={"ENTRA NELLA NOSTRA COMMUNITY"}
            isVideo={false}
          />
        </div>
        <div className="relative">
          <PageBody
            isCommunity
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          >
            <h1 className="my-16 text-3xl md:text-4xl text-center text-secondary">
              Lasciati ispirare
            </h1>
            {loading ? (
              <div className="flex items-center justify-center w-full h-96">
                <Spinner size="large" />
              </div>
            ) : (
              <div>
                <div className="grid mx-4 md:mx-0 md:grid-cols-3 gap-x-4">
                  {articles.slice(0, 6).map((item) => (
                    <SingleArticle item={item} key={item.id} />
                  ))}
                </div>
                <QuizCta />
                <h1 className="mb-20 text-3xl md:text-4xl text-center mt-28 text-secondary">
                  Dal nostro blog
                </h1>
                <div className="grid mx-4 mt-16 md:mx-0 md:grid-cols-3 gap-x-4">
                  {articles.slice(6, 9).map((item) => (
                    <SingleArticle item={item} key={item.id} />
                  ))}
                </div>
                {/* <h1 className="mt-16 mb-20 text-3xl md:text-4xl text-center text-secondary">
                  Segui le orme di altri esploratori
                </h1>

                <div className="grid grid-cols-2 gap-x-8">
                  {shareArticles.map((item) => (
                    <SingleShareArticle item={item} key={item.id} />
                  ))}
                </div> */}
              </div>
            )}
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
