import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import agriturism from "../assets/agriturism.svg";
import agriturismoBlack from "../assets/agriturismoBlack.svg";
import bAndB from "../assets/b&b.svg";
import bAndBBlack from "../assets/b&bBlack.svg";
import camping from "../assets/camping.svg";
import campingBlack from "../assets/campingBlack.svg";
import glamping from "../assets/glamping.svg";
import glampingBlack from "../assets/glampingBlack.svg";
import hostel from "../assets/ostello.svg";
import hostelBlack from "../assets/ostelloBlack.svg";
import rifugio from "../assets/rifugio.svg";
import rifugioBlack from "../assets/rifugioBlack.svg";

export default function SearchBarStructureMobile({
  setSelectedTab,
  selectedTab,
}) {
  const refs = {
    agriturismo: useRef(null),
    rifugio: useRef(null),
    "B&B": useRef(null),
    camping: useRef(null),
    ostello: useRef(null),
    glamping: useRef(null),
  };

  useEffect(() => {
    if (refs[selectedTab] && refs[selectedTab].current) {
      refs[selectedTab].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedTab]);

  return (
    <div className="relative z-20 pb-4">
      <div className="absolute inset-x-0 flex items-center justify-start w-full -mt-16 overflow-x-auto overflow-y-auto min-h-32 no-scrollbar gap-x-3">
        <div className="relative ml-8" ref={refs.agriturismo}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("agriturismo")}
            style={{
              border: selectedTab === "agriturismo" ? "" : "2px solid #246155",
              transform: selectedTab === "agriturismo" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "agriturismo" ? (
              <img
                src={agriturism}
                alt="agriturism"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
                onClick={() => setSelectedTab("agriturismo")}
              />
            ) : (
              <img
                src={agriturismoBlack}
                alt="agriturism"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "agriturismo" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary "
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "agriturismo" ? "600" : "400",
            }}
          >
            agriturismo
          </div>
        </div>
        {/* same logic as above with  rifugio,b&b,camping, hostel, glamping */}
        <div className="relative" ref={refs.rifugio}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("rifugio")}
            style={{
              border: selectedTab === "rifugio" ? "" : "2px solid #246155",
              transform: selectedTab === "rifugio" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "rifugio" ? (
              <img
                src={rifugio}
                alt="rifugio"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
              />
            ) : (
              <img
                src={rifugioBlack}
                alt="rifugio"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "rifugio" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "rifugio" ? "600" : "400",
            }}
          >
            rifugio
          </div>
        </div>
        <div className="relative" ref={refs["B&B"]}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("B&B")}
            style={{
              border: selectedTab === "B&B" ? "" : "2px solid #246155",
              transform: selectedTab === "B&B" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "B&B" ? (
              <img
                src={bAndB}
                alt="b&b"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
              />
            ) : (
              <img
                src={bAndBBlack}
                alt="b&b"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "B&B" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "B&B" ? "600" : "400",
            }}
          >
            b&b
          </div>
        </div>
        <div className="relative" ref={refs.camping}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("camping")}
            style={{
              border: selectedTab === "camping" ? "" : "2px solid #246155",
              transform: selectedTab === "camping" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "camping" ? (
              <img
                src={camping}
                alt="camping"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
              />
            ) : (
              <img
                src={campingBlack}
                alt="camping"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "camping" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "camping" ? "600" : "400",
            }}
          >
            camping
          </div>
        </div>
        <div className="relative" ref={refs.ostello}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("ostello")}
            style={{
              border: selectedTab === "ostello" ? "" : "2px solid #246155",
              transform: selectedTab === "ostello" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "ostello" ? (
              <img
                src={hostel}
                alt="hostel"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
              />
            ) : (
              <img
                src={hostelBlack}
                alt="hostel"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "ostello" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "ostello" ? "600" : "400",
            }}
          >
            hostel
          </div>
        </div>
        <div className="relative mr-8" ref={refs.glamping}>
          <button
            type="button"
            className="relative z-20 flex items-center justify-center w-full h-full px-3 py-5 bg-white rounded-full gap-x-4 focus:outline-none min-w-16"
            onClick={() => setSelectedTab("glamping")}
            style={{
              border: selectedTab === "glamping" ? "" : "2px solid #246155",
              transform: selectedTab === "glamping" ? "scale(1.15)" : "none",
            }}
          >
            {selectedTab === "glamping" ? (
              <img
                src={glamping}
                alt="glamping"
                className="relative z-20 w-6 h-6 min-w-6 min-h-6"
              />
            ) : (
              <img
                src={glampingBlack}
                alt="glamping"
                className="relative z-20 w-5 h-5 min-w-5 min-h-5"
              />
            )}
            {selectedTab === "glamping" && (
              <motion.div
                layoutId="selectedCategory"
                className="absolute z-10 w-full h-full rounded-full bg-primary"
              ></motion.div>
            )}
          </button>
          <div
            className="absolute w-full text-xs text-center -bottom-6"
            style={{
              fontWeight: selectedTab === "glamping" ? "600" : "400",
            }}
          >
            glamping
          </div>
        </div>
      </div>
    </div>
  );
}
