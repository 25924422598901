import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React, { useMemo } from "react";

export default function Map({ address, isLoaded, loadError }) {
  const center = useMemo(
    () => ({
      lat: address.latitude,
      lng: address.longitude,
    }),
    [address]
  );

  if (!isLoaded) return <div>Loading...</div>;

  return (
    isLoaded &&
    !loadError && (
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        zoom={10}
        center={center}
      >
        <MarkerF position={center} />
      </GoogleMap>
    )
  );
}
