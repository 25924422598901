import React, { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Carousel({
  children,
  slidesPerView = "auto",
  withAutoplay = true,
  withLoop = true,
  centered = false,
}) {
  const [showPagination, setShowPagination] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowPagination(false);
      } else {
        setShowPagination(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={slidesPerView === 1 ? 1.2 : "auto"}
        spaceBetween={20}
        autoplay={
          withAutoplay ? { delay: 5000, disableOnInteraction: true } : false
        }
        loop={withLoop}
        centeredSlides={(slidesPerView === 1 && withLoop) || centered}
        pagination={{
          clickable: true,
          enabled: showPagination,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="w-full mySwiper"
      >
        {children}
      </Swiper>
    </>
  );
}
