import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { FavouriteContext, FeeContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import AddToCartExperience from "../components/AddToCartExperience";
import CardUser from "../components/CardUser";
import CarouselExperiences from "../components/CarouselExperiences";
import Cover from "../components/Cover";
import ExperienceBody from "../components/ExperienceBody";
import ExperienceIcon from "../components/ExperienceIcon";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import ExperienceSubtitle from "../components/experienceComponents/ExperienceSubtitle";

export default function Experience() {
  const { id } = useParams();
  const controls = useAnimation();
  const [experience, setExperience] = useState(null);
  const [expandText, setExpandText] = useState(false);
  const [openCartMobile, setOpenCartMobile] = useState(false);
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const ref = useRef(null);
  const { fees } = useContext(FeeContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `ExperiencePageContainer`,
    });

  const experienceDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/experiencePublic/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento dell'esperienza");
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    experienceDetail().then((res) => {
      console.log(res, "res");
      setExperience(res.experience);
    });
  }, [id]);

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].experiences.find(
          (experienceToFind) =>
            experienceToFind.experience_id === experience._id
        )
      ) {
        const newExperiences = favourite[0].experiences.filter(
          (experienceToFind) =>
            experienceToFind.experience_id !== experience._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: newExperiences,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Esperienza rimossa dai preferiti con successo.");
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              experiences: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].experiences
                  : []),
                { experience_id: experience._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Esperienza aggiunta ai preferiti con successo.");
          });
      }
    }
  };

  document.title = "Esco | " + (experience ? experience.name : "Esperienza");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const referenceElement = ref.current;
      if (!referenceElement) return;

      const referencePosition = referenceElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (referencePosition <= windowHeight) {
        // Nascondi la sezione fissa quando l'elemento di riferimento è visibile
        controls.start({ y: 100, opacity: 0 });
      } else {
        // Mostra la sezione fissa quando l'elemento di riferimento non è visibile
        controls.start({ y: 0, opacity: 1 });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, ref]);

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {experience ? (
          <>
            <div>
              <Cover
                url={
                  experience.images && experience.images.length > 0
                    ? experience.images[0]
                    : "https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                isVideo={false}
              />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-md-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-center w-full px-4 mt-8 mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      <div className="mt-1 md:mt-0">
                        <div
                          className="flex items-center justify-center w-8 h-8 rounded-full md:w-10 md:h-10 min-w-8"
                          style={
                            experience.category === "wine"
                              ? { backgroundColor: "#612430" }
                              : experience.category === "art"
                              ? { backgroundColor: "#C8531C" }
                              : experience.category === "outdoor"
                              ? { backgroundColor: "#4C8AFB" }
                              : experience.category === "authentic"
                              ? { backgroundColor: "#CA9526" }
                              : null
                          }
                        >
                          <ExperienceIcon
                            kind={experience.category}
                            dimension={windowWidth >= 768 ? 6 : 5}
                          />
                        </div>
                      </div>
                      <h1 className="text-3xl text-center md:text-3xl md:text-4xl text-secondary">
                        {experience.name}
                      </h1>
                      <div className="mt-1 md:mt-0">
                        <button
                          className="flex items-center justify-center w-8 h-8 bg-white rounded-full md:w-10 md:h-10 min-w-8 md:min-w-10"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            addToFavourites();
                          }}
                        >
                          {/* <img src={heart} alt="heart" className="w-5" /> */}
                          {favourite &&
                          favourite.length > 0 &&
                          favourite[0].experiences.find(
                            (experienceToFind) =>
                              experienceToFind.experience_id === experience._id
                          ) ? (
                            <img
                              src={heartFull}
                              alt="heartFull"
                              className="h-8 md:h-10"
                            />
                          ) : (
                            <img
                              src={heart}
                              alt="heart"
                              className="h-8 md:h-10"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-4 md:gap-y-6">
                        <ExperienceSubtitle experience={experience} />
                        <div className="flex flex-row w-full">
                          {experience.tags.map((tag) => (
                            <div
                              key={tag}
                              className="flex items-center justify-center px-2 ml-3 border rounded-md shadow-md text-primary border-primary"
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {experience.description}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {" "}
                            {!expandText
                              ? "Continua a leggere..."
                              : "Mostra meno"}
                          </button>
                        </div>
                        <ImageGroup images={experience.images} />
                        <ExperienceBody experience={experience} />
                      </div>
                      <div className="flex-col hidden md:flex gap-y-6">
                        <CardUser user={experience.user_id} />
                        <AddToCartExperience element={experience} />
                      </div>
                    </div>
                    <h1
                      ref={ref}
                      className="m-12 text-3xl text-center md:text-3xl md:text-4xl text-secondary"
                    >
                      Esperienze correlate
                    </h1>
                    <div className="-mb-6">
                      <CarouselExperiences selectedTab={experience.category} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              containerId={`ExperiencePageContainer`}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Bounce}
              stacked
            />
            {openCartMobile && (
              <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-white md:hidden">
                <AddToCartExperience
                  element={experience}
                  setOpenCartMobile={setOpenCartMobile}
                />
              </div>
            )}
            <motion.div
              id="fixed-section"
              className="fixed bottom-0 right-0 z-40 w-full p-4 bg-white shadow-inner md:hidden"
              initial={{ y: 100, opacity: 0 }}
              animate={controls}
              transition={{ duration: 0.5 }}
            >
              <p className="font-bold text-primary">
                Da{" "}
                {Math.ceil(
                  experience.price.child / 100 +
                    (fees.experiences.fee * experience.price.child) / 10000
                ).toFixed(0) + ",00"}{" "}
                €
              </p>
              <div className="flex w-full mt-2 gap-x-4">
                <button
                  className="w-full py-2 text-white rounded-full bg-primary"
                  onClick={() => setOpenCartMobile((prev) => !prev)}
                >
                  Prenota
                </button>
                <button
                  className="w-full py-2 text-white rounded-full bg-escoBlack"
                  onClick={() => setOpenCartMobile((prev) => !prev)}
                >
                  Regala
                </button>
              </div>
            </motion.div>
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
