import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { UserContext } from "../App";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function ContactUs() {
  const [errors, setErrors] = useState({});
  const { user } = useContext(UserContext);
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-[#07bc0c]"
        />
      ),
      containerId: `SupportTicketContainer`,
    });

  const createSupportTicket = async () => {
    const email = document.getElementById("email").value;
    const fullname = document.getElementById("fullname").value;
    const message = document.getElementById("message").value;

    const currentErrors = {};

    if (!email || email === "") {
      currentErrors.email = "Email is required";
    }

    if (!fullname || fullname === "") {
      currentErrors.fullname = "Fullname is required";
    }

    if (!message || message === "") {
      currentErrors.message = "Message is required";
    }

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/supportTicketCreate`, {
        ...(user._id && { user_id: user._id }),
        user_email: email,
        user_fullname: fullname,
        description: message,
      })
      .then((res) => {
        notify(
          "Messaggio inviato con successo! Ti risponderemo al più presto."
        );
        document.getElementById("email").value = user.email ? user.email : "";
        document.getElementById("fullname").value =
          user.name && user.surname ? `${user.name} ${user.surname}` : "";
        document.getElementById("message").value = "";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "Esco | Contattaci";
    document
      .getElementById("contactUsPage")
      .scrollIntoView({ behavior: "smooth" }, { block: "start" });
  }, []);

  return (
    <div id="contactUsPage">
      <header>
        <Header />
      </header>
      <main>
        <div className="mx-0 2xl:mx-16">
          <div className="relative mx-4 md:mx-16 max-w-screen-2xl 2xl:mx-auto">
            <div className="flex flex-col justify-center w-full rounded-[60px] md:p-8 md:pt-36 md:py-20">
              <div className="flex flex-col w-full pt-12 gap-y-4">
                <h1 className="text-3xl font-bold md:text-4xl ">Contattaci</h1>
                <p className="text-lg text-gray-600">
                  Hai bisogno di aiuto? Contattaci compilando il modulo
                  sottostante.
                </p>
              </div>
              <div className="flex flex-col w-full mt-8 gap-y-4">
                <div className="flex flex-col gap-y-2">
                  <label htmlFor="email" className="text-lg font-semibold">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="p-4 border border-gray-200 rounded-lg"
                    placeholder="Inserisci la tua email"
                    defaultValue={user.email ? user.email : ""}
                  />
                </div>
                <div className="flex flex-col gap-y-2">
                  <label htmlFor="fullname" className="text-lg font-semibold">
                    Nome Completo
                  </label>
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    className="p-4 border border-gray-200 rounded-lg"
                    placeholder="Nome e Cognome"
                    defaultValue={
                      user.name && user.surname
                        ? `${user.name} ${user.surname}`
                        : ""
                    }
                  />
                </div>
                <div className="flex flex-col gap-y-2">
                  <label htmlFor="message" className="text-lg font-semibold">
                    Messaggio
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="h-40 p-4 border border-gray-200 rounded-lg resize-none"
                    placeholder="Inserisci il tuo messaggio"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    createSupportTicket();
                  }}
                  className="py-4 mt-6 font-semibold text-white rounded-lg bg-primary"
                >
                  Invia
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          containerId={`SupportTicketContainer`}
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        />
      </main>
      <Footer />
    </div>
  );
}
