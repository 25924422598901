import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import ExperienceIcon from "../ExperienceIcon";

function ExperienceToApprove({ experience }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faHourglassHalf}
          className="w-5 h-5 md:w-6 md:h-6 text-primary"
        />
        <div className="text-sm font-bold md:text-base">
          Richiesta di approvazione inviata
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
        <div className="flex items-center w-full gap-x-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-full ">
            <ExperienceIcon kind={experience.category} color="black" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <div>{experience.name}</div>
            </div>
            <div className="text-xs text-gray-600">
              {experience.user_id.name} {experience.user_id.surname}
            </div>
          </div>
        </div>
        <Link
          to={`/experience/preview/${experience._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Vedi anteprima
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
}

function ExperiencesToComplete({ experience }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="w-5 h-5 text-yellow-500 md:w-6 md:h-6"
        />
        <div className="text-sm font-bold md:text-base">
          Completa la tua esperienza
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
        <div className="flex items-center w-full gap-x-4">
          {experience && experience.name ? (
            <>
              <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                <ExperienceIcon kind={experience.category} color="black" />
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-x-2">
                  <div>{experience?.name}</div>
                </div>
                <div className="text-xs text-gray-600">
                  {experience.user_id.name} {experience.user_id.surname}
                </div>
              </div>
            </>
          ) : (
            <p className="w-full text-sm text-gray-400 md:w-2/3">
              Attenzione, la tua esperienza risulta incompleta, inserisci i dati
              mancanti per poterla pubblicare
            </p>
          )}
        </div>
        <Link
          to={`/propose/${experience._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Completa caricamento
        </Link>
      </div>
    </div>
  );
}

export default function Home({ experiences, user }) {
  const experiencesToApprove = experiences.filter(
    (experience) =>
      experience.request_of_approval_made_at && !experience.approved_at
  );

  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );

  const experiencesToComplete = experiences.filter(
    (experience) => !experience.request_of_approval_made_at
  );

  const isStripeConnected = user.stripe_status === "active";

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Gli ultimi aggiornamenti
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {!isStripeConnected && experiencesApproved.length > 0 && (
          <div className="border rounded-md shadow-md gap-y-6">
            <div className="flex w-full p-4 border-b gap-x-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
              <div className="text-sm font-bold md:text-base">
                Collega stripe al tuo account per ricevere pagamenti
              </div>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
            </div>
            <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
              <div className="flex items-center w-full gap-x-4">
                <p className="w-full text-sm text-gray-400 md:w-2/3">
                  Attenzione, manca ancora un passaggio per mettere online la
                  tua esperienza, vai alla sezione contabilità per completare il
                  setup
                </p>
              </div>
              <Link
                to={`/creatorZone/${user._id}/accounting`}
                className="flex items-center justify-between w-64 h-8 px-4 text-white rounded-md bg-primary"
              >
                Vai a contabilità
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        )}
        {experiencesToApprove.length > 0 && (
          <>
            {experiencesToApprove.map((experience, index) => (
              <ExperienceToApprove experience={experience} />
            ))}
          </>
        )}
        {experiencesToComplete.length > 0 && (
          <>
            {experiencesToComplete.map((experience, index) => (
              <ExperiencesToComplete experience={experience} />
            ))}
          </>
        )}
      </div>
    </BaseComponent>
  );
}
