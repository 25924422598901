import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function ApprovalType() {
  const { id } = useParams();
  const { structure, setStructure } = useContext(ProposeStructureContext);
  const [automaticApproval, setAutomaticApproval] = useState(
    structure?.automatic_approval || false
  );

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    let data = {};
    let currentError = [{ error: null }];

    data = {
      automatic_approval: automaticApproval,
    };

    if (currentError.find((e) => e.error !== null)) return;
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    if (structure) {
      updateStructure();
    }
  }, [automaticApproval]);

  return (
    <BaseComponent title="Abilita l'approvazione automatica">
      <div className="flex flex-col justify-between min-h-[500px] overflow-y-auto bg-gray-100 rounded-md px-4 py-4 my-4">
        {structure ? (
          <>
            <div className="flex p-4 text-white rounded-md md:p-6 bg-primary gap-x-4">
              <div className="flex flex-col justify-between w-full text-sm gap-y-4">
                <div className="flex items-center text-lg gap-x-3 md:gap-x-4">
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  <p className="font-semibold">
                    Cosa si intende per approvazione automatica? &nbsp; 🤔
                  </p>
                </div>
                <p>
                  Esco dà ai propri partner 3 giorni per approvare o rifiutare
                  una prenotazione. Se non si risponde entro questo termine, la
                  prenotazione verrà contrassegnata come rifiutata. Con
                  l'approvazione automatica, le prenotazioni verranno approvte
                  automaticamente alla ricezione! Seleziona questa opzione solo
                  se sei sicuro di poter garantire la disponibilità della
                  struttura.
                </p>
                <label
                  htmlFor="hasAutomaticApproval"
                  className="flex items-center text-base font-semibold gap-x-3"
                >
                  <input
                    type="checkbox"
                    id="hasAutomaticApproval"
                    name="hasAutomaticApproval"
                    className="hidden"
                    defaultChecked={false}
                  />
                  <div
                    className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                    onClick={() => {
                      setAutomaticApproval(!automaticApproval);
                    }}
                  >
                    {automaticApproval && (
                      <FontAwesomeIcon className="h-3" icon={faCheck} />
                    )}
                  </div>
                  Voglio abilitare l'approvazione automatica
                </label>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
