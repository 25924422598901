import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import commento from "../assets/icons/COMMENTO.svg";
import lettura from "../assets/icons/LETTURA.svg";
import like from "../assets/icons/LIKE.svg";
import CardUser from "../components/CardUser";
import CarouselArticles from "../components/CarouselArticles";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import SingleReview from "../components/SingleReview";

export default function Article() {
  const { id } = useParams();

  const [article, setArticle] = useState(null);
  const wordsPerMinute = 200;
  const [numberOfWords, setNumberOfWords] = useState(0);

  const articleDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/article/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento dell'articolo");
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    articleDetail().then((res) => {
      setArticle(res);
      setNumberOfWords(res.body.split(" ").length);
    });
  }, [id]);

  document.title = "Esco | " + (article ? article.title : "Articolo");

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {article ? (
          <>
            <div>
              <Cover
                url={
                  article.images && article.images.length > 1
                    ? article.images[0]
                    : "https://images.unsplash.com/photo-1519681393784-d120267933ba?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                isVideo={false}
              />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-mt-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex items-center justify-center w-full px-4 my-8 md:my-16 gap-x-8">
                      <h1 className="text-3xl text-center md:text-4xl text-secondary">
                        {article.title}
                      </h1>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="flex flex-col px-4 gap-y-4 md:gap-y-6">
                        <div className="flex flex-wrap justify-around gap-4 text-lg">
                          <div className="flex items-center mr-12 gap-x-2">
                            <img src={lettura} alt="lettura" className="w-6" />
                            <div className="text-center">
                              {Math.ceil(numberOfWords / wordsPerMinute)} min di
                              lettura
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <img src={like} alt="like" className="w-6" />
                            <div>
                              Piace a {article.likes ? article.likes.length : 0}{" "}
                              esploratori
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <img
                              src={commento}
                              alt="commento"
                              className="w-6"
                            />
                            <div>
                              {article.comments ? article.comments.length : 0}
                              commenti
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row w-full gap-3">
                          {article.tags.map((tag) => (
                            <div
                              key={tag}
                              className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                        <div className="h-auto text-lg font-semibold">
                          {article.sub_title}
                        </div>
                        <ImageGroup images={article.images} h={"[500px]"} />
                        <div className="h-auto">{article.body}</div>
                        <div className="w-full border-b border-primary" />
                        <div className="flex justify-between">
                          <div className="flex items-center gap-x-2">
                            <img
                              src={commento}
                              alt="commento"
                              className="w-6"
                            />
                            <div className="text-lg font-semibold">
                              Commenti:
                            </div>
                          </div>
                          <div className="flex gap-x-4">
                            <div className="flex items-center gap-x-1.5 text-base text-primary">
                              <div>
                                {article.comments ? article.comments.length : 0}{" "}
                                commenti
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {article.comments ? (
                            <>
                              {article.reviews.map((review) => (
                                <SingleReview key={review.id} review={review} />
                              ))}
                            </>
                          ) : (
                            <div className="flex text-gray-400">
                              Nessun commento disponibile per questo articolo
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-6">
                        {article.user_id && <CardUser user={article.user_id} />}
                      </div>
                    </div>
                    <h1 className="m-12 text-3xl text-center md:text-4xl text-secondary">
                      Articoli correlati
                    </h1>
                    <div className="-mb-6">
                      <CarouselArticles />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
