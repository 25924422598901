import React from "react";

export default function SingleLoad({ length }) {
  return (
    <>
      {Array.from({ length }).map((_, i) => (
        <div
          className="w-full bg-gray-300 animate-pulse h-[335px] rounded-md shado-md mb-10"
          key={i}
        ></div>
      ))}
    </>
  );
}
