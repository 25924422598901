import React from "react";

export default function BaseComponent({
  title,
  children,
  sidebar,
  titleComponent = null,
}) {
  return (
    <div className="md:pl-12">
      <div className="justify-between hidden md:flex">
        {titleComponent ? (
          titleComponent
        ) : (
          <h1 className="text-2xl text-primary">{title}</h1>
        )}
        {sidebar}
      </div>
      <div className="overflow-y-auto row">
        <div className="col-12">{children}</div>
      </div>
    </div>
  );
}
