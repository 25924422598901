import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import StructureIcon from "../StructureIcon";

function StructureToApprove({ structure }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faHourglassHalf}
          className="w-5 h-5 md:w-6 md:h-6 text-primary"
        />
        <div className="text-sm font-bold md:text-base">
          Richiesta di approvazione inviata
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
        <div className="flex items-center w-full gap-x-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-full ">
            <StructureIcon kind={structure.kind} color="black" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <div>{structure.name}</div>
            </div>
          </div>
        </div>
        <Link
          to={`/structure/preview/${structure._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Vedi anteprima
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
}

function StructuresToComplete({ structure }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="w-5 h-5 text-yellow-500 md:w-6 md:h-6"
        />
        <div className="text-sm font-bold md:text-base">
          Struttura incompleta
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
        <div className="flex items-center w-full gap-x-4">
          {structure && structure.name ? (
            <>
              <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                <StructureIcon kind={structure.kind} color="black" />
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-x-2">
                  <div>{structure?.name}</div>
                </div>
              </div>
            </>
          ) : (
            <p className="w-full text-sm text-gray-400 md:w-2/3">
              Attenzione, il caricamento della struttura non è ancora
              completato, completa tutti i passaggi per poterla rendere
              visibile.
            </p>
          )}
        </div>
        <Link
          to={`/proposeStructure/${structure._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Completa caricamento
        </Link>
      </div>
    </div>
  );
}

export default function Home({ structures, user }) {
  const structuresToApprove = structures.filter(
    (structure) =>
      structure.request_of_approval_made_at && !structure.approved_at
  );

  const structuresToComplete = structures.filter(
    (structure) => !structure.request_of_approval_made_at
  );

  const approvedStructures = structures.filter(
    (structure) => structure.approved_at
  );

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Gli ultimi aggiornamenti
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {user.stripe_status !== "active" && approvedStructures.length > 0 && (
          <div className="border rounded-md shadow-md gap-y-6">
            <div className="flex w-full p-4 border-b gap-x-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
              <div className="text-sm font-bold md:text-base">
                Collega stripe al tuo account per ricevere pagamenti
              </div>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
            </div>
            <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
              <div className="flex items-center w-full gap-x-4">
                <p className="w-full text-sm text-gray-400 md:w-2/3">
                  Attenzione, manca ancora un passaggio per mettere online la
                  tua struttura, vai alla sezione contabilità per completare il
                  setup
                </p>
              </div>
              <Link
                to={`/creatorZone/${user._id}/accounting`}
                className="flex items-center justify-between w-64 h-8 px-4 text-white rounded-md bg-primary"
              >
                Vai a contabilità
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        )}
        {structuresToApprove.length > 0 && (
          <>
            {structuresToApprove.map((structure, index) => (
              <StructureToApprove structure={structure} />
            ))}
          </>
        )}
        {structuresToComplete.length > 0 && (
          <>
            {structuresToComplete.map((structure, index) => (
              <StructuresToComplete structure={structure} />
            ))}
          </>
        )}
      </div>
    </BaseComponent>
  );
}
