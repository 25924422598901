import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { FavouriteContext, FeeContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import AddToCartStructure from "../components/AddToCartStructure";
import CardUser from "../components/CardUser";
import CarouselStructures from "../components/CarouselStructures";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageGroup from "../components/ImageGroup";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";
import StructureBody from "../components/StructureBody";
import StructureIcon from "../components/StructureIcon";
import {
  comfortServices,
  generalServices,
  hospitalityServices,
  securityServices,
} from "../components/structureComponents/StructureServices";
import StructureSubtitle from "../components/structureComponents/StructureSubtitle";

export default function Structure() {
  const { id } = useParams();
  const controls = useAnimation();
  const ref = useRef(null);
  const { fees } = useContext(FeeContext);
  const [openCartMobile, setOpenCartMobile] = useState(false);
  const [structure, setStructure] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [expandText, setExpandText] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { favourite, setFavourite } = useContext(FavouriteContext);
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `StructurePageContainer`,
    });

  const structureDetail = async () =>
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/structurePublic/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        alert("Errore durante il caricamento della struttura.");
      });

  useEffect(() => {
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    structureDetail().then((res) => {
      if (
        res.structure.is_suspended ||
        !res.structure.approved_at ||
        !res.structure.terms_accepted_at
      ) {
        navigate("/");
        window.location.reload();
      }
      setStructure(res.structure);
      setRooms(res.rooms);
    });
  }, [id]);

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].structures.find(
          (structureToFind) => structureToFind.structure_id === structure._id
        )
      ) {
        const newStructures = favourite[0].structures.filter(
          (structureToFind) => structureToFind.structure_id !== structure._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: newStructures,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura rimossa dai preferiti con successo.");
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              structures: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].structures
                  : []),
                { structure_id: structure._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify("Struttura aggiunta ai preferiti con successo.");
          });
      }
    }
  };

  document.title = "Esco | " + (structure ? structure.name : "Struttura");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const referenceElement = ref.current;
      if (!referenceElement) return;

      const referencePosition = referenceElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (referencePosition <= windowHeight) {
        // Nascondi la sezione fissa quando l'elemento di riferimento è visibile
        controls.start({ y: 100, opacity: 0 });
      } else {
        // Mostra la sezione fissa quando l'elemento di riferimento non è visibile
        controls.start({ y: 0, opacity: 1 });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, ref]);

  const lowestPriceRoom =
    rooms && rooms.length > 0
      ? rooms.reduce((minRoom, currentRoom) => {
          const currentPrice =
            currentRoom.price / 100 +
            (fees.structures.fee * currentRoom.price) / 10000;
          const minPrice =
            minRoom.price / 100 + (fees.structures.fee * minRoom.price) / 10000;
          return currentPrice < minPrice ? currentRoom : minRoom;
        }, rooms[0])
      : null;

  const lowestPrice = lowestPriceRoom
    ? Math.ceil(
        lowestPriceRoom.price / 100 +
          (fees.structures.fee * lowestPriceRoom.price) / 10000
      ).toFixed(0) + ",00"
    : "Caricamento...";

  return (
    <div id="page-top">
      <header>
        <Header />
      </header>
      <main>
        {structure ? (
          <>
            <div>
              <Cover url={structure.images[0]} isVideo={false} />
            </div>
            <div className="relative">
              <div className="relative w-full -mt-60 md:-mt-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10 ">
                <div className="md:mx-8 max-w-screen-2xl 2xl:mx-auto">
                  <div className="py-1 md:p-8">
                    <div className="flex justify-center w-full px-4 mt-8 mb-8 md:mb-16 gap-x-2 md:gap-x-8">
                      <div className="flex items-center justify-center w-8 rounded-full md:w-10 md:h-10 min-w-8 ">
                        <StructureIcon
                          kind={structure.kind}
                          color="black"
                          dimension={windowWidth >= 768 ? 10 : 8}
                        />
                      </div>
                      <h1 className="text-3xl text-center md:text-4xl text-secondary">
                        {structure.name}
                      </h1>

                      <button
                        className="flex items-center justify-center w-8 h-8 bg-white rounded-full min-w-8 md:w-10 md:h-10"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          addToFavourites();
                        }}
                      >
                        {favourite &&
                        favourite.length > 0 &&
                        favourite[0].structures.find(
                          (structureToFind) =>
                            structureToFind.structure_id === structure._id
                        ) ? (
                          <img
                            src={heartFull}
                            alt="heartFull"
                            className="h-10"
                          />
                        ) : (
                          <img src={heart} alt="heart" className="w-10" />
                        )}
                      </button>
                    </div>
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                      <div className="flex flex-col col-span-2 px-4 gap-y-6">
                        <StructureSubtitle structure={structure} />
                        <div className="flex flex-row flex-wrap w-full gap-3">
                          {structure.services.map((service) => (
                            <>
                              {service.services.map((serv) => (
                                <div className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary">
                                  {service.kind === "general"
                                    ? generalServices.find((s) => s.id === serv)
                                        .name
                                    : service.kind === "security"
                                    ? securityServices.find(
                                        (s) => s.id === serv
                                      ).name
                                    : service.kind === "comfort"
                                    ? comfortServices.find((s) => s.id === serv)
                                        .name
                                    : hospitalityServices.find(
                                        (s) => s.id === serv
                                      ).name}
                                </div>
                              ))}
                            </>
                          ))}
                        </div>
                        <div className={expandText ? "h-auto" : "line-clamp-3"}>
                          {structure.description}
                        </div>
                        <div className="relative flex justify-end -mt-4">
                          <button
                            className="text-primary"
                            onClick={() => setExpandText(!expandText)}
                          >
                            {!expandText
                              ? "Continua a leggere..."
                              : "Mostra meno"}
                          </button>
                        </div>
                        <ImageGroup images={structure.images} />
                        {/* <div className="flex items-center w-full py-4 gap-x-10">
                      <div className="flex items-center gap-x-2">
                        <FontAwesomeIcon icon={faUserCheck} />
                        <div>Gli ospiti vanno pazzi per: </div>
                      </div>
                      {structure.userFavoritesAmenities.map((tag) => (
                        <div
                          key={tag}
                          className="flex items-center justify-center px-2 ml-3 border rounded-md shadow-md text-primary border-primary"
                        >
                          {tag}
                        </div>
                      ))}
                    </div> */}
                        <StructureBody structure={structure} rooms={rooms} />
                      </div>
                      <div className="flex-col hidden md:flex gap-y-6">
                        <CardUser user={structure.user_id} />
                        <AddToCartStructure
                          structure={structure}
                          rooms={rooms}
                        />
                      </div>
                    </div>
                    <h1
                      ref={ref}
                      className="m-12 text-3xl text-center md:text-4xl text-secondary"
                    >
                      Strutture correlate
                    </h1>
                    <div>
                      <CarouselStructures />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              containerId={`StructurePageContainer`}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Bounce}
              stacked
            />
            <ToastContainer
              containerId={`SingleStructureContainer`}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Bounce}
              stacked
            />
            {openCartMobile && (
              <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-white md:hidden">
                <AddToCartStructure
                  structure={structure}
                  rooms={rooms}
                  setOpenCartMobile={setOpenCartMobile}
                />
              </div>
            )}
            <motion.div
              id="fixed-section"
              className="fixed bottom-0 right-0 z-40 w-full p-4 bg-white shadow-inner md:hidden"
              initial={{ y: 100, opacity: 0 }}
              animate={controls}
              transition={{ duration: 0.5 }}
            >
              <p className="font-bold text-primary">
                Da {lowestPrice} € a notte
              </p>
              <div className="flex w-full mt-2 gap-x-4">
                <button
                  className="w-full py-2 text-white rounded-full bg-primary"
                  onClick={() => setOpenCartMobile((prev) => !prev)}
                >
                  Prenota
                </button>
                <button
                  className="w-full py-2 text-white rounded-full bg-escoBlack"
                  onClick={() => setOpenCartMobile((prev) => !prev)}
                >
                  Regala
                </button>
              </div>
            </motion.div>
          </>
        ) : (
          <LoadingSpinnerPage />
        )}
      </main>
      <Footer />
    </div>
  );
}
