import React from "react";
import art from "../assets/art.svg";
import artBlack from "../assets/artBlack.svg";
import authentic from "../assets/auth.svg";
import authenticBlack from "../assets/authBlack.svg";
import outdoor from "../assets/out.svg";
import outdoorBlack from "../assets/outBlack.svg";
import food from "../assets/wine.svg";
import foodBlack from "../assets/wineBlack.svg";

export default function ExperienceIcon({ kind, color, dimension = 10 }) {
  return kind === "wine" ? (
    <img
      src={color === "black" ? foodBlack : food}
      alt="wine"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "art" ? (
    <img
      src={color === "black" ? artBlack : art}
      alt="art"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "outdoor" ? (
    <img
      src={color === "black" ? outdoorBlack : outdoor}
      alt="outdoor"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : kind === "authentic" ? (
    <img
      src={color === "black" ? authenticBlack : authentic}
      alt="authentic"
      className={`w-${dimension} h-${dimension}`}
    />
  ) : null;
}
