import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import ExperienceBookingSummaryCard from "../ExperienceBookingSummaryCard";
import StructureBookingSummaryCard from "../StructureBookingSummaryCart";

export default function Bookings() {
  const [bookings, setBookings] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  async function GetBookings() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/bookings/user/${id}`)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setBookings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    GetBookings();
  }, []);

  return (
    <BaseComponent title="">
      <div>
        <h1 className="text-2xl text-center md:text-left text-primary">
          Le tue prenotazioni
        </h1>
        {!loading && bookings.length > 0 ? (
          <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md md:pl-0 md:pr-8 gap-y-6">
            {bookings.map((booking, index) =>
              !booking.room_id ? (
                <ExperienceBookingSummaryCard
                  key={index}
                  booking={booking}
                  isMobile={isMobile}
                />
              ) : (
                <StructureBookingSummaryCard
                  key={index}
                  booking={booking}
                  isMobile={isMobile}
                />
              )
            )}
          </div>
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full min-h-[368px]">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <h1 className="my-8 text-gray-500">Non hai prenotazioni</h1>
        )}
      </div>
    </BaseComponent>
  );
}
