import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import BaseComponent from "../BaseComponent";

export default function Bookings({ experiences }) {
  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );
  const [value, onChange] = useState(new Date());
  const [experiencesOfTheDay, setExperiencesOfTheDay] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState(null);
  const [loading, setLoading] = useState(true);

  const getBookings = async () => {
    const token = localStorage.getItem("token");
    const data = {
      date: new Date(value),
      experiences: experiencesOfTheDay.map((experience) => experience._id),
    };
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesBookingsOnDate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data, "res");
        setBookingsForTheDay(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 200);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  function getExperiencesOfTheDay(date) {
    const dateFormatted = date.toISOString().slice(0, 10);
    const experiences = experiencesApproved.filter((experience) => {
      if (experience.is_recurrent) {
        if (experience.data_info.recurrency_type === "week") {
          const dayOfWeek = date.getDay();
          const experienceDayOfWeek = experience.data_info.week_days;
          const experienceDayOfWeekByNumber = experienceDayOfWeek.map((day) => {
            switch (day) {
              case "lunedi":
                return 1;
              case "martedi":
                return 2;
              case "mercoledi":
                return 3;
              case "giovedi":
                return 4;
              case "venerdi":
                return 5;
              case "sabato":
                return 6;
              default:
                return 0;
            }
          });
          return experienceDayOfWeekByNumber.includes(dayOfWeek);
        } else {
          return experience;
        }
      } else {
        const currentDate = new Date(experience.data_info.start_date);
        currentDate.setDate(currentDate.getDate() - 1);

        const formattedDate = currentDate.toLocaleDateString("it", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return (
          new Date(dateFormatted).toLocaleString("it", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) === formattedDate
        );
      }
    });
    setExperiencesOfTheDay(experiences);
  }

  useEffect(() => {
    if (experiencesOfTheDay.length > 0) {
      getBookings();
    }
  }, [experiencesOfTheDay]);

  useEffect(() => {
    getExperiencesOfTheDay(value);
  }, []);

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tue prenotazioni
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getExperiencesOfTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
            tileContent={({ date, view }) => {
              if (view === "month") {
                const dateFormatted = date.toISOString().slice(0, 10);
                const experience = experiencesApproved.map((experience) => {
                  if (experience.is_recurrent) {
                    if (experience.data_info.recurrency_type === "week") {
                      const dayOfWeek = date.getDay();
                      const experienceDayOfWeek =
                        experience.data_info.week_days;
                      const experienceDayOfWeekByNumber =
                        experienceDayOfWeek.map((day) => {
                          switch (day) {
                            case "lunedi":
                              return 1;
                            case "martedi":
                              return 2;
                            case "mercoledi":
                              return 3;
                            case "giovedi":
                              return 4;
                            case "venerdi":
                              return 5;
                            case "sabato":
                              return 6;
                            default:
                              return 0;
                          }
                        });
                      if (experienceDayOfWeekByNumber.includes(dayOfWeek)) {
                        return experienceDayOfWeekByNumber.includes(dayOfWeek);
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  } else {
                    const currentDate = new Date(
                      experience.data_info.start_date
                    );
                    currentDate.setDate(currentDate.getDate() - 1);

                    const formattedDate = currentDate.toLocaleDateString("it", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    });
                    return (
                      new Date(dateFormatted).toLocaleString("it", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }) === formattedDate
                    );
                  }
                });

                const today = new Date();

                today.setDate(today.getDate() - 1);

                const currentDay = today.toLocaleDateString("it", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                });

                if (
                  experience.includes(true) &&
                  new Date(dateFormatted).toLocaleString("it", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }) >= currentDay
                ) {
                  return (
                    <div className="w-1 h-1 -mt-2 bg-green-500 rounded-full" />
                  );
                }
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full mt-6 gap-y-4">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {experiencesOfTheDay.length > 0 && bookingsForTheDay ? (
                <>
                  {experiencesOfTheDay.map((experience) => (
                    <div className="flex flex-col w-full gap-y-2">
                      <div className="flex justify-between w-full text-primary">
                        <h3 className="text-xl font-semibold">
                          {experience.name}
                        </h3>
                        <p>
                          {value.toLocaleDateString("it", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </p>
                      </div>
                      <div className="grid items-center w-full grid-cols-2 py-2 pl-6 pr-4 border rounded-full border-primary">
                        <div className="font-bold border-r border-primary">
                          Prenotazioni
                        </div>
                        <div className="flex items-center justify-between w-full gap-x-4"></div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="grid items-center w-full grid-cols-2 py-2 pl-6 pr-4 border rounded-full border-primary">
                  <p className="font-bold">
                    Nessuna esperienza disponibile per questa data
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
