import {
  faCircleQuestion,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function BookingTime() {
  const { id } = useParams();
  const animatedComponents = makeAnimated();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [selectedCancellation, setSelectedCancellation] = useState(
    experience?.cancellation_policy || null
  );

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    const currentError = [
      { id: "maxBookingTime", error: null },
      { id: "maxCancellationTime", error: null },
    ];

    if (
      document.getElementById("maxBookingTime").value === "" ||
      +document.getElementById("maxBookingTime").value.split(" h")[0] <= 0
    ) {
      currentError[0].error =
        "Il tempo massimo di prenotazione deve essere definito e maggiore di 0";
    }

    if (!selectedCancellation) {
      currentError[1].error =
        "Il tempo di cancellazione deve essere definito e maggiore di 0";
    }

    setErrors(currentError);

    if (currentError[0].error === null && currentError[1].error === null) {
      let data = {
        bookable_until: +document
          .getElementById("maxBookingTime")
          .value.split(" h")[0],
        cancellation_policy: selectedCancellation,
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setExperience(res.data.experience);
        })
        .catch(() => {
          alert("Errore durante la modifica dell'esperienza");
        });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, []);

  useEffect(() => {
    if (experience && selectedCancellation !== experience.cancellation_policy) {
      updateExperience();
    }
  }, [selectedCancellation]);

  return (
    <BaseComponent title="Tempi di prenotazione">
      <div className="flex min-h-[400px]  flex-col px-4 md:px-10 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-4">
        {experience ? (
          <>
            <div className="flex items-center justify-between">
              <p className="font-semibold">Tempo massimo di prenotazione</p>
              <Tooltip
                content="Il tempo massimo entro cui un utente può prenotare l'esperienza es: 72h prima dell'esperienza"
                position="left"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="cursor-pointer text-primary"
                />
              </Tooltip>
            </div>
            <div className="flex flex-col gap-y-4">
              <CurrencyInput
                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                placeholder="72 h"
                id="maxBookingTime"
                min={0}
                suffix=" h"
                decimalsLimit={0}
                decimalScale={0}
                allowNegativeValue={false}
                defaultValue={experience?.bookable_until}
                onChange={() => updateExperience()}
              />
              {/* {error[0].error && (
                <p className="-mt-2 text-sm text-gray-400">{error[0].error}</p>
              )} */}
            </div>
            <div className="flex items-center justify-between">
              <p className="font-semibold">Tipologia di cancellazione</p>
              <Tooltip
                content="Il tempo massimo entro cui un utente può cancellare la prenotazione
                ottenendo un rimborso completo. es: 24h prima dell'evento"
                position="left"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="cursor-pointer text-primary"
                />
              </Tooltip>
            </div>
            <div className="w-full">
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                menuPortalTarget={document.body}
                menuPosition="absolute"
                name="recurrency"
                defaultValue={
                  selectedCancellation
                    ? {
                        label:
                          selectedCancellation === 24
                            ? "Flessibile"
                            : selectedCancellation === 72
                            ? "Moderata"
                            : "Rigida",
                        value: selectedCancellation,
                      }
                    : null
                }
                onChange={(e) => setSelectedCancellation(e.value)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={[
                  { value: 24, label: "Flessibile" },
                  { value: 72, label: "Moderata" },
                  { value: 168, label: "Rigida" },
                ]}
              />
            </div>
            {/* {error[1].error && (
                <p className="-mt-2 text-sm text-gray-400">{error[1].error}</p>
              )} */}
            <div className="flex p-6 text-white rounded-md bg-primary">
              <div className="flex flex-col justify-between w-full text-sm">
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center text-lg gap-x-4">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <p className="font-semibold">
                      Comprendi le tipologie &nbsp; &nbsp; 🧑‍🏫
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <p>
                      Esco dà ai propri partner la possibilità di definire i
                      tempi massimi in cui un utente può cancellare la
                      prenotazione e ottenere un rimborso completo. le tipologie
                      di cancellazione disponibili sono le seguenti:
                    </p>
                    <dl className="max-w-xl mt-2 space-y-3 text-white lg:max-w-none">
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Flessibile:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata entro 24 ore dalla data dell'evento.
                        </dd>
                      </div>
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Moderata:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata entro 72 ore dalla data dell'evento.
                        </dd>
                      </div>
                      <div className="relative pl-9">
                        <dt className="inline text-base font-semibold text-gray-200">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="absolute w-3 h-3 text-white left-1 top-2"
                            aria-hidden="true"
                          />
                          Rigida:
                        </dt>{" "}
                        <dd className="inline">
                          il rimborso completo è garantito se la prenotazione
                          viene cancellata 7 giorni prima dalla data
                          dell'evento.
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
