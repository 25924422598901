import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import AWS from "aws-sdk";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import plus from "../../assets/icons/PLUS.svg";
import xmark from "../../assets/icons/XMARK.svg";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function StructureMedia() {
  const [images, setImages] = useState([]);

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [uploadedImages, setUploadedImages] = useState(structure.images || []);
  const [isUploading, setIsUploading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isCover, setIsCover] = useState(
    structure.cover || uploadedImages[0] || ""
  );

  console.log(structure.images, uploadedImages, "images");

  const updateStructure = async () => {
    setErrors([]);
    if (uploadedImages.length === 0) {
      setErrors([{ error: "Aggiungi almeno un immagine" }]);
    } else {
      const data = {
        images: uploadedImages,
        cover: isCover || uploadedImages[0],
      };
      const token = localStorage.getItem("token");
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${structure._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setStructure(res.data.structure);
          setIsCover(res.data.structure.cover);
        })
        .catch(() => {
          alert("Errore durante l'aggiornamento della struttura");
        });
    }
  };

  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [...prev, ...tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [...prev, ...tempImages];
          });
        }
      }
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  useEffect(() => {
    if (JSON.stringify(uploadedImages) !== JSON.stringify(structure.images)) {
      updateStructure();
    }
  }, [uploadedImages]);

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      isCover !== "" &&
      !uploadedImages.includes(isCover) &&
      uploadedImages.length > 0
    ) {
      setIsCover(uploadedImages[0]);
    } else if (uploadedImages.length === 0 && isCover !== "") {
      setIsCover("");
    }
    if (isCover !== structure.cover) {
      updateStructure();
    }
  }, [isCover, uploadedImages]);

  return (
    <BaseComponent title="Aggiungi le tue foto">
      <div className="flex flex-col p-4 md:p-10 my-4 h-[500px]  overflow-y-auto bg-gray-100 rounded-md gap-y-4">
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={20}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="relative w-full h-full upload__image-wrapper">
              <button
                className="border-2 md:border-4 border-dashed border-gray-400 rounded-[25px] md:rounded-[40px] text-gray-700 p-4 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-12 h-12 p-2 mb-4 md:w-16 md:h-16"
                />
                {windowWidth >= 768
                  ? "Clicca o trascina le tue foto qui"
                  : "Clicca per aggiungere le tue foto"}
              </button>
              {isUploading && (
                <div
                  className="relative px-2 md:px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[30px] md:rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4"
                  style={{
                    // Firefox
                    scrollbarWidth: "none",
                    // IE and Edge
                    msOverflowStyle: "none",
                  }}
                >
                  {uploadedImages.length > 0 &&
                    uploadedImages.map((image, index) => (
                      <div
                        key={index}
                        className="relative min-w-36 w-36 image-item"
                      >
                        <img
                          src={image}
                          alt=""
                          className="object-cover h-24 rounded w-36 min-w-36"
                        />
                        <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                          <button
                            onClick={() => {
                              setUploadedImages(
                                uploadedImages.filter((_, i) => i !== index)
                              );
                            }}
                            className="flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-200 rounded-full"
                          >
                            <img src={xmark} alt="plus" className="w-6 h-6" />
                          </button>
                        </div>
                      </div>
                    ))}
                  {imageList.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex items-center justify-center h-24 bg-gray-200 rounded-md min-w-36 w-36 image-item"
                    >
                      <UiduSpinner size="medium" className="text-gray-500" />
                    </div>
                  ))}
                </div>
              )}
              {uploadedImages.length > 0 && !isUploading && (
                <div
                  className="relative px-2 md:px-6 py-2 -mt-28 bg-white border-4 border-gray-200 rounded-b-[30px] md:rounded-b-[40px] w-full overflow-x-scroll flex gap-x-4"
                  style={{
                    // Firefox
                    scrollbarWidth: "none",
                    // IE and Edge
                    msOverflowStyle: "none",
                  }}
                >
                  {uploadedImages.map((image, index) => (
                    <div
                      key={index}
                      className="relative min-w-36 w-36 image-item"
                    >
                      <img
                        src={image}
                        alt=""
                        className="object-cover h-24 rounded w-36 min-w-36"
                      />
                      <div className="absolute -right-2 -top-2 image-item__btn-wrapper">
                        <button
                          onClick={() => {
                            setUploadedImages(
                              uploadedImages.filter((_, i) => i !== index)
                            );
                          }}
                          className="flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-200 rounded-full"
                        >
                          <img src={xmark} alt="plus" className="w-6 h-6" />
                        </button>
                      </div>
                      {uploadedImages.length > 1 && (
                        <label
                          htmlFor="isCover"
                          className="absolute flex items-center text-base font-semibold gap-x-3 bottom-2 left-2"
                        >
                          <input
                            type="checkbox"
                            id="isCover"
                            name="isCover"
                            className="hidden"
                            defaultChecked={false}
                          />
                          <div
                            className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                            onClick={() => {
                              setIsCover(image);
                            }}
                          >
                            {isCover === image && (
                              <FontAwesomeIcon className="h-3" icon={faCheck} />
                            )}
                          </div>
                        </label>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
        {uploadedImages.length > 1 && (
          <div className="text-primary">
            Imposta la foto di copertina spuntando la casella sotto l'immagine
            che preferisci !
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
