import axios from "axios";
import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import Carousel from "./Carousel";
import SingleArticle from "./SingleArticle";
import SingleLoad from "./SingleLoad";

export default function CarouselArticles() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(4);

  const getArticles = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/articlesLimited`)
      .then((res) => {
        console.log(res, "articles");
        setArticles(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupedData = articles.reduce((acc, curr, i) => {
    const group = Math.floor(i / slidesPerView);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(curr);
    return acc;
  }, []);

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(4); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Carousel slidesPerView={slidesPerView}>
        {!isLoading ? (
          <>
            {articles.length === 0 ? (
              <div className="mx-4 px-4 md:px-0 md:mx-0 text-2xl text-center text-gray-500 h-80 bg-gray-100 rounded-[30px] flex items-center justify-center mb-12">
                Nessun Articolo disponibile al momento
              </div>
            ) : (
              groupedData.map((group, i) => (
                <SwiperSlide key={i}>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                    <>
                      {group.map((item) => (
                        <SingleArticle key={item._id} item={item} />
                      ))}
                    </>
                  </div>
                </SwiperSlide>
              ))
            )}
          </>
        ) : (
          <SwiperSlide>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
              <SingleLoad length={slidesPerView} />
            </div>
          </SwiperSlide>
        )}
      </Carousel>
      <div className="fixed z-50">
        {/* <ToastContainer
          containerId={`SingleBoxContainer`}
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition={Bounce}
          stacked
        /> */}
      </div>
    </>
  );
}
