import React, { useEffect, useState } from "react";
import CarouselArticles from "../components/CarouselArticles";
import CarouselEscoBox from "../components/CarouselEscoBox";
import CarouselExperiences from "../components/CarouselExperiences";
import CarouselStructures from "../components/CarouselStructures";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import { generateFakeData } from "../utils/generateFakeData";

export default function Home() {
  const [shareArticles, setShareArticles] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedExperienceTab") || "wine"
  );

  useEffect(() => {
    document.title = "Esco";
    setShareArticles(generateFakeData({ kind: "escoShare", amount: 2 }));
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("selectedExperienceTab") === null ||
      localStorage.getItem("selectedExperienceTab") !== selectedTab
    ) {
      localStorage.setItem("selectedExperienceTab", selectedTab);
    }
  }, [selectedTab]);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            key={selectedTab}
            url={
              selectedTab === "wine"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_senior-couple-at-wine-tasting-2022-08-04-17-49-16-utc.mov"
                : selectedTab === "art"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_historical-columns-2022-01-18-23-34-52-utc.mov"
                : selectedTab === "outdoor"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/GX014697.mov"
                : "https://escopublic.s3.eu-north-1.amazonaws.com/2_chef-and-students-smashing-garlic-at-cooking-maste-2022-08-05-01-52-56-utc.mov"
            }
            description={"ESPLORA, SCOPRI, GUSTA VIVI."}
            isVideo={true}
          />
        </div>
        <div className="relative z-20">
          <PageBody
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isHomePage={true}
          >
            <h1 className="px-4 my-16 text-3xl text-center md:text-4xl text-secondary md:px-0">
              i box{" "}
              <strong
                className={
                  selectedTab === "wine"
                    ? "text-wine"
                    : selectedTab === "art"
                    ? "text-art"
                    : selectedTab === "outdoor"
                    ? "text-outdoor"
                    : "text-authentic"
                }
              >
                {selectedTab === "wine"
                  ? "Food & Wine "
                  : selectedTab === "art"
                  ? "Arte e Cultura "
                  : selectedTab === "outdoor"
                  ? "Outdoor "
                  : "Authentic "}
              </strong>
              pensati per te
            </h1>
            <div>
              <CarouselEscoBox selectedTab={selectedTab} />
            </div>
            <h1 className="px-4 mt-8 mb-12 text-3xl text-center md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              Le migliori esperienze{" "}
              <strong
                className={
                  selectedTab === "wine"
                    ? "text-wine"
                    : selectedTab === "art"
                    ? "text-art"
                    : selectedTab === "outdoor"
                    ? "text-outdoor"
                    : "text-authentic"
                }
              >
                {selectedTab === "wine"
                  ? "Food & Wine "
                  : selectedTab === "art"
                  ? "Arte e Cultura "
                  : selectedTab === "outdoor"
                  ? "Outdoor "
                  : "Authentic"}
              </strong>
            </h1>
            <div>
              <CarouselExperiences selectedTab={selectedTab} />
            </div>
            <h1 className="px-4 mt-8 mb-12 text-3xl text-center md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              Gli alloggi più apprezzati
            </h1>
            <div>
              <CarouselStructures />
            </div>
            <h1 className="px-4 mt-8 mb-12 text-3xl text-center md:mt-16 md:mb-20 md:text-4xl text-secondary md:px-0">
              Scopri il territorio Bresciano
            </h1>
            <div>
              <CarouselArticles />
            </div>
            {/* <h1 className="mt-8 mb-12 text-3xl text-center md:mt-16 md:mb-20 md:text-4xl text-secondary">
              Segui le orme di altri esploratori
            </h1>
            <div className="grid grid-cols-2 gap-x-8">
              {shareArticles.map((item) => (
                <SingleShareArticle item={item} key={item.id} />
              ))}
            </div> */}
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
