import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Calendar from "react-calendar";

export default function AddToCartEscoBox({ element, experiences, structures }) {
  const [value, onChange] = useState(new Date());
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const minPrice =
    Math.min(...structures.map((structure) => structure.minRoomPrice)) / 100 +
    experiences.reduce((acc, exp) => acc + exp.price.adult, 0) / 100;
  const maxPrice =
    Math.max(...structures.map((structure) => structure.maxRoomPrice)) / 100 +
    experiences.reduce((acc, exp) => acc + exp.price.adult, 0) / 100;

  return (
    <div className="sticky flex flex-col p-4 bg-white border rounded-lg shadow-lg top-5 gap-y-2">
      <div className="font-bold">Data</div>
      <Calendar
        onChange={onChange}
        value={value}
        minDate={new Date()}
        prev2Label={null}
        next2Label={null}
      />
      <div className="font-bold">Ospiti</div>
      <div className="flex flex-row justify-between">
        <div className="font-thin">Adulti</div>
        <div className="flex items-center gap-x-2 ">
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => {
              if (adults > 1) setAdults(adults - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="text-xs" />
          </button>
          <div className="w-12 font-semibold text-center">{adults}</div>
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => setAdults(adults + 1)}
          >
            <FontAwesomeIcon icon={faPlus} className="text-xs" />
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="font-thin">Bambini</div>
        <div className="flex items-center gap-x-2 ">
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => {
              if (children > 0) setChildren(children - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="text-xs" />
          </button>
          <div className="w-12 font-semibold text-center">{children}</div>
          <button
            className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            onClick={() => setChildren(children + 1)}
          >
            <FontAwesomeIcon icon={faPlus} className="text-xs" />
          </button>
        </div>
      </div>
      <div className="w-full border-b border-primary" />
      <div className="flex flex-row justify-between mt-2">
        <div className="font-bold">Totale</div>
        <div className="font-bold">{element.price.toFixed(2) / 100}€</div>
      </div>
      <button className="w-full py-1 mt-2 bg-white border rounded-full border-primary text-primary">
        Regala EscoBox
      </button>
      <button className="w-full py-2 text-white rounded-lg bg-primary">
        Aggiungi al carrello
      </button>
    </div>
  );
}
