import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import LoginForm from "../components/LoginForm";

export default function Counter() {
  document.title = "Esco | Is Coming Soon";
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div>
      <main>
        <div className="flex flex-col w-screen h-screen">
          <img
            src="https://escopublic.s3.eu-north-1.amazonaws.com/copertina2.jpg"
            alt="Copertina"
            className="absolute top-0 left-0 object-cover w-full h-full"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
          <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full text-white">
            <h1 className="text-5xl font-bold">
              esco<span className="font-thin">.zone</span>
            </h1>
            <div className="pt-2">
              <h2 className="text-xl text-typewriter typewriter w-fit">
                Is Coming Soon...
              </h2>
            </div>
          </div>
        </div>
        <AnimatePresence mode="wait">
          {showLogin && (
            <div className="absolute top-0 z-10 flex items-center justify-center w-screen h-screen overflow-hidden bg-white">
              <motion.div
                className="w-full md:max-w-xl h-full md:h-fit min-h-[450px] overflow-hidden"
                initial={{ x: 200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 200, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="relative z-30 w-full h-16 grid grid-cols-1 text-white rounded-[37px] shadow-lg bg-tertiary">
                  <button className="relative z-20 flex items-center justify-center w-full h-full">
                    <div
                      layoutId="selectedTab"
                      className="absolute z-10 w-full h-full md:rounded-[37px] bg-primary "
                    ></div>
                    <span className="absolute z-20">Accedi</span>
                  </button>
                </div>
                <div className="flex relative -mt-10 pt-16 flex-col items-center justify-center w-full h-full p-8 pb-16 md:pb-8 bg-white rounded-[37px] md:shadow-lg overflow-hidden">
                  <div
                    key={"login"}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="w-full h-full"
                  >
                    <LoginForm serverLink={process.env.REACT_APP_SERVER_URL} />
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <button
          className="fixed z-50 px-2 py-1 text-xl text-white rounded-full top-4 right-4 md:top-8 md:right-8 bg-tertiary"
          onClick={() => setShowLogin(!showLogin)}
        >
          <FontAwesomeIcon icon={showLogin ? faXmark : faCircleUser} />
        </button>
      </main>
    </div>
  );
}
