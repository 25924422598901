import {
  faEllipsisH,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import modifica from "../../assets/icons/MODIFICA.svg";
import BaseComponent from "../BaseComponent";
import PopUp from "../PopUp";

function UpdateAvailabilityCard({
  room,
  value,
  structure,
  handleClick,
  bookingsForTheDay,
}) {
  const haveReservation = bookingsForTheDay.find(
    (booking) => booking.room_id === room._id && !booking.user
  );

  const [currentAmount, setCurrentAmount] = useState(
    room.quantity -
      (haveReservation ? haveReservation.rooms_number_reserved || 0 : 0)
  );

  useEffect(() => {
    console.log(bookingsForTheDay, "bookingsForTheDay");
    setCurrentAmount(
      room.quantity -
        (haveReservation ? haveReservation.rooms_number_reserved : 0)
    );
  }, [bookingsForTheDay]);

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flex justify-between w-full text-primary">
        <h3 className="text-xl font-semibold">{room.name}</h3>
        <p>
          {value.toLocaleDateString("it", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>
      </div>
      <div className="border rounded-[25px] md:rounded-full border-primary">
        <div className="grid items-center w-full grid-cols-2 px-4 py-2 md:pl-6 md:pr-4 ">
          <div className="font-bold md:border-r border-primary">
            Posti disponibili
          </div>
          <div className="flex items-center justify-between w-full gap-x-4">
            <div className="items-center justify-center hidden pl-12 md:flex gap-x-4">
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="minus"
                onClick={(e) => {
                  if (currentAmount > 0) {
                    setCurrentAmount(currentAmount - 1);
                    handleClick(e, room);
                  }
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="text-center ">
                {currentAmount}
                {" su "}
                {room.quantity}
              </div>
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="plus"
                onClick={(e) => {
                  if (currentAmount < room.quantity) {
                    setCurrentAmount(currentAmount + 1);
                    handleClick(e, room);
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className="md:hidden" />
            <PopUp
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisH}
                  className="text-xs text-white"
                />
              }
              items={
                <>
                  <Link
                    className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                    to={`/proposeStructure/${structure._id}/room${room._id}`}
                  >
                    <img
                      src={modifica}
                      alt="modifica"
                      className="w-6 h-6 pr-2"
                    />
                    <div>Modifica camera</div>
                  </Link>
                </>
              }
            />
          </div>
        </div>
        <div className="flex items-center px-4 py-2 md:hidden gap-x-4">
          <button
            className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
            name="minus"
            onClick={(e) => {
              if (currentAmount > 0) {
                setCurrentAmount(currentAmount - 1);
                handleClick(e, room);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <div className="text-center ">
            {currentAmount}
            {" su "}
            {room.quantity}
          </div>
          <button
            className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
            name="plus"
            onClick={(e) => {
              if (currentAmount < room.quantity) {
                setCurrentAmount(currentAmount + 1);
                handleClick(e, room);
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Availability({ structures }) {
  const [selectedStructure, setSelectedStructure] = useState(structures[0]);
  const animatedComponents = makeAnimated();
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState([]);

  const getRooms = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/roomsOfStructure/${selectedStructure._id}`
        )
        .then((res) => {
          setRooms(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getRoomAvailabilityForTheDay = async (date) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/roomsBookingsOnDate`, {
          structure_id: selectedStructure._id,
          rooms: rooms.map((room) => room._id),
          date: new Date(dayjs(date).format("YYYY-MM-DD")),
        })
        .then((res) => {
          console.log(res, "res.data");
          setBookingsForTheDay(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (selectedStructure) {
      setLoading(true);
      getRooms();
    }
  }, [selectedStructure]);

  useEffect(() => {
    if (rooms.length > 0) {
      getRoomAvailabilityForTheDay(value);
    }
  }, [rooms]);

  const createReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => [
          ...bookingsForTheDay,
          res.data,
        ]);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const updateReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservationUpdate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.map((booking) => {
            if (booking._id === data.id) {
              return {
                ...booking,
                rooms_number_reserved: data.rooms_number_reserved,
              };
            } else {
              return booking;
            }
          });
          return updatedBookingsForTheDay;
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const deleteReservation = async (id) => {
    const token = localStorage.getItem("token");
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservationDelete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.filter(
            (booking) => booking._id !== id
          );
          return updatedBookingsForTheDay;
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleClick = (e, room) => {
    e.preventDefault();
    const totalGuests = bookingsForTheDay
      .filter((booking) => booking.room_id === room._id)
      .reduce((acc, booking) => acc + booking.rooms_number_reserved, 0);
    const bookingId = bookingsForTheDay.find(
      (booking) => booking.room_id === room._id && !booking.user
    );
    if (totalGuests >= room.quantity && !bookingId) {
      return;
    }
    console.log(e.target.name, e.target.parentNode.name, e);
    if (
      e.target.name === "plus" ||
      e.target.parentNode.name === "plus" ||
      e.target.parentNode.parentNode.name === "plus"
    ) {
      if (bookingId) {
        if (bookingId.rooms_number_reserved > 1) {
          updateReservation({
            id: bookingId._id,
            rooms_number_reserved: bookingId.rooms_number_reserved - 1,
          });
        } else {
          deleteReservation(bookingId._id);
        }
      } else {
        console.log("bookingId not found");
      }
    } else {
      if (bookingId) {
        if (
          totalGuests < room.quantity &&
          bookingId.rooms_number_reserved < room.quantity
        ) {
          updateReservation({
            id: bookingId._id,
            rooms_number_reserved: bookingId.rooms_number_reserved + 1,
          });
        }
      } else {
        createReservation({
          structure_id: selectedStructure._id,
          room_id: room._id,
          start_date: new Date(dayjs(value).format("YYYY-MM-DD")),
          end_date: new Date(dayjs(value).add(1, "day").format("YYYY-MM-DD")),
        });
      }
    }
  };

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tua disponibilità
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <p>
          Verifica la disponibilità delle tue strutture e modificala a tuo
          piacimento a seconda delle tue esigenze.
        </p>
        {structures.length > 1 && (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Struttura</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // menuPortalTarget={document.body}
                // menuPosition="absolute"
                defaultValue={
                  selectedStructure && {
                    value: selectedStructure?._id,
                    label: selectedStructure?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={structures.map((structure) => ({
                  value: structure._id,
                  label: structure.name,
                }))}
                onChange={(value) => {
                  setSelectedStructure(
                    structures.find(
                      (structure) => structure._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getRoomAvailabilityForTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
          />
        </div>
        <div className="flex flex-col w-full mt-6 gap-y-6">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {rooms.length > 0 && (
                <>
                  {rooms.map((room) => (
                    <UpdateAvailabilityCard
                      key={`${room._id}-${value}`}
                      room={room}
                      value={value}
                      structure={selectedStructure}
                      handleClick={handleClick}
                      bookingsForTheDay={bookingsForTheDay}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
