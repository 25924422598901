import { faPersonHiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function QuizCta() {
  return (
    <div className="flex w-full mt-16 bg-gray-200 rounded-md shadow-md">
      <img
        className="object-cover w-2/5 overflow-hidden rounded-md h-80"
        src="https://s3-alpha-sig.figma.com/img/5296/fd1b/174dfa328dcc46756301c754cbed67b9?Expires=1704672000&Signature=aWTOFmE5lMsBBLH9zO6xnf1QUOuVXo2wxntAyLrAERlM7eFvpm5ou3RfC1QSDLdUPe-~eADWjKL3v4~79r5e1w1xh0r8zsAhuxLvCldhtN0E4Lnkrobv3An0GvvwYUN0L6C68COUOs1l2FFWGEj3wUW~YdPxBWC-w-wUJlwUkbuKKEUg~eyyPnyEqWPb261RccQXPvj41v6nZxhvyX4-WKKPTIYvRwvehFe5An5qz~R~s~2saEbcj~UgIynBE91fgrUgmqxGPDOpnqkuR3zYckczRGl2ySmvrWZ2cPCJhyPaFoqXo9kZxmigyBdu9SfQLV-L7xLnxED-4HTjU9uBeA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
        alt="quiz"
      />
      <div className="flex flex-col justify-between w-3/5 p-8 mb-4">
        <div>
          <div className="flex items-center gap-x-4 text-primary">
            <FontAwesomeIcon className="text-3xl" icon={faPersonHiking} />
            <h1 className="text-3xl md:text-4xl">
              Scopri che tipo di esploratore sei
            </h1>
          </div>
          <p className="my-8 text-lg text-gray-600">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo
            quae amet accusamus perspiciatis voluptatum hic porro enim eaque
            soluta minima, libero quod iusto laborum, illum at magnam numquam
            quisquam mollitia.
          </p>
        </div>
        <div className="flex justify-end">
          <button className="w-1/3 px-4 py-2 font-semibold text-white rounded-md bg-primary">
            Vai al quiz
          </button>
        </div>
      </div>
    </div>
  );
}
