import Spinner from "@uidu/spinner";
import React from "react";

export default function LoadingSpinnerPage() {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner size="medium" />
    </div>
  );
}
