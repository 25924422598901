import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinnerPage from "../components/LoadingSpinnerPage";

function FaqItem({ faq }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div key={faq._id} className="flex flex-col gap-y-4">
      <div
        className="flex items-center justify-between w-full cursor-pointer text-escoBlack hover:text-primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="font-semibold">{faq.question}</p>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      {isOpen && <p className="text-base text-gray-600">{faq.answer}</p>}
      <hr className="w-full border border-gray-100" />
    </div>
  );
}

export default function Faq({ withoutHeader = false }) {
  const [faqSections, setFaqSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const getFaqs = async () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/faqSectionsList`)
      .then((res) => {
        setFaqSections(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "Esco | FAQ";
    if (!withoutHeader) {
      document
        .getElementById("faqPage")
        .scrollIntoView({ behavior: "smooth" }, { block: "start" });
    }
    getFaqs();
  }, []);

  return (
    <div id="faqPage">
      {!withoutHeader && (
        <header>
          <Header />
        </header>
      )}
      <main>
        <div className="mx-0 2xl:mx-16">
          <div
            className={`relative ${
              !withoutHeader
                ? " mx-4 md:mx-16 max-w-screen-2xl 2xl:mx-auto"
                : ""
            }`}
          >
            <div
              className={`flex flex-col justify-center w-full rounded-[60px] ${
                !withoutHeader ? " md:p-8 md:pt-36 md:py-20" : " px-8"
              }`}
            >
              {loading ? (
                <LoadingSpinnerPage />
              ) : (
                <div className="flex flex-col gap-y-4">
                  <div
                    className={`flex flex-col ${
                      !withoutHeader ? "mt-6" : ""
                    } mb-2 gap-y-4`}
                  >
                    <h1
                      className={` ${
                        !withoutHeader
                          ? " text-escoBlack font-bold text-3xl"
                          : "text-primary text-2xl"
                      }`}
                    >
                      Domande frequenti
                    </h1>
                    <p className="text-lg text-gray-600">
                      Qui troverai le risposte alle domande più frequenti
                    </p>
                    <div
                      className="flex items-center overflow-x-auto gap-x-4"
                      style={{
                        scrollbarWidth: "none" /* Firefox */,
                        msOverflowStyle: "none" /* IE and Edge */,
                        overflowX: "auto",
                      }}
                    >
                      {faqSections.map((faqSection, index) => (
                        <a
                          href={`#${faqSection._id}`}
                          key={index}
                          className="px-6 py-2 text-sm font-semibold rounded-full cursor-pointer text-escoBlack hover:text-escoBlue"
                          style={
                            !withoutHeader
                              ? {
                                  backgroundColor: "#f3f4f6",
                                }
                              : {
                                  backgroundColor: "rgb(229 231 235)",
                                }
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            document
                              .getElementById(faqSection._id)
                              .scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              });
                          }}
                        >
                          {faqSection.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  {faqSections.map((faqSection, index) => (
                    <div
                      className="flex flex-col w-full px-4 py-6 bg-white rounded-md gap-y-6"
                      key={index}
                      id={faqSection._id}
                    >
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-x-4">
                          <p className="text-2xl font-semibold text-escoBlack">
                            {faqSection.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col w-full gap-y-2">
                        {faqSection?.faqs.map((faq) => (
                          <FaqItem faq={faq} key={faq._id} />
                        ))}
                      </div>
                    </div>
                  ))}
                  <div
                    className="flex flex-col w-full px-4 py-6 bg-white rounded-md gap-y-6"
                    id="stillNeedHelp"
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-x-4">
                        <p className="text-2xl font-semibold text-escoBlack">
                          Hai ancora bisogno di aiuto?
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                      <div className="flex flex-col gap-y-4">
                        <div
                          className="flex items-center justify-between w-full cursor-pointer text-escoBlack hover:text-primary"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <p className="font-semibold ">
                            Come posso contattare il servizio clienti?
                          </p>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        {isOpen && (
                          <p className="text-base text-gray-600">
                            Completa il modulo di contatto{" "}
                            <a
                              href="/contactUs"
                              className="underline text-primary"
                            >
                              qui
                            </a>{" "}
                            e ti risponderemo il prima possibile.
                          </p>
                        )}
                        <hr className="w-full border border-gray-100" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      {!withoutHeader && <Footer />}
    </div>
  );
}
