import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowBack from "../assets/icons/INDIETRO_WHITE.svg";
import Header from "../components/Header";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Esco | Forgot Password";
    document
      .getElementById("forgotPasswordPage")
      .scrollIntoView({ behavior: "smooth" }, { block: "start" });
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setSuccess(null);
    setLoading(true);
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const email = document.getElementById("email").value;
    // if email is null or the format is not correct return we should check the email format with regex
    if (!email || !emailRegex.test(email)) {
      setError("Inserisci un'email valida");
      setLoading(false);
      return;
    }
    setError(null);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/forgotPassword`, {
        email,
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res.data.msg === "Email sent") {
          setSuccess(
            "Email inviata con successo, controlla la tua casella di posta"
          );
        } else {
          setError(
            "Errore durante l'invio dell'email, oppure email non valida"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Errore durante l'invio dell'email, oppure email non valida");
      });
  };

  return (
    <div id="forgotPasswordPage">
      <header>
        <Header />
      </header>
      <main>
        <div className={`relative`}>
          <div className="mx-0 2xl:mx-10">
            <div
              className={`flex flex-col w-full rounded-[60px] h-[calc(100vh-24rem)] mx-10 max-w-screen-2xl 2xl:mx-auto`}
            >
              <div className="relative flex flex-col items-center justify-center mt-40 gap-y-4">
                <div
                  className="absolute top-0 left-0 flex items-center cursor-pointer gap-x-4"
                  onClick={() => navigate(-1)}
                >
                  <button className="rounded-full bg-primary">
                    <img src={arrowBack} alt="Indietro" className="w-8 p-2" />
                  </button>
                  <p className="font-bold text-primary">Torna a login</p>
                </div>
                <div className="w-full max-w-xl h-full min-h-[250px] mx-4 md:mx-0">
                  <div className="mt-8 relative z-30 w-full h-12 md:h-16 grid grid-cols-2 text-white rounded-[37px] md:shadow-lg bg-tertiary">
                    <div className="absolute z-10 w-full h-full rounded-[37px] bg-primary flex items-center justify-center">
                      <span className="absolute z-20">
                        Password dimenticata?
                      </span>
                    </div>
                  </div>
                  <div className="flex relative -mt-10 pt-16 flex-col items-center justify-between w-full h-full p-8 bg-gray-100 md:bg-white rounded-[37px] md:shadow-lg">
                    <div className="flex flex-col h-full gap-y-4">
                      <p className="text-primary">
                        Inserisci il tuo indirizzo email per reimpostare la tua
                        password! Riceverai un'email con le istruzioni per
                        completare il processo.
                      </p>
                      <input
                        className="w-full px-3 py-2 mt-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                      />
                    </div>
                    {error && (
                      <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-red-500 rounded">
                        {error}
                      </div>
                    )}
                    {success && (
                      <div className="flex items-center justify-center w-full h-8 text-sm text-white bg-green-500 rounded">
                        {success}
                      </div>
                    )}
                    <button
                      onClick={handleForgotPassword}
                      className="w-full px-3 py-2 mt-2 text-sm leading-tight text-white border rounded shadow appearance-none bg-primary focus:outline-none focus:shadow-outline"
                    >
                      {loading ? (
                        <UiduSpinner size="small" invertColor />
                      ) : (
                        "Invia"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
