import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const history = useNavigate();
  document.title = "Esco | Errore :c";

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h1 className="p-16 text-6xl font-bold">Oops!</h1>
      <p className="p-8 text-lg">Sorry, an unexpected error has occurred.</p>
      <p className="text-gray-400">
        <i>{error.statusText || error.message}</i>
      </p>
      <button
        className="flex items-center px-4 py-2 mt-8 font-bold text-white rounded gap-x-4 bg-primary hover:bg-primary/90"
        onClick={() => history("/")}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Torna nella esco.zone
      </button>
    </div>
  );
}
