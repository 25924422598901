import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useHover from "@react-hook/hover";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ElementsCartContext,
  FavouriteContext,
  UserContext,
  UserLoggedContext,
} from "../App";
import logo from "../assets/LOGO.svg";
import backToUser from "../assets/icons/BACKTOUSER.svg";
import logout from "../assets/icons/LOGOUT.svg";
import PopUp from "./PopUp";

export default function NavbarCreator() {
  const location = useLocation();
  const user_id = localStorage.getItem("user");
  const { user, setUser } = useContext(UserContext);
  const target = useRef(null);
  const isHovering = useHover(target, { enterDelay: 0, leaveDelay: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { elements, setElements } = useContext(ElementsCartContext);
  const { setFavourite } = useContext(FavouriteContext);
  const { setUserLogged } = useContext(UserLoggedContext);

  const logOutUser = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate(`/`, { replace: true });
    setUserLogged(false);
    setFavourite(null);
    setElements([]);
    setUser({});
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const is_structure =
    location.pathname.includes("proposeStructure") ||
    location.pathname.includes("partner");

  return (
    <nav className="fixed top-0 z-10 w-screen text-white bg-escoGray ">
      <div className="mx-0 2xl:mx-8">
        <div className="flex items-center justify-between md:mx-8 max-w-screen-2xl 2xl:mx-auto h-[90px]">
          <div className="hidden absolute left-0 md:flex items-center h-full px-16 py-5 -ml-8 rounded-r-full w-[28.5%] bg-escoBlack h-[90px] 2xl:w-[35%]" />
          <div className="relative w-2/5 h-full py-6 pr-8 rounded-r-full bg-escoBlack max-h-[120px]">
            <Link
              to={
                is_structure ? `/partner/${user_id}` : `/creatorZone/${user_id}`
              }
              className="relative z-10 flex items-center gap-x-2"
            >
              <img src={logo} alt="logo" className="w-36" />
            </Link>
          </div>
          <div className="flex items-center px-4 py-5 font-semibold md:px-8 gap-x-2 md:gap-x-4">
            <PopUp
              setIsOpen={setIsOpen}
              iconStyle="noBackground"
              goUnderneath={true}
              icon={
                <div
                  className="flex items-center border rounded-full"
                  ref={target}
                  style={{
                    borderColor: isHovering || isOpen ? "#246155" : "#222d2d",
                  }}
                >
                  <div
                    className="flex items-center rounded-full"
                    style={{
                      backgroundColor:
                        isHovering || isOpen ? "#246155" : "#222d2d",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className={`py-2.5 px-4 text-white`}
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`py-2 px-2 text-escoBlack text-sm`}
                  />
                </div>
              }
              items={
                <>
                  <Link
                    className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-black hover:bg-gray-50"
                    to={`/profile/${user_id}`}
                    target={windowWidth >= 768 ? "blank" : ""}
                  >
                    <img
                      src={backToUser}
                      alt="torna al profilo"
                      className="w-6 h-6 pr-2"
                    />
                    <div>Profilo</div>
                  </Link>
                  {user.has_structures && user.has_experiences && (
                    <>
                      <div className="mx-4 border-b" />
                      <Link
                        className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                        to={`/partnerZone/${user_id}`}
                        target={windowWidth >= 768 ? "blank" : ""}
                      >
                        <img
                          src={backToUser}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2"
                        />
                        <div>Partner zone</div>
                      </Link>
                    </>
                  )}
                  {user.has_structures && !user.has_experiences && (
                    <>
                      <div className="mx-4 border-b" />
                      <Link
                        className="flex items-center w-full h-8 px-6 py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                        to={`/partner/${user_id}`}
                        target={windowWidth >= 768 ? "blank" : ""}
                      >
                        <img
                          src={backToUser}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2"
                        />
                        <div>Partner zone</div>
                      </Link>
                    </>
                  )}
                  {user.has_experiences && !user.has_structures && (
                    <>
                      <div className="mx-4 border-b" />
                      <Link
                        className="flex items-center w-full h-8 px-6 rounded-b-[30px] py-5 text-sm text-black cursor-pointer hover:bg-gray-50"
                        to={`/creatorZone/${user_id}`}
                        target={windowWidth >= 768 ? "blank" : ""}
                      >
                        <img
                          src={backToUser}
                          alt="torna al profilo"
                          className="w-6 h-6 pr-2"
                        />
                        <div>Partner zone</div>
                      </Link>
                    </>
                  )}
                  <div className="mx-4 border-b" />
                  <button
                    className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-b-[30px] cursor-pointer text-black hover:bg-gray-50"
                    type="button"
                    onClick={logOutUser}
                  >
                    <img
                      src={logout}
                      alt="torna al profilo"
                      className="w-6 h-6 pr-2"
                    />
                    <div>Logout</div>
                  </button>
                </>
              }
            />
            <button className="flex items-center px-4 py-3 border rounded-full border-escoBlack">
              <FontAwesomeIcon className="text-escoBlack" icon={faFlag} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
