import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import axios from "axios";
import React, { useState } from "react";

export default function ModalNewFaqSection({
  faqSections,
  setFaqSections,
  editFaqSection = null,
  setEditFaqSection,
  setOpenModalFaqSection,
}) {
  const [error, setError] = useState(false);

  const addFaqSection = async () => {
    const name = document.getElementById("name").value;
    if (name === "") {
      setError(true);
      return;
    }
    const newFaqSection = {
      name: name,
      position: faqSections.length,
    };
    const token = localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/faqSectionCreate`,
        newFaqSection,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setFaqSections([...faqSections, res.data]);
        setOpenModalFaqSection(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editFaqSectionMutation = async () => {
    const name = document.getElementById("name").value;

    if (name === "") {
      setError(true);
      return;
    }
    const token = localStorage.getItem("token");
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/faqSectionUpdate/${editFaqSection._id}`,
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const newFaqSections = faqSections.map((faqSection) => {
          if (faqSection._id === editFaqSection._id) {
            return res.data;
          }
          return faqSection;
        });
        setFaqSections(newFaqSections);
        setEditFaqSection(null);
        setOpenModalFaqSection(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => {
            setOpenModalFaqSection(false);
            setEditFaqSection(null);
          }}
          className="mt-0 md:mt-20"
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader>
            <div className="flex items-center gap-x-4">
              {editFaqSection ? (
                <h3 className="text-2xl">Modifica Faq Section</h3>
              ) : (
                <h3 className="text-2xl">Aggiungi Faq Section</h3>
              )}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalFaqSection(false);
                setEditFaqSection(null);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody>
            <p className="mb-2 font-semibold">Nome sezione</p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="name"
              defaultValue={editFaqSection ? editFaqSection.name : ""}
              placeholder={`nome sezione`}
            ></input>
            {error && <p className="text-red-500">Inserire un nome</p>}
          </ModalBody>
          <ModalFooter>
            <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
              <button
                className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                onClick={() => {
                  setOpenModalFaqSection(false);
                  setEditFaqSection(null);
                }}
              >
                Annulla
              </button>
              <button
                className="w-full h-10 text-sm text-white rounded-lg bg-primary md:w-28"
                onClick={() =>
                  editFaqSection ? editFaqSectionMutation() : addFaqSection()
                }
              >
                {editFaqSection ? "Modifica" : "Aggiungi"}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
