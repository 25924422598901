// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tagsinput {
    background-color: #fff;
    border: 2px solid rgb(229 231 235);
    overflow: hidden;
    padding-left: 5px;
    border-radius: 4px;
}

.react-tagsinput-tag {
    background-color: #246155;
    border-radius: 2px;
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
    padding: 5px;
    margin-top: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: black;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    padding: 8px 5px;
    width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/proposePages/tagsInput.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".react-tagsinput {\n    background-color: #fff;\n    border: 2px solid rgb(229 231 235);\n    overflow: hidden;\n    padding-left: 5px;\n    border-radius: 4px;\n}\n\n.react-tagsinput-tag {\n    background-color: #246155;\n    border-radius: 2px;\n    color: white;\n    display: inline-block;\n    font-family: sans-serif;\n    font-size: 13px;\n    font-weight: 400;\n    margin-right: 5px;\n    padding: 5px;\n    margin-top: 5px;\n}\n\n.react-tagsinput-remove {\n    cursor: pointer;\n    font-weight: bold;\n}\n\n.react-tagsinput-tag a::before {\n    content: \" ×\";\n}\n\n.react-tagsinput-input {\n    background: transparent;\n    border: 0;\n    color: black;\n    font-family: sans-serif;\n    font-size: 13px;\n    font-weight: 400;\n    outline: none;\n    padding: 8px 5px;\n    width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
