import React from "react";
import geotag from "../../assets/icons/GEOTAG.svg";
import stella from "../../assets/icons/STELLA.svg";

export default function StructureSubtitle({ structure }) {
  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  return (
    <div className="flex flex-wrap gap-4 text-lg">
      <div className="flex items-center mr-6 md:mr-12 gap-x-4">
        <img src={geotag} alt="geotag" className="w-6" />
        <div className="text-center">
          {structure.address
            ? structure.address?.municipality
            : "Pertica Bassa"}
        </div>
      </div>
      <div className="flex items-center gap-x-4">
        <img src={stella} alt="stella" className="w-6" />
        <div>
          {structure.reviews ? averageReviews(structure.reviews) : 0}
          /5
        </div>
      </div>
      <div className="flex items-center gap-x-1.5 text-base text-primary">
        <div>{structure.reviews ? structure.reviews.length : 0} recensioni</div>
      </div>
    </div>
  );
}
