import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import agriturism from "../assets/agriturism.svg";
import bAndB from "../assets/b&b.svg";
import camping from "../assets/camping.svg";
import glamping from "../assets/glamping.svg";
import hostel from "../assets/ostello.svg";
import rifugio from "../assets/rifugio.svg";
import { LocationValleys } from "./utils/LocationValleys";

export default function SearchBarStructure({
  setSelectedTab,
  selectedTab,
  selectedLocation,
  setSelectedLocation,
}) {
  const [searchLocation, setSearchLocation] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const searchRef = useRef(null);

  const filteredLocations = LocationValleys.filter(
    (valley) =>
      valley.villages.some((village) =>
        village.toLowerCase().includes(searchLocation.toLowerCase())
      ) || valley.name.toLowerCase().includes(searchLocation.toLowerCase())
  );

  useEffect(() => {
    if (selectedLocation === "" || !selectedLocation) {
      setSearchLocation("");
    }
  }, [selectedLocation]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex(
          (prevIndex) => (prevIndex + 1) % filteredLocations.length
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredLocations.length) %
            filteredLocations.length
        );
      }
    };

    if (openPopup) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openPopup, filteredLocations.length]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex(
          (prevIndex) => (prevIndex + 1) % filteredLocations.length
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredLocations.length) %
            filteredLocations.length
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        const selectedValley = filteredLocations[selectedIndex];
        setSelectedLocation(selectedValley.name);
        setSearchLocation(selectedValley.name);
        setOpenPopup(false);
      }
    };

    if (openPopup) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openPopup, filteredLocations, selectedIndex]);

  return (
    <div className="relative z-20 mx-8 max-w-screen-2xl 2xl:mx-auto">
      <div className="absolute grid items-center justify-center w-full grid-cols-8 rounded-full -mt-7 bg-tertiary">
        <div className="relative col-span-2" ref={searchRef}>
          <div className="absolute z-40 flex items-center justify-center h-full left-8">
            <FontAwesomeIcon className="text-gray-500" icon={faSearch} />
          </div>
          <input
            type="text"
            placeholder="Dove andiamo?"
            className="relative z-30 flex items-center w-full h-full py-6 pl-16 text-gray-500 truncate bg-white rounded-full shadow-md pr-14 gap-x-4 focus:outline-none hover:text-gray-700"
            value={searchLocation}
            onChange={(e) => {
              setSearchLocation(e.target.value);
              setOpenPopup(true);
            }}
          />
          {selectedLocation && (
            <div className="absolute top-0 z-40 flex items-center justify-center h-full right-8">
              <button
                type="button"
                className="flex items-center justify-center w-4 h-4 text-gray-500 "
                onClick={() => {
                  setSelectedLocation("");
                  setSearchLocation("");
                }}
              >
                <span className="sr-only">Clear</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          {searchLocation !== "" && openPopup && (
            <div className="absolute z-50 w-80 flex flex-col p-4 bg-white border rounded-[20px] shadow-md top-20 divide-y">
              {filteredLocations.map((valley, index) => (
                <div
                  key={valley.id}
                  className={`flex items-center justify-between p-2 text-gray-500 cursor-pointer hover:text-primary ${
                    index === selectedIndex ? "text-primary" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedLocation(valley.name);
                      setSearchLocation(valley.name);
                      setOpenPopup(false);
                    }}
                  >
                    {valley.name}
                  </button>
                </div>
              ))}
              {filteredLocations.length === 0 && (
                <div className="p-2 text-gray-500">Nessun risultato</div>
              )}
            </div>
          )}
        </div>
        {/* <FontAwesomeIcon icon={faSearch} />
          <span>Dove andiamo?</span>
        </button> */}
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("agriturismo")}
        >
          <img
            src={agriturism}
            alt="agriturism"
            className="relative z-20 w-6 h-6"
            onClick={() => setSelectedTab("agriturismo")}
          />
          <span className="relative z-20">agriturismo</span>
          {selectedTab === "agriturismo" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("rifugio")}
        >
          <img src={rifugio} alt="rifugio" className="relative z-20 w-6 h-6" />
          <span className="relative z-20">rifugio</span>
          {selectedTab === "rifugio" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("B&B")}
        >
          <img src={bAndB} alt="b&b" className="relative z-20 w-6 h-6" />
          <span className="relative z-20 ">b&b</span>
          {selectedTab === "B&B" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("camping")}
        >
          <img src={camping} alt="camping" className="relative z-20 w-6 h-6" />
          <span className="relative z-20">camping</span>
          {selectedTab === "camping" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("ostello")}
        >
          <img src={hostel} alt="hostel" className="relative z-20 w-6 h-6" />
          <span className="relative z-20 ">hostel</span>
          {selectedTab === "ostello" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
        <button
          type="button"
          className="relative z-20 flex items-center justify-center w-full h-full px-8 py-6 text-white rounded-full gap-x-4 focus:outline-none "
          onClick={() => setSelectedTab("glamping")}
        >
          <img
            src={glamping}
            alt="glamping"
            className="relative z-20 w-6 h-6"
          />
          <span className="relative z-20">glamping</span>
          {selectedTab === "glamping" && (
            <motion.div
              layoutId="selectedCategory"
              className="absolute z-10 w-full h-full rounded-full bg-primary "
            ></motion.div>
          )}
        </button>
      </div>
    </div>
  );
}
