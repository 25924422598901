import useHover from "@react-hook/hover";
import axios from "axios";
import React, { useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import avanti from "../../assets/icons/AVANTI.svg";
import avantiWhite from "../../assets/icons/AVANTI_WHITE.svg";
import plus from "../../assets/icons/PLUS.svg";
import plusBlack from "../../assets/icons/PLUS_BLACK.svg";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";

export default function AddRoom() {
  const { id } = useParams();
  const navigate = useNavigate();
  const createRoomTarget = useRef(null);
  const goNextTarget = useRef(null);
  const isHovering = useHover(createRoomTarget, {
    enterDelay: 0,
    leaveDelay: 0,
  });
  const isHoveringGoNext = useHover(goNextTarget, {
    enterDelay: 0,
    leaveDelay: 0,
  });

  const { rooms, setRooms } = useContext(ProposeStructureContext);

  const createRoom = async () => {
    const token = localStorage.getItem("token");
    const data = {
      structure_id: id,
      name: `Camera ${rooms.length + 1}`,
    };

    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/room`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setRooms((prev) => [...prev, res.data]);
        navigate(`/proposeStructure/${id}/room${res.data._id}`, {
          replace: true,
        });
      })
      .catch(() => {
        alert("Errore durante la creazione della stanza");
      });
  };

  return (
    <BaseComponent title="La tua struttura comprende altre stanze?">
      <div className="bg-gray-100 items-center justify-center flex flex-col gap-6 rounded-md h-[500px] overflow-y-auto p-4 md:p-10 my-4">
        <button
          ref={createRoomTarget}
          className="w-full h-24 text-lg text-black border-2 rounded-lg md:h-32 hover:text-white border-primary hover:bg-primary"
          onClick={() => createRoom()}
        >
          <div className="flex items-center justify-center w-full h-full gap-x-4">
            {isHovering ? (
              <img className="w-5 h-5" src={plus} alt={"Aggiungi stanza"} />
            ) : (
              <img
                className="w-5 h-5"
                src={plusBlack}
                alt={"Aggiungi stanza"}
              />
            )}
            <p className="text-center">Aggiungi stanza</p>
          </div>
        </button>
        <button
          ref={goNextTarget}
          className="w-full h-24 text-lg text-black border-2 rounded-lg md:h-32 hover:text-white border-primary hover:bg-primary"
          onClick={() =>
            navigate(`/proposeStructure/${id}/meals`, { replace: true })
          }
        >
          <div className="flex items-center justify-center w-full h-full gap-x-4">
            <p className="text-center">Procedi allo step successivo</p>
            {isHoveringGoNext ? (
              <img
                className="w-5 h-5"
                src={avantiWhite}
                alt={"Procedi allo step successivo"}
              />
            ) : (
              <img
                className="w-5 h-5"
                src={avanti}
                alt={"Procedi allo step successivo"}
              />
            )}
          </div>
        </button>
      </div>
    </BaseComponent>
  );
}
